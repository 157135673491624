import { Box, Divider, Typography, Menu, MenuItem, IconButton } from "@mui/material";
import React, { useState } from "react";
import { commonColors } from "../../Assets/colors.ts";
import Accordion from "@mui/material/Accordion";
import AccordionSummary from "@mui/material/AccordionSummary";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import EditRoundedIcon from "@mui/icons-material/EditRounded";
import { AccountCircle, Phone, Title, Work, MoreVert } from "@mui/icons-material"; // Example icons
import "./contactCards.css";

interface props {
  contactCard?: any;
  handleContactEdit?: any;
  showSearchData?: any;
  searchData?: any;
}

function ContactCards({
  contactCard,
  handleContactEdit,
  showSearchData,
  searchData,
}: props) {
  const showData = searchData ? showSearchData : contactCard;
  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);
  const handleMenuClick = (event: React.MouseEvent<HTMLElement>) => {
    setAnchorEl(event.currentTarget);
  };

  const handleMenuClose = () => {
    setAnchorEl(null);
  };
  const isMenuOpen = Boolean(anchorEl);
  return (
    <Box
      className="c-cardMainBox"
      sx={{
        display: "flex",
        flexWrap: "wrap",
        gap: 2,
        justifyContent: "center",
        padding: "20px",
        backgroundColor: "#f4f6f8",
      }}
    >
      {showData?.map((item: any, index: any) => (
        <Box
          className="c-MainCard"
          sx={{
            borderRadius: "16px",
            boxShadow: "0px 6px 15px rgba(0, 0, 0, 0.15)",
            overflow: "hidden",
            backgroundColor: "#fff",
            transition: "transform 0.3s",
            "&:hover": {
              transform: "scale(1.03)",
            },
          }}
          key={index}
        >
          <Box sx={{ position: "relative", padding: "0px" }}>
            <a
              onClick={() => handleContactEdit(item, index)}
              style={{
                position: "absolute",
                fontSize: "0px",
                top: "0",
                right: "0",
                cursor: "pointer",
                color: "#6c63ff",
                background: "#f0f0ff",
                borderRadius: "50%",
                padding: "0",
                transition: "all 0.3s",
              }}
            >
              <EditRoundedIcon
                sx={{
                  "&:hover": { color: "#4f46e5" },
                  fontSize: "2rem",
                  padding: "5px",
                }}
              />
            </a>
            <div
              className="c-cardImgBox"
              style={{
                backgroundColor:
                  item.theme === 6
                    ? commonColors.AccentColor
                    : item.theme === 5
                    ? commonColors.dynamicPurple
                    : item.theme === 4
                    ? commonColors.dynamicBlue
                    : item.theme === 3
                    ? commonColors.dynamicGreen
                    : item.theme === 2
                    ? commonColors.dynamicOrange
                    : commonColors.MainColor,
                width: "70%",
                height: "auto",
                borderRadius: "12px",
                marginBottom: "16px",
              }}
            >
              <img
                src={
                  item.profileImg
                    ? item.profileImg
                    : "https://static.vecteezy.com/system/resources/previews/020/213/738/non_2x/add-profile-picture-icon-upload-photo-of-social-media-user-vector.jpg"
                }
                alt="Profile"
                className="c-cardImg"
                style={{
                  width: "100%",
                  height: "100%",
                  objectFit: "cover",
                  borderRadius: "50%",
                  border: "4px solid #fff",
                }}
              />
            </div>
            <Typography
      sx={{
        fontSize: "18px",
        fontWeight: "600",
        padding: "10px 10px",
        lineHeight: "25px",
        marginBottom: "8px",
        borderTop: "1px solid #999999",
        borderBottom: "1px solid #999999",
        position: "relative",
      }}
    >
      {/* Three-dot menu */}
      <IconButton
        sx={{ position: "absolute", top: "10px", right: "10px" }}
        onClick={handleMenuClick}
      >
        <MoreVert />
      </IconButton>
      <Menu
        id="profile-menu"
        anchorEl={anchorEl}
        open={isMenuOpen}
        onClose={handleMenuClose}
        PaperProps={{
          style: {
            width: "280px", // Increased menu width for mobile
            padding: "10px",
            borderRadius: "12px", // Rounded corners for a modern look
          },
        }}
      >
        {/* Accreditations */}
        <MenuItem
          onClick={handleMenuClose}
          sx={{
            padding: "15px 20px",
            fontSize: "1rem", // Larger font size
          }}
        >
          <Typography variant="body1">
            {item.accreditations || "Accreditations: Not Provided"}
          </Typography>
        </MenuItem>

        {/* Preferred Name */}
        <MenuItem
          onClick={handleMenuClose}
          sx={{
            padding: "15px 20px",
            fontSize: "1rem",
          }}
        >
          <Typography variant="body1">
            {item.preferredName || "Preferred Name: Not Provided"}
          </Typography>
        </MenuItem>

        {/* Maiden Name */}
        <MenuItem
          onClick={handleMenuClose}
          sx={{
            padding: "15px 20px",
            fontSize: "1rem",
          }}
        >
          <Typography variant="body1">
            {item.maidenName || "Maiden Name: Not Provided"}
          </Typography>
        </MenuItem>

        {/* Pronouns */}
        <MenuItem
          onClick={handleMenuClose}
          sx={{
            padding: "15px 20px",
            fontSize: "1rem",
          }}
        >
          <Typography variant="body1">
            {item.pronouns || "Pronouns: Not Provided"}
          </Typography>
        </MenuItem>

        {/* Title */}
        <MenuItem
          onClick={handleMenuClose}
          sx={{
            padding: "15px 20px",
            fontSize: "1rem",
          }}
        >
          <Typography variant="body1">
            {item.title || "Title: Not Provided"}
          </Typography>
        </MenuItem>

        {/* Department */}
        <MenuItem
          onClick={handleMenuClose}
          sx={{
            padding: "15px 20px",
            fontSize: "1rem",
          }}
        >
          <Typography variant="body1">
            {item.department || "Department: Not Provided"}
          </Typography>
        </MenuItem>

        {/* Company */}
        <MenuItem
          onClick={handleMenuClose}
          sx={{
            padding: "15px 20px",
            fontSize: "1rem",
          }}
        >
          <Typography variant="body1">
            {item.company || "Company: Not Provided"}
          </Typography>
        </MenuItem>

        {/* Headline */}
        <MenuItem
          onClick={handleMenuClose}
          sx={{
            padding: "15px 20px",
            fontSize: "1rem",
          }}
        >
          <Typography variant="body1">
            {item.headline || "Headline: Not Provided"}
          </Typography>
        </MenuItem>
      </Menu>

      {/* Prefix with icon */}
      <h5
        style={{
          color: "#555",
          fontSize: "0.7rem",
          margin: "0px",
          display: "flex",
          alignItems: "center",
        }}
      >
        <AccountCircle
          style={{ fontSize: "1rem", color: "red", marginRight: "5px" }}
        />
        {item.prefix || "Prefix"}
      </h5>

      {/* Name with icons */}
      <div
        className="name flex"
        style={{
          display: "flex",
          alignItems: "center",
          flexWrap: "wrap",
        }}
      >
        <AccountCircle
          style={{
            fontSize: "1rem",
            color: "purple",
            marginRight: "5px",
          }}
        />
        <span
          style={{
            color: "#333",
            fontSize: "0.8rem",
            margin: "0px 0px 3px 0px",
          }}
        >
          {item.firstName || "FirstName"}
        </span>
        <span
          style={{
            color: "#333",
            fontSize: "0.8rem",
            margin: "0px 3px",
          }}
        >
          {item.middleName || "middleName"}
        </span>
        <span
          style={{
            color: "#333",
            fontSize: "0.8rem",
            margin: "0px 3px",
          }}
        >
          {item.lastName || "lastName"}
        </span>
      </div>

      {/* Suffix with icon */}
      <h3
        style={{
          color: "#555",
          fontSize: "0.7rem",
          margin: "0px",
          display: "flex",
          alignItems: "center",
        }}
      >
        <Title
          style={{
            fontSize: "1rem",
            color: "blue",
            marginRight: "5px",
          }}
        />
        {item.suffix || "suffix"}
      </h3>

      {/* Phone with icon */}
      <p
        style={{
          color: "#555",
          fontSize: "0.7rem",
          margin: "0px",
          display: "flex",
          alignItems: "center",
        }}
      >
        <Phone
          style={{
            fontSize: "1rem",
            color: "green",
            marginRight: "5px",
          }}
        />
        {item.phone || "Phone"}
      </p>
    </Typography>
            <div style={{ display: "flex", justifyContent: "end" }}>
              <Box
                style={{
                  display: "flex",
                  justifyContent: "end",
                  alignItems: "end",
                  marginTop: "10px",
                  gap: "15px",
                  flexWrap: "wrap",
                }}
              >
                {item.facebook && (
                  <a
                    href={`https://facebook.com/${item.facebook}`}
                    target="_blank"
                    rel="noopener noreferrer"
                  >
                    <img
                      src={require("../../Assets/Images/Icons/faceBookColored.png")}
                      alt="Facebook"
                      style={{ width: "18px", height: "18px" }}
                    />
                  </a>
                )}
                {item.instagram && (
                  <a
                    href={`https://instagram.com/${item.instagram}`}
                    target="_blank"
                    rel="noopener noreferrer"
                  >
                    <img
                      src={require("../../Assets/Images/Icons/instaColored.png")}
                      alt="Instagram"
                      style={{ width: "18px", height: "18px" }}
                    />
                  </a>
                )}
                {item.twitter && (
                  <a
                    href={`https://twitter.com/${item.twitter}`}
                    target="_blank"
                    rel="noopener noreferrer"
                  >
                    <img
                      src={require("../../Assets/Images/Icons/twitterColored.png")}
                      alt="Twitter"
                      style={{ width: "18px", height: "18px" }}
                    />
                  </a>
                )}
                {item.linkedin && (
                  <a
                    href={`https://linkedin.com/in/${item.linkedin}`}
                    target="_blank"
                    rel="noopener noreferrer"
                  >
                    <img
                      src={require("../../Assets/Images/Icons/linkedinColored.png")}
                      alt="LinkedIn"
                      style={{ width: "18px", height: "18px" }}
                    />
                  </a>
                )}
                {item.youtube && (
                  <a
                    href={`https://youtube.com/${item.youtube}`}
                    target="_blank"
                    rel="noopener noreferrer"
                  >
                    <img
                      src={require("../../Assets/Images/Icons/youtubeColored.png")}
                      alt="YouTube"
                      style={{ width: "18px", height: "18px" }}
                    />
                  </a>
                )}
              </Box>
            </div>
            {/* <Accordion
              sx={{
                boxShadow: "none",
                border: "1px solid #e0e0e0",
                borderRadius: "8px",
                marginBottom: "16px",
              }}
            >
              <AccordionSummary
                expandIcon={<ExpandMoreIcon />}
                aria-controls="panel1-content"
                id="panel1-header"
                sx={{
                  padding: "8px 16px",
                  "&:hover": {
                    backgroundColor: "#f9f9f9",
                  },
                }}
              >
                <Typography sx={{ fontSize: "16px", fontWeight: "bold" }}>
                  Contact Details
                </Typography>
              </AccordionSummary>
              <div className="c-cardDesp" style={{ padding: "16px" }}>
                {item.title && (
                  <Typography sx={{ fontSize: "14px", marginBottom: "8px" }}>
                    <strong>Title:</strong> {item.title}
                  </Typography>
                )}
                {item.company && (
                  <Typography sx={{ fontSize: "14px", marginBottom: "8px" }}>
                    <strong>Company:</strong> {item.company}
                  </Typography>
                )}
                {item.department && (
                  <Typography sx={{ fontSize: "14px", marginBottom: "8px" }}>
                    <strong>Department:</strong> {item.department}
                  </Typography>
                )}
                {item.moreInfo &&
                  item.moreInfo.map((info: any, index: number) => (
                    <Box
                      className="c-cardAddInfo"
                      key={index}
                      sx={{
                        display: "flex",
                        alignItems: "center",
                        gap: "8px",
                        marginBottom: "8px",
                      }}
                    >
                      {info.logo && <info.logo />}
                      <Typography
                        sx={{ fontSize: "14px", color: "#757575" }}
                      >
                        {info.label === "Phone" ? (
                          <>
                            {info.subInput && `${info.subInput} `}
                            {info.firstInput}
                          </>
                        ) : (
                          <>
                            {info.firstInput}
                            {info.subInput && `, ${info.subInput}`}
                          </>
                        )}
                      </Typography>
                    </Box>
                  ))}
              </div>
            </Accordion> */}
          </Box>
        </Box>
      ))}
    </Box>
  );
}

export default ContactCards;
