import Api from './Api.ts';
import Api2 from './ApiAddCard.ts'
import { ADD_PROFILE, GET_QRCODE, SAVE_CARD, GET_CARD } from './ApiName.ts';
import Cookies from 'universal-cookie';

const cookies = new Cookies();
let token = await cookies.get('token')
let idToken = await cookies.get('idToken')


let configToken = {};

const generateHeader = () => {
    if(token == undefined){
        token = cookies.get('token')
    }
    console.log("Token----", token)
    configToken = {
        headers: {
            accept: 'application/json',
            Authorization: 'Bearer ' + token?.toString(),
        },
    };
    return configToken
}

const generateHeaderForCard = () => {
    if(idToken == undefined){
        idToken = cookies.get('idToken')
    }
    configToken = {
        headers: {
            accept: 'application/json',
            Authorization: idToken?.toString(),
        },
    };
    return configToken
}
const generateHeaderImage = () => {
    if(token == undefined){
        token = cookies.get('token')
    }
    console.log("Token----",token);
    configToken = {
        headers: {
            // accept: 'application/json',
            accept: 'image/png',
            Authorization: 'Bearer ' + token?.toString(),
        },
    };
    return configToken
}


export default {
    async addProfile(data:any) {
        let url = `${ADD_PROFILE}`
        const header = generateHeader()
        var response = await Api().post(url, data, header)
        console.log("Add Profile Response----", response)
        return response.data;
    },
    async getQRCode() {
        let url = `${GET_QRCODE}`
        const header = generateHeaderImage()
        var response = await Api().get(url, header);
        console.log("Get QR Code Response----", response);
        return response.data;
    },

    async addCard(data:any) {
        let url = `${SAVE_CARD}`
        const header = generateHeaderForCard()
        var response = await Api().post(url, data, header)
        console.log("Add Profile Response----", response)
        return response.data;
    },

    async getCards() {
        let url = `${GET_CARD}`
        
        const header = generateHeaderForCard()

        var response = await Api().get(url, header)
        console.log(response);
        
        console.log("Add Profile Response----", response)
        return response.data;
    },

    async getCardById(id:any) {
        let url = `${GET_CARD}/${id}`
        const header = generateHeaderForCard()
        var response = await Api().get(url, header)
        console.log("Add Profile Response----", response)
        return response.data;
    },
    async updateCard(data:any, id:any) {
        let url = `${GET_CARD}/${id}`
        const header = generateHeaderForCard()
        var response = await Api().put(url, data, header)
        console.log("Add Profile Response----", response)
        return response.data;
    },
    async deleteCard(id:any) {
        let url = `${GET_CARD}/${id}`
        const header = generateHeaderForCard()
        var response = await Api().delete(url, header)
        console.log("Add Profile Response----", response)
        return response.data;
    }
}