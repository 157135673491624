// import React, { useRef, useState } from "react";
// import WhiteBox from "../commonComponents/whiteBox/whiteBox.tsx";
// import { Box, Button, IconButton, Typography } from "@mui/material";
// import "./scannerBox.css"
// import Menu from '@mui/material/Menu';
// import MenuItem from '@mui/material/MenuItem';
// import CloseRoundedIcon from '@mui/icons-material/CloseRounded';
// import { useNavigate } from "react-router";

// interface scannerDetails {
//     texts: any
// }

// export default function ScannerBox({ texts }: scannerDetails) {
//   const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null);
//   const [image, setImage] = useState<any>()
//   const [cardId, setCardId] = useState('07ac3aa0-1001-447c-bd0c-42e0cbc60189')
//   const navigate = useNavigate()
//   const open = Boolean(anchorEl);
//   const profileImgRef = useRef<any>();

//   const handleClick = (event: React.MouseEvent<HTMLButtonElement>) => {
//     setAnchorEl(event.currentTarget);
//   };
//   const handleClose = () => {
//     setAnchorEl(null);
//   };

//     const handleScanCard = () => {
//       // After scanning qrcode this navigation will call and cardId will be passed in to this url and it will navigate to card details page for perticular card.
//       navigate(`/card/${cardId}`)
//     }

//     const handleImage = (event: any) => {
//       setImage(URL.createObjectURL(event.target.files[0]))
//       setAnchorEl(null);
//     };

//     const handleRemoveImg = () => {
//       setImage('')
//     }

//     return (
//         <WhiteBox
//             title={texts?.allowCamera}
//             titleColor='main'
//             className='page2NextBtn'
//             onClick={handleScanCard}
//             btnTitle={texts?.scanCard}
//         >
//             <Box className="ScannerBox">
//               {
//                 image ?
//                 <Box className='imageMainBox'>
//                 <img src={image}  className="scannerImage"/>
//                 <IconButton disableRipple onClick={handleRemoveImg} className="scannerImageCloseIconBtn"><CloseRoundedIcon className="closeIcon"/></IconButton>
//                 </Box>:
//                 <>
//                  <Button onClick={handleClick} disableRipple className="cameraBtn">
//                     <Typography className="cameraTitle">{texts?.camera}</Typography>
//                 </Button>
//                 <Menu
//                   id="basic-menu"
//                   anchorEl={anchorEl}
//                   open={open}
//                   onClose={handleClose}
//                   MenuListProps={{
//                     'aria-labelledby': 'basic-button',
//                   }}
//                 >
//                   <MenuItem
//                     onClick={handleClose}>{texts?.camera}</MenuItem>
//                   <MenuItem
//                     className="gallaryOption"
//                     onClick={() => profileImgRef.current?.click()}
//                      >
//                     <input hidden  ref={profileImgRef} onChange={(event: any) => handleImage(event)} type="file"/>
//                       {texts?.gallery}
//                   </MenuItem>
//                 </Menu>
//                 </>
//               }
//             </Box>
//         </WhiteBox>
//     )
// }
// import React, { useRef, useState } from "react";
// import WhiteBox from "../commonComponents/whiteBox/whiteBox.tsx";
// import { Box, Button, IconButton, Typography } from "@mui/material";
// import PhotoCameraIcon from "@mui/icons-material/PhotoCamera";
// import PhotoLibraryIcon from "@mui/icons-material/PhotoLibrary";
// import CloseRoundedIcon from "@mui/icons-material/CloseRounded";
// import { useNavigate } from "react-router";
// import "./scannerBox.css";
// import Cookies from "universal-cookie";

// interface ScannerDetails {
//   texts: any;
//   setCardDetails: (data: any) => void; // Prop for setting API response
// }

// export default function ScannerBox({ texts, setCardDetails }: ScannerDetails) {
//   const [image, setImage] = useState<string | null>(null);
//   const navigate = useNavigate();
//   const profileImgRef = useRef<any>();
//   const cameraImgRef = useRef<any>();
//   const [loading, setLoading] = useState(false);

//   const handleImage = (event: React.ChangeEvent<HTMLInputElement>) => {
//     const file = event.target.files?.[0];
//     if (file) {
//       const reader = new FileReader();
//       reader.onload = () => {
//         setImage(reader.result as string);
//       };
//       reader.readAsDataURL(file);
//     }
//   };

//   const handleRemoveImg = () => setImage(null);

//   // const handleScanCard = async () => {
//   //   if (!image) return;
//   //   try {
//   //     const cookies = new Cookies();
//   //     const token = cookies.get("idToken"); // Retrieve token from cookies
//   //     console.log(`token`, token);
//   //     const response = await fetch(
//   //       "https://ul83jp2wof.execute-api.us-west-2.amazonaws.com/prod/cards/scan",
//   //       {
//   //         method: "POST",
//   //         headers: {
//   //           "Content-Type": "application/json", // Ensure proper header
//   //           Authorization: `${token}`,
//   //         },
//   //         body: JSON.stringify({ image: image.split(",")[1] }), // Remove prefix
//   //       }
//   //     );
//   //     // const daata = await response.json();
//   //     // console.log(daata);

//   //     if (response.ok) {
//   //       const data = await response.json();
//   //       console.log("Card Details:", data); // Log in console
//   //       setCardDetails(data); // Update parent state
//   //       navigate(`/`); // Navigate to card details page
//   //     } else {
//   //       console.error("Error scanning card:", response.statusText);
//   //     }
//   //   } catch (error) {
//   //     console.error("error:", error);
//   //   }
//   // };
//   const handleScanCard = async () => {
//     if (!image) return;

//     setLoading(true); // Start loading

//     try {
//       const cookies = new Cookies();
//       const token = cookies.get("idToken"); // Retrieve token from cookies
//       console.log(`token`, token);
//       const response = await fetch(
//         "https://ul83jp2wof.execute-api.us-west-2.amazonaws.com/prod/cards/scan",
//         {
//           method: "POST",
//           headers: {
//             "Content-Type": "application/json", // Ensure proper header
//             Authorization: `${token}`,
//           },
//           body: JSON.stringify({ image: image.split(",")[1] }), // Remove prefix
//         }
//       );

//       if (response.ok) {
//         const data = await response.json();
//         console.log("Card Details:", data); // Log in console
//         setCardDetails(data); // Update parent state
//         navigate(`/`); // Navigate to card details page
//       } else {
//         console.error("Error scanning card:", response.statusText);
//       }
//     } catch (error) {
//       console.error("error:", error);
//     } finally {
//       setLoading(false); // End loading (whether success or error)
//     }
//   };

//   return (
//     <WhiteBox
//       title={texts?.allowCamera}
//       titleColor="main"
//       className="page2NextBtn"
//       onClick={handleScanCard}
//       btnTitle={texts?.scanCard}
//     >
//       <Box className="ScannerBox">
//         {image ? (
//           <Box className="imageMainBox">
//             <img src={image} className="scannerImage" alt="Scanned Card" />
//             <IconButton
//               disableRipple
//               onClick={handleRemoveImg}
//               className="scannerImageCloseIconBtn"
//             >
//               <CloseRoundedIcon className="closeIcon" />
//             </IconButton>
//           </Box>
//         ) : (
//           <Box className="optionsBox">
//             <Button
//               className="cameraOption"
//               onClick={() => cameraImgRef.current?.click()}
//               startIcon={<PhotoCameraIcon />}
//             >
//               {texts?.camera}
//             </Button>
//             <input
//               hidden
//               ref={cameraImgRef}
//               type="file"
//               accept="image/*"
//               onChange={handleImage}
//             />
//             <Button
//               className="galleryOption"
//               onClick={() => profileImgRef.current?.click()}
//               startIcon={<PhotoLibraryIcon />}
//             >
//               {texts?.gallery}
//             </Button>
//             <input
//               hidden
//               ref={profileImgRef}
//               type="file"
//               accept="image/*"
//               onChange={handleImage}
//             />
//           </Box>
//         )}
//       </Box>
//     </WhiteBox>
//   );
// }
import React, { useRef, useState } from "react";
import WhiteBox from "../commonComponents/whiteBox/whiteBox.tsx";
import {
  Box,
  Button,
  IconButton,
  Typography,
  LinearProgress,
} from "@mui/material";
import PhotoCameraIcon from "@mui/icons-material/PhotoCamera";
import PhotoLibraryIcon from "@mui/icons-material/PhotoLibrary";
import CloseRoundedIcon from "@mui/icons-material/CloseRounded";
import { useNavigate } from "react-router";
import "./scannerBox.css";
import Cookies from "universal-cookie";

interface ScannerDetails {
  texts: any;
  setCardDetails: (data: any) => void; // Prop for setting API response
}

export default function ScannerBox({ texts, setCardDetails }: ScannerDetails) {
  const [image, setImage] = useState<string | null>(null);
  const [loading, setLoading] = useState(false); // Loading state
  const [progress, setProgress] = useState(0); // Progress state for loading bar
  const [done, setDone] = useState(false); // State to track success
  const navigate = useNavigate();
  const profileImgRef = useRef<any>();
  const cameraImgRef = useRef<any>();

  const handleImage = (event: React.ChangeEvent<HTMLInputElement>) => {
    const file = event.target.files?.[0];
    if (file) {
      const reader = new FileReader();
      reader.onload = () => {
        setImage(reader.result as string);
      };
      reader.readAsDataURL(file);
    }
  };

  const handleRemoveImg = () => setImage(null);

  const handleScanCard = async () => {
    if (!image) return;

    setLoading(true); // Start loading
    setDone(false); // Reset done state

    // Simulate the loading progress
    let interval = setInterval(() => {
      setProgress((prev) => {
        if (prev >= 100) {
          clearInterval(interval);
          return 100;
        }
        return prev + 10; // Increment by 10% every interval
      });
    }, 500);

    try {
      const cookies = new Cookies();
      const token = cookies.get("idToken"); // Retrieve token from cookies
      const response = await fetch(
        "https://ul83jp2wof.execute-api.us-west-2.amazonaws.com/prod/cards/scan",
        {
          method: "POST",
          headers: {
            "Content-Type": "application/json", // Ensure proper header
            Authorization: `${token}`,
          },
          body: JSON.stringify({ image: image.split(",")[1] }), // Remove prefix
        }
      );

      if (response.ok) {
        const data = await response.json();
        setCardDetails(data); // Update parent state
        // navigate(`/`); // Navigate to card details page
        setDone(true); // Mark as done when successful
      } else {
        console.error("Error scanning card:", response.statusText);
      }
    } catch (error) {
      console.error("error:", error);
    } finally {
      setLoading(false); // End loading
      window.location.reload();
      // navigate(-1);
    }
  };

  return (
    <WhiteBox
      title={texts?.allowCamera}
      titleColor="main"
      className="page2NextBtn"
      onClick={handleScanCard}
      btnTitle={texts?.scanCard}
    >
      <Box className="ScannerBox">
        {loading ? (
          <Box>
            <LinearProgress
              variant="determinate"
              value={progress} // Display progress as a percentage
              sx={{ marginBottom: 2 }} // Add margin below the progress bar
            />
            <Typography variant="body2" color="textSecondary" align="center">
              {progress < 100 ? `Loading... ${progress}%` : " just a second!"}
            </Typography>
          </Box>
        ) : image ? (
          <Box className="imageMainBox">
            <img src={image} className="scannerImage" alt="Scanned Card" />
            <IconButton
              disableRipple
              onClick={handleRemoveImg}
              className="scannerImageCloseIconBtn"
            >
              <CloseRoundedIcon className="closeIcon" />
            </IconButton>
          </Box>
        ) : (
          <Box className="optionsBox">
            <Button
              className="cameraOption"
              onClick={() => cameraImgRef.current?.click()}
              startIcon={<PhotoCameraIcon />}
            >
              {texts?.camera}
            </Button>
            <input
              hidden
              ref={cameraImgRef}
              type="file"
              accept="image/*"
              onChange={handleImage}
            />
            <Button
              className="galleryOption"
              onClick={() => profileImgRef.current?.click()}
              startIcon={<PhotoLibraryIcon />}
            >
              {texts?.gallery}
            </Button>
            <input
              hidden
              ref={profileImgRef}
              type="file"
              accept="image/*"
              onChange={handleImage}
            />
          </Box>
        )}
      </Box>
    </WhiteBox>
  );
}
