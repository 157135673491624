import React, { useEffect, useState } from "react";
import "./loginForm.css";
import {
  Box,
  Button,
  Divider,
  Typography,
  Checkbox,
  FormControlLabel,
  useMediaQuery,
  Link,
} from "@mui/material";
import WhiteBox from "../commonComponents/whiteBox/whiteBox.tsx";
import InputField from "../commonComponents/InputField/inputField.tsx";
import { validateEmail } from "../../utils/commonRegex.ts";
import service from "../../services/service.ts";
import Cookies from "universal-cookie";
import { useNavigate } from "react-router";
import { paths } from "../../values/paths.ts";
import { auth, googleProvider } from "../../config/firbase.ts";
import { GoogleAuthProvider, signInWithPopup } from "firebase/auth";
import GoogleIcon from "@mui/icons-material/Google";
import { commonColors } from "../../Assets/colors.ts";

interface props {
  commonTexts?: any;
  setIsLogedIn?: any;
}

function LoginForm({ commonTexts, setIsLogedIn }: props) {
  const cookies = new Cookies();
  const navigate = useNavigate();
  const maxW900px = useMediaQuery("(max-width: 900px)");
  const [loginData, setLoginData] = useState({
    email: "",
    password: "",
  });
  const [error, setError] = useState(false);
  const [showAlert, setShowAlert] = useState(false);
  const [rememberMe, setRememberMe] = useState(true);
  //  Custom Methods
  const handleLoginData = (keyName: any) => (e: any) => {
    setLoginData({ ...loginData, [keyName]: e.target.value });
  };

  // life Cycle Method
  useEffect(() => {
    if (validateEmail(loginData.email)) {
      return setError(false);
    } else {
      return setError(true);
    }
  }, [loginData.email]);

  //  Api Call
  const onClickLogin = async () => {
    if (loginData.email?.length == 0 || loginData.password.length == 0) {
      return setShowAlert(true);
    } else {
      setShowAlert(false);
      let data = {
        email: loginData.email,
        password: loginData.password,
      };
      const response = await service.login(data);
      if (response) {
        cookies.set("token", response?.accessToken, { path: "/" });
        cookies.set("idToken", response?.idToken, { path: "/" });
        setIsLogedIn(cookies.get("token"));
        setTimeout(() => {
          navigate(paths.cards);
        }, 1000);
      }
    }
  };

  // const signInWithGoogle = async () => {
  //   try {
  //    signInWithPopup(auth,googleProvider)
  //    .then((response:any) => {
  //     const token = response?.user?.accessToken;
  //     cookies.set("token", token, { path: '/' });
  //     cookies.set("user", response?.user ,{ path: '/' } );
  //     setIsLogedIn(cookies.get('token'))
  //     setTimeout(() => {
  //       navigate(paths.cards);
  //     }, 1000)
  //    })
  //   } catch (err){
  //     console.error(err);
  //   }
  // };

  return (
    <Box className="loginpage">
      {!maxW900px && (
        <Box className="loginLogoBox">
          <img
            src={require("../../Assets/Images/Icons/logo.png")}
            className="loginPageLogo"
          />
          <img
            src={require("../../Assets/Images/image/loginImg.png")}
            className="loginPageImg"
          />
        </Box>
      )}
      {/* <WhiteBox
        title={'Login to your Account'}
        info={'See what is going on with your business'}
        titleColor={undefined}
        className={undefined}
        onClick={undefined}
        btnTitle={undefined}
      > */}
      {/* <Box className="loginLogoBox">
          <img
            src={require('../../Assets/Images/image/bird.jpg')}
            className="loginPageLogo"
          />
        </Box> */}
      <Box className="loginRightBox">
        <Box className="loginRightSubBox">
          {maxW900px && (
            <Box className="loginLogoBox">
              <img
                src={require("../../Assets/Images/Icons/logo.png")}
                className="loginPageLogo"
              />
            </Box>
          )}
          <div>
            <Typography className="loginBoxHeadingText">
              {commonTexts?.LoginToAccount}
            </Typography>
            {/* <Typography className="loginBoxTitleText">
              {commonTexts?.bussinesCard}
            </Typography> */}
          </div>
          <div>
            <Typography className="loginInputHeading">
              {" "}
              {commonTexts?.email}{" "}
            </Typography>
            <InputField
              value={loginData.email}
              onChange={handleLoginData("email")}
              type="email"
              label={loginData.email ? "" : commonTexts?.mailCom}
            />
            {showAlert && loginData.email?.length == 0 && (
              <Typography className="alertText">
                {" "}
                {commonTexts?.enterEmail}
              </Typography>
            )}
            {error && loginData.email?.length > 0 && (
              <Typography className="alertText">
                {" "}
                {commonTexts?.emailFormat}{" "}
              </Typography>
            )}
          </div>
          <div className="passwordInput">
            <Typography className="loginInputHeading">
              {" "}
              {commonTexts?.password}{" "}
            </Typography>
            <InputField
              value={loginData.password}
              onChange={handleLoginData("password")}
              type="password"
              label={loginData.password ? "" : "•••••••••"}
            />
            {showAlert && loginData.password?.length == 0 && (
              <Typography className="alertText">
                {" "}
                {commonTexts?.enterYourPassword}
              </Typography>
            )}
            <div className="passwordDetailBox">
              <FormControlLabel
                label={
                  <Typography className="checkBoxLabel">
                    {commonTexts?.rememberMe}
                  </Typography>
                }
                control={
                  <Checkbox
                    size="small"
                    checked={rememberMe}
                    sx={{
                      "&.Mui-checked": {
                        color: commonColors.lightPurpleColor,
                      },
                    }}
                  />
                }
              />
              <Link
                underline="none"
                onClick={() => navigate(paths.forgotpassword)}
                className="forgotPasswordText"
                style={{ cursor: "pointer" }}
              >
                <Button disableRipple className="forgotBtn">
                  {commonTexts?.ForgotPassword}
                </Button>
              </Link>
            </div>
          </div>
          <Box className="loginBtnBox">
            <Button type="submit" className="loginBtn" onClick={onClickLogin}>
              {commonTexts?.login}
            </Button>
          </Box>
          <Divider className="dividerText" variant="middle">
            {commonTexts?.Or}
          </Divider>
          {/* <Button type="submit" className="googleBtn" onClick={signInWithGoogle} >
                <img src={require('../../Assets/Images/Icons/google.png')} style={{width:"100%" ,maxWidth:"22px"}}/>{commonTexts?.ContinueWithGoogle}
            </Button> */}
          <Typography className="dontaccText">
            {commonTexts?.dontHaveAccount}
            <Link
              underline="none"
              onClick={() => navigate(paths.signup)}
              className="signupText"
              style={{ cursor: "pointer" }}
            >
              {commonTexts?.Signup}
            </Link>
          </Typography>
          {/* <Typography className="loginFormText"> {commonTexts?.commonText?.continue} </Typography> */}
          {/* <Box className='googleBtnBox' >
            <Button type="submit" className="button" onClick={signInWithGoogle} >
              <Typography className="btnText"><img src={require('../../Assets/Images/Icons/google.png')} style={{width:"100%" ,maxWidth:"22px"}}/>Sign In With Google</Typography>
            </Button>
            <Button type="submit" className="button"  >
              <Typography className="btnText"><img src={require('../../Assets/Images/Icons/facebook.png')} style={{width:"100%" ,maxWidth:"22px"}}/>Sign In With FaceBook</Typography>
            </Button>
          </Box> */}
          {/* <Box className='googleBtnBox'>
            <Button type="submit" className="button" >
              <Typography className="btnText"><img src={require('../../Assets/Images/Icons/apple.png')} style={{width:"100%" ,maxWidth:"22px"}}/>Sign In With Apple</Typography>
            </Button>
            <Button type="submit" className="button"  >
              <Typography className="btnText"><img src={require('../../Assets/Images/Icons/microsoft.png')} style={{width:"100%", maxWidth:"22px"}}/>Sign In With Microsoft</Typography>
            </Button>
          </Box> */}
        </Box>
      </Box>
      {/* </WhiteBox> */}
    </Box>
  );
}

export default LoginForm;
