export const paths = {
  cards: "/",
  card:"/card/:id",
  // home: "/",
  scanner: "/scanner",
  qrcode: "/qrcode",
  login : "/login",
  publicDetails : '/publicDetails',
  setting:'/setting',
  contact:'/contact',
  signup: '/signup',
  forgotpassword: '/forgot-password'
};
