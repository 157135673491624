import { Box, Divider, Typography } from "@mui/material";
import React from "react";
import { commonColors } from "../../Assets/colors.ts";
import Accordion from "@mui/material/Accordion";
import AccordionSummary from "@mui/material/AccordionSummary";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import EditRoundedIcon from "@mui/icons-material/EditRounded";
import "./contactCards.css";

interface props {
  contactCard?: any;
  handleContactEdit?: any;
  showSearchData?: any;
  searchData?: any;
}

function ContactCards({
  contactCard,
  handleContactEdit,
  showSearchData,
  searchData,
}: props) {
  const showData = searchData ? showSearchData : contactCard;

  console.log("asd---", showData);

  return (
   <Box className="c-cardMainBox" sx={{ display: "flex", flexWrap: "wrap", gap: 2, justifyContent: "center" }}>
  {showData?.map((item: any, index: any) => (
    <Box
      className="c-MainCard"
      sx={{
        width: "300px",
        borderRadius: "12px",
        boxShadow: "0px 4px 10px rgba(0, 0, 0, 0.1)",
        overflow: "hidden",
        backgroundColor: "#fff",
      }}
      key={index}
    >
      <Box sx={{ position: "relative", padding: "16px" }}>
        <a
          onClick={() => handleContactEdit(item, index)}
          style={{
            position: "absolute",
            top: "10px",
            right: "10px",
            cursor: "pointer",
            color: "#6c63ff",
            transition: "color 0.3s",
          }}
        >
          <EditRoundedIcon sx={{ "&:hover": { color: "#4f46e5" } }} />
        </a>
        <div
          className="c-cardImgBox"
          style={{
            backgroundColor:
              item.theme === 6
                ? commonColors.AccentColor
                : item.theme === 5
                ? commonColors.dynamicPurple
                : item.theme === 4
                ? commonColors.dynamicBlue
                : item.theme === 3
                ? commonColors.dynamicGreen
                : item.theme === 2
                ? commonColors.dynamicOrange
                : commonColors.MainColor,
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            height: "120px",
            borderRadius: "12px",
            marginBottom: "16px",
          }}
        >
          <img
            src={
              item.profileImg
                ? item.profileImg
                : "https://static.vecteezy.com/system/resources/previews/020/213/738/non_2x/add-profile-picture-icon-upload-photo-of-social-media-user-vector.jpg"
            }
            alt="Profile"
            className="c-cardImg"
            style={{ width: "80px", height: "80px", borderRadius: "50%" }}
          />
        </div>

        <Accordion
          sx={{
            boxShadow: "none",
            border: "1px solid #e0e0e0",
            borderRadius: "8px",
          }}
        >
          <AccordionSummary
            expandIcon={<ExpandMoreIcon />}
            aria-controls="panel1-content"
            id="panel1-header"
            sx={{
              padding: "8px 16px",
              "&:hover": {
                backgroundColor: "#f9f9f9",
              },
            }}
          >
            <Typography sx={{ fontSize: "16px", fontWeight: "bold" }}>
              {item.prefix}
              {item.firstName} {item.middleName} {item.lastName}
            </Typography>
          </AccordionSummary>
          <div className="c-cardDesp" style={{ padding: "16px" }}>
            {item.suffix && <Typography>{item.suffix}</Typography>}
            {item.accreditations && <Typography>{item.accreditations}</Typography>}
            {item.prefferredName && <Typography>{item.prefferredName}</Typography>}
            {item.maidenName && <Typography>{item.maidenName}</Typography>}
            {item.pronouse && <Typography>{item.pronouse}</Typography>}
            {(item.title || item.department || item.company || item.headline) && <Divider sx={{ margin: "12px 0" }} />}
            {item.title && <Typography>{item.title}</Typography>}
            {item.department && <Typography>{item.department}</Typography>}
            {item.company && <Typography>{item.company}</Typography>}
            {item.headline && <Typography>{item.headline}</Typography>}
            {item.moreInfo && item.moreInfo.length > 0 && <Divider sx={{ margin: "12px 0" }} />}
            {item.moreInfo &&
              item.moreInfo.map((info: any, index: number) => (
                <Box className="c-cardAddInfo" key={index} sx={{ display: "flex", alignItems: "center", gap: "8px", marginBottom: "8px" }}>
                  {info.logo && <info.logo />}
                  <Typography sx={{ fontSize: "14px", color: "#757575" }}>
                    {info.label === "Phone" ? (
                      <>
                        {info.subInput && `${info.subInput} `}
                        {info.firstInput}
                      </>
                    ) : (
                      <>
                        {info.firstInput}
                        {info.subInput && `, ${info.subInput}`}
                      </>
                    )}
                  </Typography>
                </Box>
              ))}
          </div>
        </Accordion>
      </Box>
    </Box>
  ))}
</Box>

  );
}

export default ContactCards;
