import React, { useState } from "react";
import { Box, Typography, IconButton, Menu, MenuItem } from "@mui/material";
import MoreVertIcon from "@mui/icons-material/MoreVert";
import ShareIcon from "@mui/icons-material/Share";
import DeleteIcon from "@mui/icons-material/Delete";
import PhoneIcon from "@mui/icons-material/Phone";
import EmailIcon from "@mui/icons-material/Email";
import LocationOnIcon from "@mui/icons-material/LocationOn";
import LanguageIcon from "@mui/icons-material/Language";
import AddOutlinedIcon from "@mui/icons-material/AddOutlined";
import "./customCards.css";
import commonTexts from "../../../Assets/CommonTexts/texts.json";
import Cookies from "universal-cookie";
interface Props {
  searchData?: any;
  showSearchData?: any;
  cards?: any;
  handleEdit?: any;
  commonTexts?: any;
  handleDeleteCard?: any;
  handleOpen: any;
}

const CustomCards: React.FC<Props> = ({
  cards,
  handleDeleteCard,
  handleOpen,
}) => {
  const cookies = new Cookies();
  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);
  const [selectedCardId, setSelectedCardId] = useState<string | null>(null);
  const open = Boolean(anchorEl);

  const handleMenuOpen = (
    event: React.MouseEvent<HTMLButtonElement>,
    cardId: string
  ) => {
    setAnchorEl(event.currentTarget);
    setSelectedCardId(cardId);
  };

  const handleMenuClose = () => {
    setAnchorEl(null);
    setSelectedCardId(null);
  };

  const handleShare = (cardId: string) => {
    navigator.clipboard.writeText(`http://localhost:3001/card/${cardId}`);
    alert("Card link copied to clipboard!");
    handleMenuClose();
  };

  const handleDelete = (cardId: string) => {
    if (handleDeleteCard) {
      handleDeleteCard(cardId);
    }
    handleMenuClose();
  };

  return (
    <Box className="displayCardBox">
      {cards.length < 1 && (
        <div className="emptycardBox">
          <img
            src={require("../../../Assets/Images/image/emptyCardsImg.png")}
            alt=""
            className="emptycardImg"
          />
          <Typography className="emptycardText">
            {commonTexts?.youDontHaveBusinessCard || "No cards found"}
          </Typography>
          <Typography className="emptycardText2">
            {commonTexts?.MakeyourBusinessCard || "Create your first card now"}
          </Typography>
          <button className="headerAddBtn1" onClick={handleOpen}>
            <AddOutlinedIcon sx={{ marginRight: "8px" }} />
            {commonTexts?.add || "Add Card"}
          </button>
        </div>
      )}
      {/* {cards.length >= 1 && (
        <Box
          className="newCardBox addCardBox"
          onClick={handleOpen}
          style={{
            cursor: "pointer",
            display: "flex",
            flexDirection: "column",
            justifyContent: "center",
            alignItems: "center",
            backgroundColor: "#f5f5f5",
            border: "2px dashed #ccc",
          }}
        >
          <AddOutlinedIcon sx={{ fontSize: 48, color: "#ccc" }} />
          <Typography className="addCardText">
            {commonTexts?.add || "Add Card"}
          </Typography>
        </Box>
      )} */}
  {cards?.map((card: any) => (
  <Box
    className="newCardBox"
    key={card.cardId}
    style={{
      boxShadow: "0px 4px 10px rgba(0, 0, 0, 0.1)",
      borderRadius: "12px",
      backgroundColor: "#fff",
      overflow: "hidden",
      marginBottom: "20px",
      padding: "15px",
      position: "relative",
    }}
  >
    {/* Card Header */}
    <Box
      className="cardHeader"
      style={{
        display: "flex",
        // justifyContent: "space-between",
        alignItems: "center",
        marginBottom: "10px",
      }}
    >
      <Box
        className="companyName mx-auto"
        style={{
          fontSize: "18px",
          fontWeight: "bold",
          color: "#3e64ff",
          fontFamily: "Arial, sans-serif",
        }}
      >
        {card.company || "No Company"}
      </Box>
      <IconButton
        className="moreOptionsBtn"
        onClick={(e) => handleMenuOpen(e, card.cardId)}
        style={{
          backgroundColor: "#f5f5f5",
          boxShadow: "0px 2px 5px rgba(0, 0, 0, 0.1)",
        }}
      >
        <MoreVertIcon />
      </IconButton>
    </Box>

    {/* Card Image */}
    <img
      className="newCardImg"
      src={
        card.imageUrl ||
        require("../../../Assets/Images/image/emptyCardsImg.png")
      }
      alt="Card"
      style={{
        width: "100%",
        height: "150px",
        objectFit: "cover",
        borderRadius: "8px",
        marginBottom: "10px",
      }}
    />

    {/* Card Details */}
    <Box
      className="cardContent"
      style={{
        display: "flex",
        justifyContent: "space-between",
        alignItems: "flex-start",
      }}
    >
      <Box className="cardDetailsLeft">
        {/* Card Name */}
        {card.cardName && (
          <Typography
            style={{
              fontSize: "14px",
              fontWeight: "500",
              color: "#999",
              marginBottom: "10px",
            }}
          >
            {card.cardName}
          </Typography>
        )}

        {/* Full Name */}
        {(card.firstName || card.lastName) && (
          <Typography
            style={{
              fontSize: "18px",
              fontWeight: "bold",
              color: "#333",
              marginBottom: "-10px",
            }}
          >
            {`${card.firstName || ""} ${card.lastName || ""}`.trim() || "N/A"}
          </Typography>
        )}

        {/* Role and Contact Details */}
        {card.role && (
          <Typography
            style={{
              fontSize: "12px",
              fontWeight: "400",
              color: "#666",
              marginBottom: "8px",
            }}
          >
            {card.role}
          </Typography>
        )}
        {card.phoneNumber && (
          <Box style={{ display: "flex", alignItems: "center", gap: "5px" }}>
            <PhoneIcon fontSize="small" style={{ color: "#888" }} />
            <Typography style={{ fontSize: "14px", color: "#555" }}>
              {card.phoneNumber}
            </Typography>
          </Box>
        )}
        {card.emails && (
          <Box style={{ display: "flex", alignItems: "center", gap: "5px" }}>
            <EmailIcon fontSize="small" style={{ color: "#888" }} />
            <Typography style={{ fontSize: "14px", color: "#555" }}>
              {card.emails}
            </Typography>
          </Box>
        )}
        {card.address && (
          <Box style={{ display: "flex", alignItems: "center", gap: "5px" }}>
            <LocationOnIcon fontSize="small" style={{ color: "#888" }} />
            <Typography style={{ fontSize: "14px", color: "#555" }}>
              {card.address}
            </Typography>
          </Box>
        )}
      </Box>

      {/* Company Logo */}
      <Box className="cardDetailsRight">
        <img
          className="companyLogo"
          src={
            card.logoUrl ||
            require("../../../Assets/Images/Icons/logo.png")
          }
          alt="Logo"
          style={{
            width: "60px",
            height: "60px",
            objectFit: "contain",
            borderRadius: "50%",
            border: "1px solid #ddd",
          }}
        />
      </Box>
    </Box>

    {/* Social Media Links */}
    <div style={{display : "flex", justifyContent : "end"}}>   
    <Box
      style={{
        display: "flex",
        justifyContent: "end",
        alignItems: "end",
        marginTop: "10px",
        gap: "15px",
        flexWrap: "wrap",
      }}
    >
      {card.facebook && (
        <a
          href={`https://facebook.com/${card.facebook}`}
          target="_blank"
          rel="noopener noreferrer"
        >
          <img
            src={require("../../../Assets/Images/Icons/faceBookColored.png")}
            alt="Facebook"
            style={{ width: "18px", height: "18px" }}
          />
        </a>
      )}
      {card.instagram && (
        <a
          href={`https://instagram.com/${card.instagram}`}
          target="_blank"
          rel="noopener noreferrer"
        >
          <img
            src={require("../../../Assets/Images/Icons/instaColored.png")}
            alt="Instagram"
            style={{ width: "18px", height: "18px" }}
          />
        </a>
      )}
      {card.twitter && (
        <a
          href={`https://twitter.com/${card.twitter}`}
          target="_blank"
          rel="noopener noreferrer"
        >
          <img
            src={require("../../../Assets/Images/Icons/twitterColored.png")}
            alt="Twitter"
            style={{ width: "18px", height: "18px" }}
          />
        </a>
      )}
      {card.linkedin && (
        <a
          href={`https://linkedin.com/in/${card.linkedin}`}
          target="_blank"
          rel="noopener noreferrer"
        >
          <img
            src={require("../../../Assets/Images/Icons/linkedinColored.png")}
            alt="LinkedIn"
            style={{ width: "18px", height: "18px" }}
          />
        </a>
      )}
      {card.youtube && (
        <a
          href={`https://youtube.com/${card.youtube}`}
          target="_blank"
          rel="noopener noreferrer"
        >
          <img
            src={require("../../../Assets/Images/Icons/youtubeColored.png")}
            alt="YouTube"
            style={{ width: "18px", height: "18px" }}
          />
        </a>
      )}
    </Box>
    </div>
  </Box>
))}



      {/* Menu for Share and Delete */}
      <Menu
        anchorEl={anchorEl}
        open={open}
        onClose={handleMenuClose}
        anchorOrigin={{ vertical: "top", horizontal: "right" }}
        transformOrigin={{ vertical: "top", horizontal: "right" }}
      >
        <MenuItem onClick={() => handleShare(selectedCardId || "")}>
          <ShareIcon sx={{ marginRight: "8px" }} />
          Share
        </MenuItem>
        <MenuItem
          onClick={() => {
            const confirmDelete = window.confirm(
              "Are you sure you want to delete this card?"
            );
            if (confirmDelete && selectedCardId) {
              // Retrieve token from cookies
              const token = cookies.get("token");

              if (!token) {
                alert("Authorization token is missing!");
                return;
              }

              // Call your delete API here
              fetch(
                `${process.env.REACT_APP_API_URL}/cards/${selectedCardId}`,
                {
                  method: "DELETE",
                  headers: {
                    "Content-Type": "application/json",
                    Authorization: `${token}`,
                  },
                }
              )
                .then((response) => {
                  if (!response.ok) {
                    throw new Error(
                      `Delete failed with status: ${response.status}`
                    );
                  }
                  return response.json();
                })
                .then((data) => {
                  alert("Card deleted successfully!");
                  // handleDelete(selectedCardId); // Trigger any additional deletion logic
                  window.location.reload();
                })
                .catch((err) => {
                  alert("An error occurred while deleting the card.");
                  console.error(err.message);
                })
                .finally(() => {
                  handleMenuClose(); // Close the menu after deletion
                });
            }
          }}
        >
          <DeleteIcon sx={{ marginRight: "8px" }} />
          Delete
        </MenuItem>
      </Menu>
    </Box>
  );
};

export default CustomCards;
