import React from "react";
import { TextField } from "@mui/material";
import "./inputField.css";

interface FieldDetails {
  value?: any;
  onChange?: any;
  label?: string;
  type?: string;
  onInput?: any;
  ref?: any;
}

export default function InputField({ value, onChange, label, type, onInput, ref }: FieldDetails) {
  return (
    <TextField
      className="nameFieldBox"
      value={value}
      onChange={onChange}
      onInput={onInput}
      label={label}
      size="small"
      type={type}
      ref={ref}
      InputLabelProps={{
        shrink: !!value, // Dynamically shrink the label only if there's a value
      }}
      sx={{
        input: {
          fontFamily:
            "system-ui, -apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto, Oxygen, Ubuntu, Cantarell, 'Open Sans', 'Helvetica Neue', sans-serif;",
        },
        label: {
          fontFamily:
            "system-ui, -apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto, Oxygen, Ubuntu, Cantarell, 'Open Sans', 'Helvetica Neue', sans-serif;",
        },
      }}
    />
  );
}
