import { Button, TextField } from "@mui/material";
import React from "react";
import AddCircleOutlineRoundedIcon from "@mui/icons-material/AddCircleOutlineRounded";
import { commonColors } from "../../../Assets/colors.ts";

interface Props {
  handleSearch?: any;
  handleOpen?: any;
  buttonName?: any;
  placeHolder?: any;
  handleScanClick?: any; // Add this prop to handle the scan action
}

function AddSearchBar({
  handleSearch,
  handleOpen,
  buttonName,
  placeHolder,
  handleScanClick, // Destructure the prop here
}: Props) {
  return (
    <div style={{ width: "100%" }}>
      <span className="addSearchBtn">
        <TextField
          style={{ width: "98%" }}
          placeholder={placeHolder}
          onChange={handleSearch}
        />
        <Button
          variant="contained"
          color="primary"
          onClick={handleOpen}
          sx={{ marginLeft: 2, marginTop: 2 }}
          startIcon={<AddCircleOutlineRoundedIcon />}
        >
          {buttonName}
        </Button>

        {/* Scan Button */}
        <Button
          variant="contained"
          color="secondary"
          onClick={handleScanClick}
          sx={{ marginLeft: 2, marginTop: 2 }} // Adds margin between the "Add" and "Scan" buttons
        >
          Scan
        </Button>
      </span>
    </div>
  );
}

export default AddSearchBar;
