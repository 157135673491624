import React from "react";
import SignupForm from "../../Components/signupComponents/SignupForm.tsx";
import texts from "../../Assets/CommonTexts/texts.json";

interface SignupPageProps {
  setIsLogedIn: (value: boolean) => void;
}

export default function SignupPage({ setIsLogedIn }: SignupPageProps) {
  return <SignupForm commonTexts={texts.commonText} setIsLogedIn={setIsLogedIn} />;
} 