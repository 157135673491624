// import React, { useEffect, useState } from "react";
// import "./signupForm.css";
// import {
//   Box,
//   Button,
//   Divider,
//   Typography,
//   useMediaQuery,
//   Link,
// } from "@mui/material";
// import InputField from "../commonComponents/InputField/inputField.tsx";
// import { validateEmail } from "../../utils/commonRegex.ts";
// import service from "../../services/service.ts";
// import Cookies from "universal-cookie";
// import { useNavigate } from "react-router";
// import { paths } from "../../values/paths.ts";
// import { auth, googleProvider } from "../../config/firbase.ts";
// import { signInWithPopup } from "firebase/auth";

// interface SignupFormProps {
//   commonTexts?: any;
//   setIsLogedIn?: any;
// }

// export default function SignupForm({
//   commonTexts,
//   setIsLogedIn,
// }: SignupFormProps) {
//   const cookies = new Cookies();
//   const navigate = useNavigate();
//   const maxW900px = useMediaQuery("(max-width: 900px)");

//   const [signupData, setSignupData] = useState({
//     email: "",
//     password: "",
//     confirmPassword: "",
//   });

//   const [errors, setErrors] = useState({
//     email: false,
//     password: false,
//     confirmPassword: false,
//   });

//   const [showAlert, setShowAlert] = useState(false);

//   const handleSignupData =
//     (keyName: string) => (e: React.ChangeEvent<HTMLInputElement>) => {
//       setSignupData({ ...signupData, [keyName]: e.target.value });
//       setShowAlert(false);
//     };

//   useEffect(() => {
//     if (validateEmail(signupData.email)) {
//       setErrors((prev) => ({ ...prev, email: false }));
//     } else {
//       setErrors((prev) => ({ ...prev, email: true }));
//     }
//   }, [signupData.email]);

//   useEffect(() => {
//     if (signupData.password === signupData.confirmPassword) {
//       setErrors((prev) => ({ ...prev, confirmPassword: false }));
//     } else {
//       setErrors((prev) => ({ ...prev, confirmPassword: true }));
//     }
//   }, [signupData.password, signupData.confirmPassword]);

//   const handleSignup = async () => {
//     if (
//       !signupData.email ||
//       !signupData.password ||
//       !signupData.confirmPassword
//     ) {
//       alert("Please fill in all required fields.");
//       setShowAlert(true);
//       return;
//     }

//     if (errors.email) {
//       alert("Invalid email format.");
//       setShowAlert(true);
//       return;
//     }

//     if (errors.confirmPassword) {
//       alert("Passwords do not match.");
//       setShowAlert(true);
//       return;
//     }

//     try {
//       const apiUrl = process.env.REACT_APP_LOGIN_API_URL; // Fetch from environment variables
//       if (!apiUrl) {
//         throw new Error(
//           "API base URL is not defined in environment variables."
//         );
//       }

//       const response = await fetch(`${apiUrl}/register`, {
//         method: "POST",
//         headers: {
//           "Content-Type": "application/json",
//         },
//         body: JSON.stringify({
//           email: signupData.email,
//           password: signupData.password,
//         }),
//       });

//       if (!response.ok) {
//         const errorData = await response.json();
//         throw new Error(
//           errorData?.message || `Signup failed with status: ${response.status}`
//         );
//       }

//       const data = await response.json();
//       console.log("Signup response:", data);

//       // Save token if provided
//       cookies.set("token", data?.accessToken, { path: "/" });
//       navigate("/confirm-email");
//     } catch (err) {
//       alert(err.message || "An unknown error occurred.");
//       setShowAlert(true);
//       console.error("Signup error:", err.message);
//     }
//   };

//   return (
//     <Box className="signupPage">
//       <Box className="signupLogoBox">
//         <img
//           src={require("../../Assets/Images/Icons/logo.png")}
//           className="signupPageLogo"
//           alt="Logo"
//         />
//         {!maxW900px && (
//           <img
//             src={require("../../Assets/Images/image/loginImg.png")}
//             className="signupPageImg"
//             alt="Login"
//           />
//         )}
//       </Box>
//       <Box className="signupRightBox">
//         <Box className="signupRightSubBox">
//           {maxW900px && (
//             <Box sx={{ display: "flex", justifyContent: "center", mb: 2 }}>
//               <img
//                 src={require("../../Assets/Images/Icons/logo.png")}
//                 className="signupPageLogo"
//                 alt="Logo"
//               />
//             </Box>
//           )}
//           <div>
//             <Typography className="signupBoxHeadingText">
//               {commonTexts?.createAccount}
//             </Typography>
//           </div>

//           <div>
//             <Typography className="signupInputHeading">
//               {commonTexts?.email}
//             </Typography>
//             <InputField
//               value={signupData.email}
//               onChange={handleSignupData("email")}
//               type="email"
//               label={signupData.email ? "" : commonTexts?.mailCom}
//             />
//             {showAlert && !signupData.email && (
//               <Typography className="alertText">
//                 {commonTexts?.enterEmail}
//               </Typography>
//             )}
//             {errors.email && signupData.email && (
//               <Typography className="alertText">
//                 {commonTexts?.emailFormat}
//               </Typography>
//             )}
//           </div>

//           <div>
//             <Typography className="signupInputHeading">
//               {commonTexts?.password}
//             </Typography>
//             <InputField
//               value={signupData.password}
//               onChange={handleSignupData("password")}
//               type="password"
//               label={signupData.password ? "" : "•••••••••"}
//             />
//             {showAlert && !signupData.password && (
//               <Typography className="alertText">
//                 {commonTexts?.enterPassword}
//               </Typography>
//             )}
//           </div>

//           <div>
//             <Typography className="signupInputHeading">
//               {commonTexts?.confirmPassword}
//             </Typography>
//             <InputField
//               value={signupData.confirmPassword}
//               onChange={handleSignupData("confirmPassword")}
//               type="password"
//               label={signupData.confirmPassword ? "" : "•••••••••"}
//             />
//             {errors.confirmPassword && signupData.confirmPassword && (
//               <Typography className="alertText">
//                 {commonTexts?.passwordsDoNotMatch}
//               </Typography>
//             )}
//           </div>

//           <Box className="signupBtnBox">
//             <Button className="signupBtn" onClick={handleSignup}>
//               Signup
//             </Button>
//           </Box>

//           <Divider className="dividerText">{commonTexts?.Or}</Divider>

//           <Typography className="haveAccountText">
//             {commonTexts?.alreadyHaveAccount}
//             <Link
//               underline="none"
//               onClick={() => navigate(paths.login)}
//               className="loginText"
//               style={{ cursor: "pointer" }}
//             >
//               {commonTexts?.login}
//             </Link>
//           </Typography>
//         </Box>
//       </Box>
//     </Box>
//   );
// }

import React, { useEffect, useState } from "react";
import "./signupForm.css";
import {
  Box,
  Button,
  Divider,
  Typography,
  useMediaQuery,
  Link,
} from "@mui/material";
import InputField from "../commonComponents/InputField/inputField.tsx";
import { validateEmail } from "../../utils/commonRegex.ts";
import service from "../../services/service.ts";
import Cookies from "universal-cookie";
import { useNavigate } from "react-router";
import { paths } from "../../values/paths.ts";
import { auth, googleProvider } from "../../config/firbase.ts";
import { signInWithPopup } from "firebase/auth";

interface SignupFormProps {
  commonTexts?: any;
  setIsLogedIn?: any;
}

export default function SignupForm({
  commonTexts,
  setIsLogedIn,
}: SignupFormProps) {
  const cookies = new Cookies();
  const navigate = useNavigate();
  const maxW900px = useMediaQuery("(max-width: 900px)");

  const [signupData, setSignupData] = useState({
    email: "",
    password: "",
    confirmPassword: "",
  });

  const [errors, setErrors] = useState({
    email: false,
    password: false,
    confirmPassword: false,
  });

  const [showAlert, setShowAlert] = useState(false);

  const handleSignupData =
    (keyName: string) => (e: React.ChangeEvent<HTMLInputElement>) => {
      setSignupData({ ...signupData, [keyName]: e.target.value });
      setShowAlert(false);
    };

  useEffect(() => {
    if (validateEmail(signupData.email)) {
      setErrors((prev) => ({ ...prev, email: false }));
    } else {
      setErrors((prev) => ({ ...prev, email: true }));
    }
  }, [signupData.email]);

  useEffect(() => {
    if (signupData.password === signupData.confirmPassword) {
      setErrors((prev) => ({ ...prev, confirmPassword: false }));
    } else {
      setErrors((prev) => ({ ...prev, confirmPassword: true }));
    }
  }, [signupData.password, signupData.confirmPassword]);

  const handleSignup = async () => {
    if (
      !signupData.email ||
      !signupData.password ||
      !signupData.confirmPassword
    ) {
      alert("Please fill in all required fields.");
      setShowAlert(true);
      return;
    }

    if (errors.email) {
      alert("Invalid email format.");
      setShowAlert(true);
      return;
    }

    if (errors.confirmPassword) {
      alert("Passwords do not match.");
      setShowAlert(true);
      return;
    }

    try {
      const apiUrl = process.env.REACT_APP_LOGIN_API_URL;
      if (!apiUrl) {
        throw new Error(
          "API base URL is not defined in environment variables."
        );
      }

      const response = await fetch(`${apiUrl}/register`, {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify({
          email: signupData.email,
          password: signupData.password,
        }),
      });

      if (!response.ok) {
        const errorData = await response.json();
        throw new Error(
          errorData?.message || `Signup failed with status: ${response.status}`
        );
      }

      const data = await response.json();
      console.log("Signup response:", data);

      // Save token if provided
      cookies.set("token", data?.accessToken, { path: "/" });

      // Navigate to confirmation page with email
      navigate("/confirm-email", { state: { email: signupData.email } });
    } catch (err) {
      alert(err.message || "An unknown error occurred.");
      setShowAlert(true);
      console.error("Signup error:", err.message);
    }
  };

  return (
    <Box className="signupPage">
      <Box className="signupLogoBox">
        <img
          src={require("../../Assets/Images/Icons/logo.png")}
          className="signupPageLogo"
          alt="Logo"
        />
        {!maxW900px && (
          <img
            src={require("../../Assets/Images/image/loginImg.png")}
            className="signupPageImg"
            alt="Login"
          />
        )}
      </Box>
      <Box className="signupRightBox">
        <Box className="signupRightSubBox">
          {maxW900px && (
            <Box sx={{ display: "flex", justifyContent: "center", mb: 2 }}>
              <img
                src={require("../../Assets/Images/Icons/logo.png")}
                className="signupPageLogo"
                alt="Logo"
              />
            </Box>
          )}
          <div>
            <Typography className="signupBoxHeadingText">
              {commonTexts?.createAccount}
            </Typography>
          </div>

          <div>
            <Typography className="signupInputHeading">
              {commonTexts?.email}
            </Typography>
            <InputField
              value={signupData.email}
              onChange={handleSignupData("email")}
              type="email"
              label={signupData.email ? "" : commonTexts?.mailCom}
            />
            {showAlert && !signupData.email && (
              <Typography className="alertText">
                {commonTexts?.enterEmail}
              </Typography>
            )}
            {errors.email && signupData.email && (
              <Typography className="alertText">
                {commonTexts?.emailFormat}
              </Typography>
            )}
          </div>

          <div>
            <Typography className="signupInputHeading">
              {commonTexts?.password}
            </Typography>
            <InputField
              value={signupData.password}
              onChange={handleSignupData("password")}
              type="password"
              label={signupData.password ? "" : "•••••••••"}
            />
            {showAlert && !signupData.password && (
              <Typography className="alertText">
                {commonTexts?.enterPassword}
              </Typography>
            )}
          </div>

          <div>
            <Typography className="signupInputHeading">
              {commonTexts?.confirmPassword}
            </Typography>
            <InputField
              value={signupData.confirmPassword}
              onChange={handleSignupData("confirmPassword")}
              type="password"
              label={signupData.confirmPassword ? "" : "•••••••••"}
            />
            {errors.confirmPassword && signupData.confirmPassword && (
              <Typography className="alertText">
                {commonTexts?.passwordsDoNotMatch}
              </Typography>
            )}
          </div>

          <Box className="signupBtnBox">
            <Button className="signupBtn" onClick={handleSignup}>
              Signup
            </Button>
          </Box>

          <Divider className="dividerText">{commonTexts?.Or}</Divider>

          <Typography className="haveAccountText">
            {commonTexts?.alreadyHaveAccount}
            <Link
              underline="none"
              onClick={() => navigate(paths.login)}
              className="loginText"
              style={{ cursor: "pointer" }}
            >
              {commonTexts?.login}
            </Link>
          </Typography>
        </Box>
      </Box>
    </Box>
  );
}
