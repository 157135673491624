// import React , {useState} from "react";
// import { Box, useMediaQuery } from "@mui/material";
// import texts from "../../Assets/CommonTexts/texts.json"
// import SideBar from "../../Components/commonComponents/sideBar/sideBar.tsx";
// import ScannerBox from "../../Components/scannerComponent/scannerBox.tsx";
// import HeaderBar from "../../Components/commonComponents/headerBar/headerBar.tsx";
// import "./scannerPage.css"

// export default function ScannerPage() {

//     // Custome States
//     const [openDrawer, setOpenDrawer] = useState(false)
//     const maxWidth890px = useMediaQuery('(max-width:890px)')
//     const [searchData, setSearchData] = useState('')
//     const [addNewCard, setAddNewCard] = useState(false);

//     // Custome Methodes
//     const handleOpen = () => {
//         return setAddNewCard(true);
//       };
//     const handleDrawer = (value: any) => {
//         setOpenDrawer(value)
//         }

//     // UI Implamentation
//     return (
//         <Box className="scannerPageMainBox">
//             {/* <SideBar /> */}
//             {!maxWidth890px && <SideBar commonTexts={texts.commonText} openDrawer={openDrawer}/>}
//             <Box className='scannerPagelayout'>
//             <HeaderBar  openAddCards={handleOpen} openDrawer={openDrawer} handleDrawer={handleDrawer}
//               searchInput={searchData}
//               commonText={texts.commonText}
//               />
//               <Box className="scannerPageShowBox">
//               <ScannerBox texts={texts.commonText} />
//               </Box>
//             </Box>
//         </Box>
//     )
// }
import React, { useState } from "react";
import { Box, useMediaQuery } from "@mui/material";
import texts from "../../Assets/CommonTexts/texts.json";
import SideBar from "../../Components/commonComponents/sideBar/sideBar.tsx";
import ScannerBox from "../../Components/scannerComponent/scannerBox.tsx";
import HeaderBar from "../../Components/commonComponents/headerBar/headerBar.tsx";
import "./scannerPage.css";

export default function ScannerPage() {
  const [openDrawer, setOpenDrawer] = useState(false);
  const maxWidth890px = useMediaQuery("(max-width:890px)");
  const [searchData, setSearchData] = useState("");
  const [addNewCard, setAddNewCard] = useState(false);
  const [cardDetails, setCardDetails] = useState(null); // API response data

  const handleOpen = () => setAddNewCard(true);
  const handleDrawer = (value: any) => setOpenDrawer(value);

  return (
    <Box className="scannerPageMainBox">
      {/* {!maxWidth890px && (
        <SideBar commonTexts={texts.commonText} openDrawer={openDrawer} />
      )} */}
      <Box className="scannerPagelayout">
        {/* <HeaderBar
          openAddCards={handleOpen}
          openDrawer={openDrawer}
          handleDrawer={handleDrawer}
          searchInput={searchData}
          commonText={texts.commonText}
        /> */}
        <Box className="scannerPageShowBox">
          <ScannerBox
            texts={texts.commonText}
            setCardDetails={setCardDetails}
          />
        </Box>
        {cardDetails && (
          <Box className="cardDetailsBox">
            <h3>Card Details:</h3>
            <pre>{JSON.stringify(cardDetails, null, 2)}</pre>
          </Box>
        )}
      </Box>
    </Box>
  );
}
