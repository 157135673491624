// import React, { useState } from "react";
// import { useLocation, useNavigate } from "react-router";
// import InputField from "../Components/commonComponents/InputField/inputField.tsx";
// import {
//   Box,
//   Button,
//   Divider,
//   Typography,
//   Checkbox,
//   FormControlLabel,
//   useMediaQuery,
//   Link,
// } from "@mui/material";
// import Cookies from "universal-cookie";
// interface ConfirmationPageProps {
//   setIsLogedIn: (value: boolean) => void;
// }

// export default function ConfirmationPage({
//   setIsLogedIn,
// }: ConfirmationPageProps) {
//   const maxW900px = useMediaQuery("(max-width: 900px)");
//   const location = useLocation();
//   const navigate = useNavigate();
//   const [email, setEmail] = useState(location.state?.email || ""); // Get the email from state
//   const [otp, setOtp] = useState("");
//   const [errors, setErrors] = useState({ email: false, otp: false });

//   const handleEmailChange = (e: React.ChangeEvent<HTMLInputElement>) => {
//     setEmail(e.target.value);
//     setErrors((prev) => ({ ...prev, email: false }));
//   };

//   const handleOtpChange = (e: React.ChangeEvent<HTMLInputElement>) => {
//     setOtp(e.target.value);
//     setErrors((prev) => ({ ...prev, otp: false }));
//   };

//   const handleConfirm = async () => {
//     const cookies = new Cookies();
//     if (!email || !otp) {
//       setErrors({
//         email: !email,
//         otp: !otp,
//       });
//       return alert("Please fill in all required fields.");
//     }

//     try {
//       const apiUrl = process.env.REACT_APP_LOGIN_API_URL;
//       const response = await fetch(`${apiUrl}/confirm`, {
//         method: "POST",
//         headers: {
//           "Content-Type": "application/json",
//         },
//         body: JSON.stringify({
//           email, // Use the email passed from props or state
//           confirmationCode: otp, // Send OTP as confirmationCode
//         }),
//       });

//       if (!response.ok) {
//         throw new Error("OTP verification failed.");
//       }

//       const data = await response.json();
//       console.log("OTP Verified:", data);
//       alert("OTP Verified. Account created successfully!");
//       setIsLogedIn(cookies.get("token"));

//       // Redirect to login or dashboard
//       navigate("/"); // Adjust the path as required
//     } catch (err) {
//       console.error(err.message);
//       alert(err.message || "An unknown error occurred.");
//     }
//   };

//   return (
//     <Box className="signupPage">
//       {!maxW900px && (
//         <Box className="loginLogoBox">
//           <img
//             src={require("../Assets/Images/Icons/logo.png")}
//             className="loginPageLogo"
//           />
//           <img
//             src={require("../Assets/Images/image/loginImg.png")}
//             className="loginPageImg"
//           />
//         </Box>
//       )}
//       <Box className="signupRightBox">
//         <Box className="signupRightSubBox">
//           <Typography className="signupBoxHeadingText">
//             Email Verification
//           </Typography>
//           <Typography className="signupBoxTitleText">
//             Please verify your email: <strong>{email}</strong>
//           </Typography>

//           {/* Email Field */}
//           <div>
//             <Typography className="signupInputHeading">Email</Typography>
//             <InputField
//               value={email}
//               onChange={handleEmailChange}
//               type="email"
//               label="Enter Email"
//             />
//             {errors.email && (
//               <Typography className="alertText">Email is required.</Typography>
//             )}
//           </div>

//           {/* OTP Field */}
//           <div>
//             <Typography className="signupInputHeading">OTP</Typography>
//             <InputField
//               value={otp}
//               onChange={handleOtpChange}
//               type="text"
//               label="Enter OTP"
//             />
//             {errors.otp && (
//               <Typography className="alertText">OTP is required.</Typography>
//             )}
//           </div>

//           <Box className="signupBtnBox">
//             <Button className="signupBtn" onClick={handleConfirm}>
//               Confirm
//             </Button>
//           </Box>
//         </Box>
//       </Box>
//     </Box>
//   );
// }

import React, { useState } from "react";
import { useLocation, useNavigate } from "react-router";
import InputField from "../Components/commonComponents/InputField/inputField.tsx";
import {
  Box,
  Button,
  Divider,
  Typography,
  Checkbox,
  FormControlLabel,
  useMediaQuery,
  Link,
} from "@mui/material";
import Cookies from "universal-cookie";
interface ConfirmationPageProps {
  setIsLogedIn: (value: boolean) => void;
}

export default function ConfirmationPage({
  setIsLogedIn,
}: ConfirmationPageProps) {
  const maxW900px = useMediaQuery("(max-width: 900px)");
  const location = useLocation();
  const navigate = useNavigate();
  const [email, setEmail] = useState(location.state?.email || ""); // Get the email from state
  const [otp, setOtp] = useState("");
  const [errors, setErrors] = useState({ email: false, otp: false });

  const handleEmailChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    setEmail(e.target.value);
    setErrors((prev) => ({ ...prev, email: false }));
  };

  const handleOtpChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    setOtp(e.target.value);
    setErrors((prev) => ({ ...prev, otp: false }));
  };

  const handleConfirm = async () => {
    const cookies = new Cookies();
    if (!email || !otp) {
      setErrors({
        email: !email,
        otp: !otp,
      });
      return alert("Please fill in all required fields.");
    }

    try {
      const apiUrl = process.env.REACT_APP_LOGIN_API_URL;
      const response = await fetch(`${apiUrl}/confirm`, {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify({
          email, // Use the email passed from props or state
          confirmationCode: otp, // Send OTP as confirmationCode
        }),
      });

      if (!response.ok) {
        throw new Error("OTP verification failed.");
      }

      const data = await response.json();
      console.log("OTP Verified:", data);
      alert("OTP Verified. Account created successfully!");
      setIsLogedIn(cookies.get("token"));

      // Redirect to login or dashboard
      navigate("/"); // Adjust the path as required
    } catch (err) {
      console.error(err.message);
      alert(err.message || "An unknown error occurred.");
    }
  };

  return (
    <Box className="signupPage">
      {!maxW900px && (
        <Box className="loginLogoBox">
          <img
            src={require("../Assets/Images/Icons/logo.png")}
            className="loginPageLogo"
          />
          <img
            src={require("../Assets/Images/image/loginImg.png")}
            className="loginPageImg"
          />
        </Box>
      )}
      <Box className="signupRightBox">
        <Box className="signupRightSubBox">
          <Typography className="signupBoxHeadingText">
            Email Verification
          </Typography>
          <Typography className="signupBoxTitleText">
            Please verify your email: <strong>{email}</strong>
          </Typography>

          {/* Email Field */}
          <div>
            <Typography className="signupInputHeading">Email</Typography>
            <InputField
              value={email}
              onChange={handleEmailChange}
              type="email"
              label="Enter Email"
            />
            {errors.email && (
              <Typography className="alertText">Email is required.</Typography>
            )}
          </div>

          {/* OTP Field */}
          <div>
            <Typography className="signupInputHeading">OTP</Typography>
            <InputField
              value={otp}
              onChange={handleOtpChange}
              type="text"
              label="Enter OTP"
            />
            {errors.otp && (
              <Typography className="alertText">OTP is required.</Typography>
            )}
          </div>

          <Box className="signupBtnBox">
            <Button className="signupBtn" onClick={handleConfirm}>
              Confirm
            </Button>
          </Box>
        </Box>
      </Box>
    </Box>
  );
}
