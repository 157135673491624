import React, { useEffect, useState } from "react";
import "./App.css";
import DashBoardPage from "./Pages/dashBoard/dashBoardPage.tsx";
import { CssBaseline, StyledEngineProvider } from "@mui/material";
import { Navigate, Route, Routes } from "react-router";
import { paths } from "./values/paths.ts";
import ScannerPage from "./Pages/scannerPage/scannerPage.tsx";
import QrCodePage from "./Pages/qrCodePage/qrCodePage.tsx";
import LoginPage from "./Pages/loginPage/login.tsx";
import Cookies from "universal-cookie";
import PublicDetailsPage from "./Pages/publicDetailsPage/publicDetails.tsx";
import SettingPage from "./Pages/settingPage/settingPage.tsx";
import ContactPage from "./Pages/contactPage/contactPage.tsx";
import Homepage from "./Pages/homePage/home.tsx";
import CardPage from "./Pages/cardPage/cardPage.tsx";
import SignupPage from "./Pages/signupPage/signupPage.tsx";
import ForgotPassword from "./Pages/forgotPasswordPage/forgotPassword.tsx";
import ConfirmationPage from "./Pages/confirmationPage.tsx";

function App() {
  const cookies = new Cookies();
  const [isLogedIn, setIsLogedIn] = useState(cookies.get("token"));

  return (
    <StyledEngineProvider injectFirst>
      <CssBaseline />
      <Routes>
        <Route
          path={paths.cards}
          element={
            isLogedIn ? (
              <DashBoardPage />
            ) : (
              <Navigate replace to={paths.login} />
            )
          }
        />
        {/* <Route path={paths.cards} element={ <DashBoardPage/> } /> */}
        {/* <Route path={paths.home} element={isLogedIn ? <Homepage/> : <Navigate replace to={paths.login}/>} /> */}
        {/* <Route path={paths.home} element={<Homepage/>} /> */}
        <Route path={paths.card} element={<CardPage />} />
        <Route
          path={paths.scanner}
          element={
            isLogedIn ? <ScannerPage /> : <Navigate replace to={paths.login} />
          }
        />
        <Route
          path={paths.qrcode}
          element={
            isLogedIn ? <QrCodePage /> : <Navigate replace to={paths.login} />
          }
        />
        <Route
          path={paths.login}
          element={
            isLogedIn ? (
              <Navigate replace to={paths.cards} />
            ) : (
              <LoginPage setIsLogedIn={setIsLogedIn} />
            )
          }
        />
        <Route path={paths.publicDetails} element={<PublicDetailsPage />} />
        <Route
          path={paths.setting}
          element={
            isLogedIn ? <SettingPage /> : <Navigate replace to={paths.login} />
          }
        />
        <Route
          path={paths.contact}
          element={
            isLogedIn ? <ContactPage /> : <Navigate replace to={paths.login} />
          }
        />
        <Route
          path={paths.signup}
          element={
            isLogedIn ? (
              <Navigate replace to={paths.cards} />
            ) : (
              <SignupPage setIsLogedIn={setIsLogedIn} />
            )
            // <ForgotPassword setIsLogedIn={setIsLogedIn} />
          }
        />
        <Route
          path={"confirm-email"}
          element={
            isLogedIn ? (
              <Navigate replace to={paths.cards} />
            ) : (
              <ConfirmationPage setIsLogedIn={setIsLogedIn} />
            )
          }
        />
      </Routes>
    </StyledEngineProvider>
  );
}

export default App;
