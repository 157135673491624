// // //   const [value, setValue] = React.useState("1");
// // //   const [confirm, setConfirm] = useState(false);
// // //   const [openDrawer, setOpenDrawer] = useState(false);
// // //   const [showAddCardLayout, setShowAddCardLayout] = useState(false);
// // //   const [socialDetails, setSocialDetails] = useState({
// // //     instagramExpand: false,
// // //     youtubeExpand: false,
// // //     facebookExpand: false,
// // //     twitterExpand: false,
// // //     linkedinExpand: false,
// // //   });
// // //   const toggleAddCardLayout = () => {
// // //     setShowAddCardLayout((prev) => !prev);
// // //   };
// // //   const [cardId, setCardIs] = useState("");
// // //   const check = [] as any;

// // //   // Custom Methods
// // //   const handleTabs = (event: React.SyntheticEvent, newValue: string) => {
// // //     setValue(newValue);
// // //   };
// // //   const handleDrawer = (value: any) => {
// // //     setOpenDrawer(value);
// // //   };

// // //   const handleNextTab = () => {
// // //     // console.log('cardDate--->', cardData)
// // //     // setTabs((tab) =>  tab + 1)
// // //     handleSubmitData();
// // //   };

// // //   const handleBackTab = () => {
// // //     setTabs((tab) => tab - 1);
// // //   };

// // //   const handleCountrycode = (value) => {
// // //     setCountrycode(value);
// // //   };
// // //   const handleOpen = () => {
// // //     return setAddNewCard(true);
// // //   };

// // //   const handleClose = () => {
// // //     setShowAlert(false);
// // //     setAddNewCard(false);
// // //     setShowForm([]);
// // //     setFilterForm([]);
// // //     setCardData({
// // //       ...cardData,
// // //       layout: 1,
// // //       color: 1,
// // //       imgLayout: 1,
// // //       imageUrl: "",
// // //       // prifix: "",
// // //       firstName: "",
// // //       logoUrl: "",
// // //       // middleName: "",
// // //       lastName: "",
// // //       // suffix: "",
// // //       // accreditation: "",
// // //       // preFerredName: "",
// // //       // maidenName: "",
// // //       pronouns: "",
// // //       title: "",
// // //       department: "",
// // //       company: "",
// // //       headline: "",
// // //       // url: [],
// // //       phoneNumber: "",
// // //       emails: "",
// // //       address: "",
// // //       linkedin: "",
// // //       instagram: "",
// // //       twitter: "",
// // //       website: "",
// // //     });
// // //     setTabs("1");
// // //   };

// // //   const handleCardLayout = (keyName: any, e: any) => {
// // //     const value = e.target ? e.target.value : e;

// // //     setCardData((prev) => {
// // //       let updatedData = { ...prev, [keyName]: value };

// // //       // Ensure firstName, lastName, and cardName exist in the state
// // //       if (!updatedData.firstName) updatedData.firstName = "";
// // //       if (!updatedData.lastName) updatedData.lastName = "";
// // //       if (!updatedData.cardName) updatedData.cardName = "";

// // //       // Clear imageUrl if virtualImage is being set
// // //       if (keyName === "virtualImage") {
// // //         updatedData.imageUrl = ""; // Clear imageUrl
// // //       }

// // //       // Split fullName into firstName and lastName
// // //       if (keyName === "fullName") {
// // //         const nameParts = value.trim().split(" ");
// // //         updatedData.firstName = nameParts[0] || ""; // First part as firstName
// // //         updatedData.lastName = nameParts.slice(1).join(" ") || ""; // Remaining parts as lastName
// // //       }

// // //       // Handle cardName separately
// // //       if (keyName === "cardName") {
// // //         updatedData.cardName = value || ""; // Set or clear the cardName
// // //       }
// // //       return updatedData;
// // //     });
// // //   };

// // //   const handleCardDisplayLayout = (keyName: any, e: any) => {
// // //     setDisplayTexts({
// // //       ...displayTexts,
// // //       [keyName]: e.target ? e.target.value : e,
// // //     });
// // //   };
// // //   const handleProfileImage = (e: any) => {
// // //     if (e.target.files[0]) {
// // //       const imageUrl = URL.createObjectURL(e.target.files[0]);

// // //       setCardData((prev) => {
// // //         let updatedData = { ...prev, imageUrl };

// // //         // Clear virtualImage if imageUrl is being set
// // //         updatedData.virtualImage = ""; // Clear virtualImage
// // //         return updatedData;
// // //       });
// // //     }
// // //   };

// // //   const handleLogo = (e: any) => {
// // //     if (e.target.files[0]) {
// // //       setCardData({
// // //         ...cardData,
// // //         logoUrl: URL.createObjectURL(e.target.files[0]),
// // //       });
// // //     }
// // //   };

// // //   // const handleFieldInput = (keyName: any, e: any, i: any) => {
// // //   //   setUrls({ ...urls, [keyName]: e.target.value })
// // //   //   if(keyName === 'url'){
// // //   //     let url =  e.target.value
// // //   //     filterForm[i].url = url
// // //   //   } else if( keyName === 'displayText'){
// // //   //     let displayText = e.target.value
// // //   //     filterForm[i].displayText = displayText
// // //   //   }
// // //   // };

// // //   // const filterData2 = cardData.url.filter(
// // //   //   (item: any, i: any) =>
// // //   //     i === cardData.url.findIndex((items: any) => items.label === item.label)
// // //   // );

// // //   const handleUrlData = (e: any) => {
// // //     setIsFirstLoad(e);
// // //     const data = {
// // //       id: e.id,
// // //       label: e.label,
// // //       logoUrl: e.logo,
// // //       url: "",
// // //       displayText: "",
// // //       verfiyUrl: false,
// // //     };
// // //     showForm.push(data);
// // //     setShowFields(false);
// // //   };

// // //   // const handleUnSelect = (e: any) => {
// // //   //   let test = filterData2.filter((itm: any) => e.label !== itm.label);
// // //   //   setCardData({ ...cardData, url: test });
// // //   // };

// // //   const getCardData = async () => {
// // //     try {
// // //       const cookies = new Cookies();
// // //       const token = cookies.get("idToken"); // Retrieve token from cookies
// // //       console.log(`token`, token);
// // //       // Fetch API URL from environment variables
// // //       const apiUrl = process.env.REACT_APP_API_URL; // Replace with your variable name
// // //       if (!apiUrl) {
// // //         throw new Error(
// // //           "API base URL is not defined in environment variables."
// // //         );
// // //       }

// // //       // Make the API call using fetch
// // //       const response = await fetch(`${apiUrl}/cards`, {
// // //         method: "GET",
// // //         headers: {
// // //           "Content-Type": "application/json", // Ensure proper header
// // //           Authorization: `${token}`, // Add token to the Authorization header
// // //         },
// // //       });
// // //       console.log(response);

// // //       if (!response.ok) {
// // //         throw new Error(`API call failed with status: ${response.status}`);
// // //       }

// // //       const data = await response.json();
// // //       if (!Array.isArray(data)) {
// // //         throw new Error("Unexpected API response format. Expected an array.");
// // //       }

// // //       // Set the cards data
// // //       setAllcards(data);
// // //       console.log("All cards:", data);
// // //     } catch (error) {
// // //       console.error("Error fetching card data:", error.message);
// // //     }
// // //   };

// // //   const handleDeleteCard = async (e: any) => {
// // //     console.log("cardIdasdfasfd>", cardId);
// // //     await service1
// // //       .deleteCard(cardId)
// // //       .then(() => {
// // //         getCardData();
// // //         handelConfirmModal(e, false, "");
// // //       })
// // //       .catch((err) => {
// // //         console.log("err--->", err);
// // //       });
// // //   };

// // //   const handelConfirmModal = async (e: any, value: boolean, id: string) => {
// // //     // This ( e.stopPropagation()) will stop on child click it will trigger parent.
// // //     e.stopPropagation();
// // //     setConfirm(value);
// // //     setCardIs(id);
// // //   };

// // //   // useEffect(() => {
// // //   //   setLoading(!loading)
// // //   //   for (let index = 0; index < filterForm?.length; index++) {
// // //   //     const element = filterForm[index].label === 'Phone' ? OnlyNumbers(filterForm[index].url) : filterForm[index].label === 'Email' ? validateEmail(filterForm[index].url) :validateUrl(filterForm[index].url)
// // //   //     filterForm[index].verfiyUrl = element
// // //   //     // if (validateUrl(filterForm[index].url) && validateUrl(urls.url)){
// // //   //     //   setError(false);
// // //   //     // } else {setError(true)};
// // //   //   }
// // //   // },[urls]);

// // //   // const handleAddBtn = () => {
// // //   //   setLoading(!loading);
// // //   //   // console.log("asdf====",);
// // //   //   for (let index = 0; index < filterForm.length; index++) {
// // //   //     if(filterForm[index].url.length > 0 && filterForm[index].displayText.length > 0){
// // //   //       check.push(true)
// // //   //     } else {setError(true)}
// // //   //   }
// // //   //   console.log("asdf====",check.length , filterForm.length);
// // //   //   if(check.length === filterForm.length){
// // //   //     cardData?.url.push(...filterForm);
// // //   //     setFilterForm([]);
// // //   //     setShowForm([]);
// // //   //     setUrls({...urls,url:'', displayText:''})
// // //   //     setSubAlert(false)
// // //   //   } else {
// // //   //     setSubAlert(true)
// // //   //   }
// // //   // }

// // //   const handleSubmitData = async () => {
// // //     if (edit === true) {
// // //       if (tabs === 0) {
// // //         // if (cardData.imageUrl && cardData.logoUrl) {
// // //         setTabs(1);
// // //         setShowAlert(false);
// // //         // } else {
// // //         //   setShowAlert(true);
// // //         // }
// // //       } else if (tabs === 1) {
// // //         console.log("tab1111--->", tab);
// // //         if (
// // //           cardData.company.length &&
// // //           // cardData.department.length &&
// // //           cardData.firstName.length &&
// // //           // cardData.headline.length &&
// // //           cardData.lastName.length &&
// // //           // cardData.maidenName.length &&
// // //           // cardData.middleName.length &&
// // //           // cardData.preFerredName.length &&
// // //           // cardData.prifix.length &&
// // //           // cardData.pronouns.length &&
// // //           cardData.phoneNumber.length &&
// // //           validateEmail(cardData.emails) &&
// // //           // cardData.suffix.length &&
// // //           cardData.title.length
// // //           // cardData.accreditation.length
// // //         ) {
// // //           console.log("tab3333--->", tab);
// // //           setTabs(2);
// // //           setShowAlert(false);
// // //         } else {
// // //           setShowAlert(true);
// // //         }
// // //       } else {
// // //         if (tabs === 2) {
// // //           const modify = {
// // //             firstName: `${cardData.pronouns} ${cardData.firstName}`,
// // //             lastName: cardData.lastName,
// // //             emails: cardData.emails,
// // //             phoneNumber: cardData.phoneNumber,
// // //             address: cardData.address,
// // //             title: cardData.title,
// // //             company: cardData.company,
// // //             imageUrl: cardData.imageUrl,
// // //             logoUrl: cardData.logoUrl,
// // //             youtube: `${cardData.youtube}`,
// // //             linkedin: `${cardData.linkedin}`,
// // //             instagram: `${cardData.instagram}`,
// // //             twitter: `${cardData.twitter}`,
// // //             role: `${cardData.role}`,
// // //             facebook: `${cardData.facebook}`,
// // //             cardName: `${cardData.cardName}`,
// // //           };
// // //           setEdit(false);
// // //           setAddNewCard(false);
// // //           if (modify) {
// // //             await service1
// // //               .updateCard(modify, cardData?.cardId)
// // //               .then(() => {
// // //                 getCardData();
// // //                 // setCustomCard(customCard);
// // //                 setShowAlert(false);
// // //                 setAddNewCard(false);
// // //                 setShowForm([]);
// // //                 setFilterForm([]);
// // //                 setCardData({
// // //                   ...cardData,
// // //                   layout: 1,
// // //                   color: 1,
// // //                   imgLayout: 1,
// // //                   imageUrl: "",
// // //                   // prifix: "",
// // //                   firstName: "",
// // //                   logoUrl: "",
// // //                   // middleName: "",
// // //                   lastName: "",
// // //                   // suffix: "",
// // //                   // accreditation: "",
// // //                   // preFerredName: "",
// // //                   // maidenName: "",
// // //                   pronouns: "",
// // //                   title: "",
// // //                   department: "",
// // //                   company: "",
// // //                   headline: "",
// // //                   // url: [],
// // //                   phoneNumber: "",
// // //                   emails: "",
// // //                   address: "",
// // //                   linkedin: "",
// // //                   instagram: "",
// // //                   twitter: "",
// // //                   website: "",
// // //                 });
// // //                 setTabs("1");
// // //               })
// // //               .catch((err) => {
// // //                 console.log("err---->", err);
// // //               });
// // //           }
// // //         }
// // //       }
// // //     } else {
// // //       if (tabs === 0) {
// // //         // if (cardData.imageUrl && cardData.logoUrl) {
// // //         setTabs(1);
// // //         setShowAlert(false);
// // //         // } else {
// // //         //   setShowAlert(true);
// // //         // }
// // //       } else if (tabs === 1) {
// // //         if (
// // //           // cardData.company.length &&
// // //           // cardData.department.length &&
// // //           // cardData.firstName.length &&
// // //           // cardData.headline.length &&
// // //           // cardData.lastName.length &&
// // //           // cardData.phoneNumber.length &&
// // //           // validateEmail(cardData.emails) &&
// // //           // cardData.maidenName.length &&
// // //           // cardData.middleName.length &&
// // //           // cardData.preFerredName.length &&
// // //           // cardData.prifix.length &&
// // //           // cardData.pronouns.length &&
// // //           // cardData.suffix.length &&
// // //           // cardData.title.length
// // //           // cardData.accreditation.length
// // //           // New Ui Validation
// // //           cardData.fullName.length &&
// // //           validateEmail(cardData.emails) &&
// // //           cardData.phoneNumber.length &&
// // //           cardData.company.length &&
// // //           cardData.role.length
// // //         ) {
// // //           console.log("cardData", cardData);
// // //           setTabs(2);
// // //           setShowAlert(false);
// // //         } else {
// // //           setShowAlert(true);
// // //         }
// // //       } else {
// // //         if (tabs === 2) {
// // //           const modify = {
// // //             firstName: `${cardData.pronouns} ${cardData.firstName}`,
// // //             lastName: cardData.lastName,
// // //             emails: cardData.emails,
// // //             phoneNumber: cardData.phoneNumber,
// // //             address: cardData.address,
// // //             title: cardData.title,
// // //             company: cardData.company,
// // //             imageUrl: cardData.imageUrl,
// // //             logoUrl: cardData.logoUrl,
// // //             youtube: `${cardData.youtube}`,
// // //             linkedin: `${cardData.linkedin}`,
// // //             instagram: `${cardData.instagram}`,
// // //             twitter: `${cardData.twitter}`,
// // //             role: `${cardData.role}`,
// // //             facebook: `${cardData.facebook}`,
// // //             cardName: `${cardData.cardName}`,
// // //             // links: `${cardData.linkedin}, ${cardData.instagram}, ${cardData.twitter}, ${cardData.website}`,
// // //             // layout: cardData.layout,
// // //             // color: cardData.color,
// // //             // pronouns: cardData.pronouns,
// // //             // department: cardData.department,
// // //             // headline: cardData.headline,
// // //             // imgLayout: cardData.imgLayout,
// // //             // id:customCard.length ? customCard?.length : 0,
// // //             // prifix: cardData.prifix,
// // //             // middleName: cardData.middleName,
// // //             // suffix: cardData.suffix,
// // //             // accreditation: cardData.accreditation,
// // //             // preFerredName: cardData.preFerredName,
// // //             // maidenName: cardData.maidenName,
// // //             // url: cardData.url,
// // //             // linkedin: cardData.linkedin,
// // //             // instagram: cardData.instagram,
// // //             // twitter: cardData.twitter,
// // //             // website: cardData.website
// // //           };
// // //           if (
// // //             // cardData.imageUrl &&
// // //             // cardData.logoUrl &&
// // //             cardData.company.length &&
// // //             // cardData.firstName.length &&
// // //             // cardData.lastName.length &&
// // //             cardData.fullName &&
// // //             cardData.role &&
// // //             cardData.phoneNumber.length &&
// // //             validateEmail(cardData.emails) &&
// // //             socialValidation("instagramExpand", "instagram") &&
// // //             socialValidation("twitterExpand", "twitter") &&
// // //             socialValidation("linkedinExpand", "linkedin") &&
// // //             socialValidation("youtubeExpand", "youtube") &&
// // //             socialValidation("facebookExpand", "facebook")
// // //             // cardData.title.length
// // //           ) {
// // //             customCard.push(modify);
// // //             const response = await service1.addCard(modify);
// // //             if (response) {
// // //               getCardData();
// // //               setShowAlert(false);
// // //               setAddNewCard(false);
// // //               setShowForm([]);
// // //               setFilterForm([]);
// // //               setCardData({
// // //                 ...cardData,
// // //                 layout: 1,
// // //                 color: 1,
// // //                 imgLayout: 1,
// // //                 imageUrl: "",
// // //                 logoUrl: "",
// // //                 firstName: "",
// // //                 lastName: "",
// // //                 pronouns: "",
// // //                 title: "",
// // //                 department: "",
// // //                 company: "",
// // //                 headline: "",
// // //                 phoneNumber: "",
// // //                 emails: "",
// // //                 address: "",
// // //                 linkedin: "",
// // //                 instagram: "",
// // //                 twitter: "",
// // //                 website: "",
// // //               });
// // //               setTabs("1");
// // //             }
// // //           } else {
// // //             console.log("showAlert--->asdf", showAlert);
// // //             setShowAlert(true);
// // //           }
// // //         } else {
// // //           setShowAlert(true);
// // //         }
// // //       }
// // //     }
// // //   };

// // //   const handleEdit = async (e: any, item: any, index: any) => {
// // //     console.log("item?.cardId--->", item?.cardId);
// // //     e.stopPropagation();
// // //     const cardResponse = await service1.getCardById(item?.cardId);
// // //     //   console.log('cardResponse-->', cardResponse)
// // //     //   const divideLinks = cardResponse?.links?.split(",")
// // //     //   let instaLink = ''
// // //     //   let twitterLink = ''
// // //     //   let linkedInLink = ''
// // //     //   let websiteLink = ''
// // //     //   divideLinks.forEach((link: any) => {
// // //     //   console.log('link-->', link, link.includes('instagram'))
// // //     //     if (link.includes('instagram')) {
// // //     //       instaLink = link
// // //     //     } else if (link.includes('linkedin')) {
// // //     //       twitterLink = link
// // //     //     } else  if (link.includes('twitter')){
// // //     //       linkedInLink = link
// // //     //     } else {
// // //     //       websiteLink = link
// // //     //     }
// // //     // })
// // //     setCardData({
// // //       ...cardData,
// // //       // this key added forfully to pass cardid in payload.
// // //       cardId: cardResponse.cardId,
// // //       layout: cardResponse.layout,
// // //       color: cardResponse.color,
// // //       imgLayout: cardResponse.imageUrl,
// // //       imageUrl: cardResponse.imageUrl,
// // //       logoUrl: cardResponse.logoUrl,
// // //       // prifix: item.prifix,
// // //       firstName: cardResponse.firstName,
// // //       // middleName: item.middleName,
// // //       lastName: cardResponse.lastName,
// // //       // suffix: item.suffix,
// // //       // accreditation: item.accreditation,
// // //       // preFerredName: item.preFerredName,
// // //       // maidenName: item.maidenName,
// // //       pronouns: cardResponse.pronouns,
// // //       title: cardResponse.title,
// // //       department: cardResponse.department,
// // //       company: cardResponse.company,
// // //       headline: cardResponse.headline,
// // //       // url: item.url,
// // //       phoneNumber: cardResponse.phoneNumber,
// // //       emails: cardResponse.emails,
// // //       address: cardResponse.address,
// // //       linkedin: cardResponse.links,
// // //       // instagram: instaLink,
// // //       // twitter: twitterLink,
// // //       // website: websiteLink
// // //     });
// // //     setSelectCard(index);
// // //     setFilterForm(item.url);
// // //     setAddNewCard(true);
// // //     setEdit(true);
// // //   };

// // //   const handleRemoveUrl = (it: any) => {
// // //     setLoading(!loading);
// // //     const remove = filterForm.filter((e: any) => e.label !== it.label);
// // //     // setError(false)
// // //     setSubAlert(false);
// // //     setFilterForm(remove);
// // //     // setUrls({...urls,url:'', displayText:''})
// // //     setShowForm(remove);
// // //     setIsFirstLoad(remove);
// // //   };

// // //   const handleSearch = (e: any) => {
// // //     setSearchData(e.target.value);
// // //     const filterdItem = allCards.filter(
// // //       (user: any) =>
// // //         // state takes time tu update, so search result will be delayed and to improve search functionality i used e.target.value in filter method to improve search performance.
// // //         user?.firstName
// // //           ?.toLowerCase()
// // //           .includes(e.target.value?.toLowerCase()) ||
// // //         user?.emails?.toLowerCase().includes(e.target.value?.toLowerCase())
// // //     );
// // //     sewtShowSearchData(filterdItem);
// // //   };
// // //   const handleLocalImage = (e: any) => {
// // //     setCardData({ ...cardData, virtualImage: "" });
// // //     // setLoading(!loading);
// // //     // if(e.target.files[0]){
// // //     //   console.log('item---->', e.target.files[0])
// // //     //   let data = URL.createObjectURL(e.target.files[0]);
// // //     //   // setSelectedImage(data)
// // //     //   setCardData({...cardData, virtualImage: data})
// // //     // }
// // //   };
// // //   const handleImage = (item: any) => {
// // //     setLoading(!loading);
// // //     // setSelectedImage(item);
// // //     // let data = URL.createObjectURL(item);
// // //     console.log("item---->", item);
// // //     setCardData({ ...cardData, virtualImage: item });
// // //   };
// // //   const clearSearchInput = () => {
// // //     setSearchData("");
// // //   };
// // //   const handleExpandSocialDetails = (keyName: any) => () => {
// // //     setSocialDetails({ ...socialDetails, [keyName]: !socialDetails[keyName] });
// // //   };
// // //   const socialValidation = (expandKey: string, cardDataKey: string) => {
// // //     if (socialDetails[expandKey]) {
// // //       return cardData[cardDataKey].length && displayTexts[cardDataKey].length;
// // //     } else {
// // //       return true;
// // //     }
// // //   };
// // //   // Life Cycle Metod

// // //   useEffect(() => {
// // //     getCardData();
// // //     setLoading(!loading);
// // //     if (showForm !== 0) {
// // //       const filterData = showForm?.filter(
// // //         (item: any, i: any) =>
// // //           i === showForm?.findIndex((items: any) => items.label === item.label)
// // //       );
// // //       setFilterForm(filterData);
// // //     }
// // //   }, [isFirstLoad]);

// // //   // UI Implamentation

// // //   return (
// // //     <Box className="mainLoginBox" position={"relative"}>
// // //       {!maxWidth890px && (
// // //         <SideBar commonTexts={texts.commonText} openDrawer={openDrawer} />
// // //       )}
// // //       <Box className="layout" position={"relative"}>
// // //         <HeaderBar
// // //           openAddCards={handleOpen}
// // //           openDrawer={openDrawer}
// // //           handleDrawer={handleDrawer}
// // //           searchInput={searchData}
// // //           handleSearch={handleSearch}
// // //           clearSearchInput={clearSearchInput}
// // //           commonText={texts.commonText}
// // //         />
// // //         {maxWidth890px && !addNewCard && (
// // //           <div className="mobileSearchBox">
// // //             <SearchField
// // //               searchData={searchData}
// // //               handleSearch={handleSearch}
// // //               commonText={texts.commonText}
// // //               clearSearchInput={clearSearchInput}
// // //             />
// // //             <Button>
// // //               <img
// // //                 src={require("../../Assets/Images/Icons/filter.png")}
// // //                 alt="filter"
// // //               />
// // //             </Button>
// // //           </div>
// // //         )}
// // //         {/* <AddSearchBar placeHolder={texts?.commonText.search} buttonName={texts?.commonText.addCard} handleSearch={handleSearch}  handleOpen={handleOpen}/> */}
// // //         {/* <TabContext value={value}>
// // //         <Box sx={{ borderBottom: 1, borderColor: 'divider' }}>
// // //           <TabList onChange={handleTabs} aria-label="lab API tabs example">
// // //             <Tab label="Cards" value="1" />
// // //             <Tab label="VirtualBackGrounds" value="2"  />
// // //           </TabList>
// // //         </Box>
// // //         <TabPanel value="1">
// // //         <CustomCards handleOpen={handleOpen} searchData={searchData} showSearchData={showSearchData} cards={allCards} handleEdit={handleEdit} commonTexts={texts} handleDeleteCard={handelConfirmModal}/>
// // //         </TabPanel>
// // //         <TabPanel value="2">
// // //           <VirtualBackGrounds cards={allCards} commonTexts={texts} handleOpen={handleOpen}/>
// // //        </TabPanel>
// // //       </TabContext> */}
// // //         {!addNewCard && (
// // //           <CustomCards
// // //             handleOpen={handleOpen}
// // //             searchData={searchData}
// // //             showSearchData={showSearchData}
// // //             cards={allCards}
// // //             handleEdit={handleEdit}
// // //             commonTexts={texts.commonText}
// // //             handleDeleteCard={handelConfirmModal}
// // //           />
// // //         )}
// // //         {addNewCard && (
// // //           <AddCardLayout
// // //             cardData={cardData}
// // //             handleCardLayout={handleCardLayout}
// // //             commonTexts={texts.commonText}
// // //             handleProfileImage={handleProfileImage}
// // //             handleLogo={handleLogo}
// // //             handleLocalImage={handleLocalImage}
// // //             showAlert={showAlert}
// // //             validateEmail={validateEmail}
// // //             tabs={tabs}
// // //             handleNextTab={handleNextTab}
// // //             handleBackTab={handleBackTab}
// // //             displayTexts={displayTexts}
// // //             handleCardDisplayLayout={handleCardDisplayLayout}
// // //             countryCode={countryCode}
// // //             handleCountrycode={handleCountrycode}
// // //             handleImage={handleImage}
// // //             socialDetails={socialDetails}
// // //             handleExpandSocialDetails={handleExpandSocialDetails}
// // //             //  selectedImage={selectedImage}
// // //           />
// // //         )}
// // //         {/* <AddNewCard
// // //               addNewCard={addNewCard}
// // //               commonTexts={texts}
// // //               handleClose={handleClose}
// // //               tabs={tabs}
// // //               handleChange={handleChange}
// // //               filterForm={filterForm}
// // //               cardData={cardData}
// // //               handleCardLayout={handleCardLayout}
// // //               showAlert={showAlert}
// // //               handleProfileImage={handleProfileImage}
// // //               handleLogo={handleLogo}
// // //               // handleUnSelect={handleUnSelect}
// // //               // handleFieldInput={handleFieldInput}
// // //               handleRemoveUrl={handleRemoveUrl}
// // //               error={error}
// // //               // urls={urls}
// // //               subAlert={subAlert}
// // //               handleUrlData={handleUrlData}
// // //               handleSubmitData={handleSubmitData}`
// // //               // handleAddBtn={handleAddBtn}
// // //               // filterData2={filterData2}
// // //             /> */}
// // //         {allCards.length > 0 && !addNewCard && (
// // //           <Box className="dashboardCardFooterbox">
// // //             <Button className="dashboardCardEditBtn" onClick={handleOpen}>
// // //               Add Card
// // //             </Button>
// // //           </Box>
// // //         )}
// // //       </Box>
// // //       {/* <DetailsBox commonTexts={texts} /> */}
// // //       <ConfirmModal
// // //         open={confirm}
// // //         onClose={(e: any) => handelConfirmModal(e, false, "")}
// // //         confirmClick={(e: any) => handleDeleteCard(e)}
// // //         commonTexts={texts.commonText}
// // //       />
// // //     </Box>
// // //   );
// // // }

// // import React, { useEffect, useState } from "react";
// // import {
// //   Avatar,
// //   Box,
// //   Button,
// //   Divider,
// //   Drawer,
// //   List,
// //   ListItem,
// //   TextField,
// //   Typography,
// //   useMediaQuery,
// //   InputAdornment,
// // } from "@mui/material";

// // import "./dashBoardPage.css";
// // import texts from "../../Assets/CommonTexts/texts.json";
// // // import DetailsBox from "../../Components/dashboardComponent/detailsBox.tsx";
// // import SideBar from "../../Components/commonComponents/sideBar/sideBar.tsx";
// // // import AddNewCard from "../../Components/dashboardComponent/addNewCardComponents/addNewCard.tsx";
// // import {
// //   OnlyNumbers,
// //   validateEmail,
// //   validateUrl,
// // } from "../../utils/commonRegex.ts";
// // import AddNewCard from "../../Components/dashboardComponent/addNewCard/addNewCard.tsx";
// // import AddSearchBar from "../../Components/commonComponents/addSearchBar/addSearchBar.tsx";
// // import CustomCards from "../../Components/dashboardComponent/customCard/customCards.tsx";
// // import VirtualBackGrounds from "../../Components/dashboardComponent/virtualBackGrounds/virtualBackGrounds.tsx";
// // import Tab from "@mui/material/Tab";
// // import TabContext from "@mui/lab/TabContext";
// // import TabList from "@mui/lab/TabList";
// // import TabPanel from "@mui/lab/TabPanel";
// // import { tab } from "@testing-library/user-event/dist/tab";
// // import service1 from "../../services/service1.ts";
// // import ConfirmModal from "../../Components/commonComponents/modals/confirmModal/confirmModal.tsx";
// // import HeaderBar from "../../Components/commonComponents/headerBar/headerBar.tsx";
// // import AddCardLayout from "../../Components/dashboardComponent/addCardLayout/addCardLayout.tsx";
// // import CloseIcon from "@mui/icons-material/Close";
// // import { commonColors } from "../../Assets/colors.ts";
// // import SearchField from "../../Components/commonComponents/searchField/searchField.tsx";
// // import Cookies from "universal-cookie";
// // import ContactPage from "../contactPage/contactPage.tsx";
// // import AddCircleOutlineRoundedIcon from "@mui/icons-material/AddCircleOutlineRounded";

// // export default function DashBoardPage() {
// //   // Custom States
// //   const [showContactPage, setShowContactPage] = useState(false);

// //   const [addNewCard, setAddNewCard] = useState(false);
// //   const [customCard, setCustomCard] = useState<any>([]);
// //   const [countryCode, setCountrycode] = useState({
// //     code: "IN",
// //     label: "India",
// //     phone: "+91",
// //   });
// //   // const [selectedImage, setSelectedImage] = useState(""
// //   //   // require("../../Assets/Images/image/demo.jpg")
// //   // );
// //   const maxWidth890px = useMediaQuery("(max-width:890px)");

// //   const [cardData, setCardData] = useState({
// //     layout: 1,
// //     color: 1,
// //     imgLayout: 1,
// //     imageUrl: "",
// //     logoLayout: 1,
// //     logoUrl: "",
// //     firstName: "",
// //     lastName: "",
// //     pronouns: "",
// //     title: "",
// //     department: "",
// //     company: "",
// //     headline: "",
// //     phoneNumber: "",
// //     emails: "",
// //     address: "",
// //     linkedin: "",
// //     instagram: "",
// //     twitter: "",
// //     website: "",
// //     // new Ui keys
// //     fullName: "",
// //     role: "",
// //     youtube: "",
// //     facebook: "",
// //     virtualImage: "",
// //     cardName: "",
// //   });
// //   const [displayTexts, setDisplayTexts] = useState({
// //     instagram: "",
// //     youtube: "",
// //     facebook: "",
// //     twitter: "",
// //     linkedin: "",
// //   });
// //   const [showFields, setShowFields] = useState<any>(false);
// //   const [tabs, setTabs] = React.useState(0);
// //   const [edit, setEdit] = useState(false);
// //   const [selelctCard, setSelectCard] = useState<any>();
// //   // const [urls, setUrls] = useState({
// //   //   url: "",
// //   //   displayText: "",
// //   // })
// //   const [allCards, setAllcards] = useState<any>([]);
// //   const [isFirstLoad, setIsFirstLoad] = useState("");
// //   const [showForm, setShowForm] = useState<any>([]);
// //   const [loading, setLoading] = useState<any>(false);
// //   const [filterForm, setFilterForm] = useState<any>([]);
// //   const [showAlert, setShowAlert] = useState(false);
// //   const [searchData, setSearchData] = useState("");
// //   const [showSearchData, sewtShowSearchData] = useState([]);
// //   const [subAlert, setSubAlert] = useState(false);
// //   const [error, setError] = useState(false);
// //   const [value, setValue] = React.useState("1");
// //   const [confirm, setConfirm] = useState(false);
// //   const [openDrawer, setOpenDrawer] = useState(false);
// //   const [showAddCardLayout, setShowAddCardLayout] = useState(false);
// //   const [socialDetails, setSocialDetails] = useState({
// //     instagramExpand: false,
// //     youtubeExpand: false,
// //     facebookExpand: false,
// //     twitterExpand: false,
// //     linkedinExpand: false,
// //   });
// //   const toggleAddCardLayout = () => {
// //     setShowAddCardLayout((prev) => !prev);
// //   };
// //   const [cardId, setCardIs] = useState("");
// //   const check = [] as any;

// //   // Custom Methods
// //   const handleTabs = (event: React.SyntheticEvent, newValue: string) => {
// //     setValue(newValue);
// //   };
// //   const handleDrawer = (value: any) => {
// //     setOpenDrawer(value);
// //   };

// //   const handleNextTab = () => {
// //     // console.log('cardDate--->', cardData)
// //     // setTabs((tab) =>  tab + 1)
// //     handleSubmitData();
// //   };

// //   const handleBackTab = () => {
// //     setTabs((tab) => tab - 1);
// //   };

// //   const handleCountrycode = (value) => {
// //     setCountrycode(value);
// //   };
// //   const handleOpen = () => {
// //     return setAddNewCard(true);
// //   };

// //   const handleClose = () => {
// //     setShowAlert(false);
// //     setAddNewCard(false);
// //     setShowForm([]);
// //     setFilterForm([]);
// //     setCardData({
// //       ...cardData,
// //       layout: 1,
// //       color: 1,
// //       imgLayout: 1,
// //       imageUrl: "",
// //       // prifix: "",
// //       firstName: "",
// //       logoUrl: "",
// //       // middleName: "",
// //       lastName: "",
// //       // suffix: "",
// //       // accreditation: "",
// //       // preFerredName: "",
// //       // maidenName: "",
// //       pronouns: "",
// //       title: "",
// //       department: "",
// //       company: "",
// //       headline: "",
// //       // url: [],
// //       phoneNumber: "",
// //       emails: "",
// //       address: "",
// //       linkedin: "",
// //       instagram: "",
// //       twitter: "",
// //       website: "",
// //     });
// //     setTabs("1");
// //   };

// //   const handleCardLayout = (keyName: any, e: any) => {
// //     const value = e.target ? e.target.value : e;

// //     setCardData((prev) => {
// //       let updatedData = { ...prev, [keyName]: value };

// //       // Ensure firstName, lastName, and cardName exist in the state
// //       if (!updatedData.firstName) updatedData.firstName = "";
// //       if (!updatedData.lastName) updatedData.lastName = "";
// //       if (!updatedData.cardName) updatedData.cardName = "";

// //       // Clear imageUrl if virtualImage is being set
// //       if (keyName === "virtualImage") {
// //         updatedData.imageUrl = ""; // Clear imageUrl
// //       }

// //       // Split fullName into firstName and lastName
// //       if (keyName === "fullName") {
// //         const nameParts = value.trim().split(" ");
// //         updatedData.firstName = nameParts[0] || ""; // First part as firstName
// //         updatedData.lastName = nameParts.slice(1).join(" ") || ""; // Remaining parts as lastName
// //       }

// //       // Handle cardName separately
// //       if (keyName === "cardName") {
// //         updatedData.cardName = value || ""; // Set or clear the cardName
// //       }
// //       return updatedData;
// //     });
// //   };

// //   const handleCardDisplayLayout = (keyName: any, e: any) => {
// //     setDisplayTexts({
// //       ...displayTexts,
// //       [keyName]: e.target ? e.target.value : e,
// //     });
// //   };
// //   const handleProfileImage = (e: any) => {
// //     if (e.target.files[0]) {
// //       const imageUrl = URL.createObjectURL(e.target.files[0]);

// //       setCardData((prev) => {
// //         let updatedData = { ...prev, imageUrl };

// //         // Clear virtualImage if imageUrl is being set
// //         updatedData.virtualImage = ""; // Clear virtualImage
// //         return updatedData;
// //       });
// //     }
// //   };

// //   const handleLogo = (e: any) => {
// //     if (e.target.files[0]) {
// //       setCardData({
// //         ...cardData,
// //         logoUrl: URL.createObjectURL(e.target.files[0]),
// //       });
// //     }
// //   };

// //   const handleUrlData = (e: any) => {
// //     setIsFirstLoad(e);
// //     const data = {
// //       id: e.id,
// //       label: e.label,
// //       logoUrl: e.logo,
// //       url: "",
// //       displayText: "",
// //       verfiyUrl: false,
// //     };
// //     showForm.push(data);
// //     setShowFields(false);
// //   };

// //   // const handleUnSelect = (e: any) => {
// //   //   let test = filterData2.filter((itm: any) => e.label !== itm.label);
// //   //   setCardData({ ...cardData, url: test });
// //   // };

// //   const getCardData = async () => {
// //     try {
// //       const cookies = new Cookies();
// //       const token = cookies.get("idToken"); // Retrieve token from cookies
// //       console.log(`token`, token);
// //       // Fetch API URL from environment variables
// //       const apiUrl = process.env.REACT_APP_API_URL; // Replace with your variable name
// //       if (!apiUrl) {
// //         throw new Error(
// //           "API base URL is not defined in environment variables."
// //         );
// //       }

// //       // Make the API call using fetch
// //       const response = await fetch(`${apiUrl}/cards`, {
// //         method: "GET",
// //         headers: {
// //           "Content-Type": "application/json", // Ensure proper header
// //           Authorization: `${token}`, // Add token to the Authorization header
// //         },
// //       });
// //       console.log(response);

// //       if (!response.ok) {
// //         throw new Error(`API call failed with status: ${response.status}`);
// //       }

// //       const data = await response.json();
// //       if (!Array.isArray(data)) {
// //         throw new Error("Unexpected API response format. Expected an array.");
// //       }

// //       // Set the cards data
// //       setAllcards(data);
// //       console.log("All cards:", data);
// //     } catch (error) {
// //       console.error("Error fetching card data:", error.message);
// //     }
// //   };

// //   const handleDeleteCard = async (e: any) => {
// //     console.log("cardIdasdfasfd>", cardId);
// //     await service1
// //       .deleteCard(cardId)
// //       .then(() => {
// //         getCardData();
// //         handelConfirmModal(e, false, "");
// //       })
// //       .catch((err) => {
// //         console.log("err--->", err);
// //       });
// //   };

// //   const handelConfirmModal = async (e: any, value: boolean, id: string) => {
// //     // This ( e.stopPropagation()) will stop on child click it will trigger parent.
// //     e.stopPropagation();
// //     setConfirm(value);
// //     setCardIs(id);
// //   };

// //   const handleSubmitData = async () => {
// //     if (edit === true) {
// //       if (tabs === 0) {
// //         // if (cardData.imageUrl && cardData.logoUrl) {
// //         setTabs(1);
// //         setShowAlert(false);
// //         // } else {
// //         //   setShowAlert(true);
// //         // }
// //       } else if (tabs === 1) {
// //         console.log("tab1111--->", tab);
// //         if (
// //           cardData.company.length &&
// //           // cardData.department.length &&
// //           cardData.firstName.length &&
// //           // cardData.headline.length &&
// //           cardData.lastName.length &&
// //           // cardData.maidenName.length &&
// //           // cardData.middleName.length &&
// //           // cardData.preFerredName.length &&
// //           // cardData.prifix.length &&
// //           // cardData.pronouns.length &&
// //           cardData.phoneNumber.length &&
// //           validateEmail(cardData.emails) &&
// //           // cardData.suffix.length &&
// //           cardData.title.length
// //           // cardData.accreditation.length
// //         ) {
// //           console.log("tab3333--->", tab);
// //           setTabs(2);
// //           setShowAlert(false);
// //         } else {
// //           setShowAlert(true);
// //         }
// //       } else {
// //         if (tabs === 2) {
// //           const modify = {
// //             firstName: `${cardData.pronouns} ${cardData.firstName}`,
// //             lastName: cardData.lastName,
// //             emails: cardData.emails,
// //             phoneNumber: cardData.phoneNumber,
// //             address: cardData.address,
// //             title: cardData.title,
// //             company: cardData.company,
// //             imageUrl: cardData.imageUrl,
// //             logoUrl: cardData.logoUrl,
// //             youtube: `${cardData.youtube}`,
// //             linkedin: `${cardData.linkedin}`,
// //             instagram: `${cardData.instagram}`,
// //             twitter: `${cardData.twitter}`,
// //             role: `${cardData.role}`,
// //             facebook: `${cardData.facebook}`,
// //             cardName: `${cardData.cardName}`,
// //           };
// //           setEdit(false);
// //           setAddNewCard(false);
// //           if (modify) {
// //             await service1
// //               .updateCard(modify, cardData?.cardId)
// //               .then(() => {
// //                 getCardData();
// //                 // setCustomCard(customCard);
// //                 setShowAlert(false);
// //                 setAddNewCard(false);
// //                 setShowForm([]);
// //                 setFilterForm([]);
// //                 setCardData({
// //                   ...cardData,
// //                   layout: 1,
// //                   color: 1,
// //                   imgLayout: 1,
// //                   imageUrl: "",
// //                   // prifix: "",
// //                   firstName: "",
// //                   logoUrl: "",
// //                   // middleName: "",
// //                   lastName: "",
// //                   // suffix: "",
// //                   // accreditation: "",
// //                   // preFerredName: "",
// //                   // maidenName: "",
// //                   pronouns: "",
// //                   title: "",
// //                   department: "",
// //                   company: "",
// //                   headline: "",
// //                   // url: [],
// //                   phoneNumber: "",
// //                   emails: "",
// //                   address: "",
// //                   linkedin: "",
// //                   instagram: "",
// //                   twitter: "",
// //                   website: "",
// //                 });
// //                 setTabs("1");
// //               })
// //               .catch((err) => {
// //                 console.log("err---->", err);
// //               });
// //           }
// //         }
// //       }
// //     } else {
// //       if (tabs === 0) {
// //         // if (cardData.imageUrl && cardData.logoUrl) {
// //         setTabs(1);
// //         setShowAlert(false);
// //         // } else {
// //         //   setShowAlert(true);
// //         // }
// //       } else if (tabs === 1) {
// //         if (
// //           cardData.fullName.length &&
// //           validateEmail(cardData.emails) &&
// //           cardData.phoneNumber.length &&
// //           cardData.company.length &&
// //           cardData.role.length
// //         ) {
// //           console.log("cardData", cardData);
// //           setTabs(2);
// //           setShowAlert(false);
// //         } else {
// //           setShowAlert(true);
// //         }
// //       } else {
// //         if (tabs === 2) {
// //           const modify = {
// //             firstName: `${cardData.pronouns} ${cardData.firstName}`,
// //             lastName: cardData.lastName,
// //             emails: cardData.emails,
// //             phoneNumber: cardData.phoneNumber,
// //             address: cardData.address,
// //             title: cardData.title,
// //             company: cardData.company,
// //             imageUrl: cardData.imageUrl,
// //             logoUrl: cardData.logoUrl,
// //             youtube: `${cardData.youtube}`,
// //             linkedin: `${cardData.linkedin}`,
// //             instagram: `${cardData.instagram}`,
// //             twitter: `${cardData.twitter}`,
// //             role: `${cardData.role}`,
// //             facebook: `${cardData.facebook}`,
// //             cardName: `${cardData.cardName}`,
// //           };
// //           if (
// //             // cardData.imageUrl &&
// //             // cardData.logoUrl &&
// //             cardData.company.length &&
// //             // cardData.firstName.length &&
// //             // cardData.lastName.length &&
// //             cardData.fullName &&
// //             cardData.role &&
// //             cardData.phoneNumber.length &&
// //             validateEmail(cardData.emails) &&
// //             socialValidation("instagramExpand", "instagram") &&
// //             socialValidation("twitterExpand", "twitter") &&
// //             socialValidation("linkedinExpand", "linkedin") &&
// //             socialValidation("youtubeExpand", "youtube") &&
// //             socialValidation("facebookExpand", "facebook")
// //             // cardData.title.length
// //           ) {
// //             customCard.push(modify);
// //             const response = await service1.addCard(modify);
// //             if (response) {
// //               getCardData();
// //               setShowAlert(false);
// //               setAddNewCard(false);
// //               setShowForm([]);
// //               setFilterForm([]);
// //               setCardData({
// //                 ...cardData,
// //                 layout: 1,
// //                 color: 1,
// //                 imgLayout: 1,
// //                 imageUrl: "",
// //                 logoUrl: "",
// //                 firstName: "",
// //                 lastName: "",
// //                 pronouns: "",
// //                 title: "",
// //                 department: "",
// //                 company: "",
// //                 headline: "",
// //                 phoneNumber: "",
// //                 emails: "",
// //                 address: "",
// //                 linkedin: "",
// //                 instagram: "",
// //                 twitter: "",
// //                 website: "",
// //               });
// //               setTabs("1");
// //             }
// //           } else {
// //             console.log("showAlert--->asdf", showAlert);
// //             setShowAlert(true);
// //           }
// //         } else {
// //           setShowAlert(true);
// //         }
// //       }
// //     }
// //   };

// //   const handleEdit = async (e: any, item: any, index: any) => {
// //     console.log("item?.cardId--->", item?.cardId);
// //     e.stopPropagation();
// //     const cardResponse = await service1.getCardById(item?.cardId);
// //     console.log("cardResponse-->", cardResponse);

// //     setCardData({
// //       ...cardData,
// //       // this key added forfully to pass cardid in payload.
// //       cardId: cardResponse.cardId,
// //       layout: cardResponse.layout,
// //       color: cardResponse.color,
// //       imgLayout: cardResponse.imageUrl,
// //       imageUrl: cardResponse.imageUrl,
// //       logoUrl: cardResponse.logoUrl,
// //       // prifix: item.prifix,
// //       firstName: cardResponse.firstName,
// //       // middleName: item.middleName,
// //       lastName: cardResponse.lastName,
// //       suffix: item.suffix,
// //       accreditation: item.accreditation,
// //       preFerredName: item.preFerredName,
// //       maidenName: item.maidenName,
// //       pronouns: cardResponse.pronouns,
// //       title: cardResponse.title,
// //       department: cardResponse.department,
// //       company: cardResponse.company,
// //       headline: cardResponse.headline,
// //       // url: item.url,
// //       phoneNumber: cardResponse.phoneNumber,
// //       emails: cardResponse.emails,
// //       address: cardResponse.address,
// //       linkedin: cardResponse.links,
// //       // instagram: instaLink,
// //       // twitter: twitterLink,
// //       // website: websiteLink
// //     });
// //     setSelectCard(index);
// //     setFilterForm(item.url);
// //     setAddNewCard(true);
// //     setEdit(true);
// //   };

// //   const handleRemoveUrl = (it: any) => {
// //     setLoading(!loading);
// //     const remove = filterForm.filter((e: any) => e.label !== it.label);
// //     // setError(false)
// //     setSubAlert(false);
// //     setFilterForm(remove);
// //     // setUrls({...urls,url:'', displayText:''})
// //     setShowForm(remove);
// //     setIsFirstLoad(remove);
// //   };

// //   const handleSearch = (e: any) => {
// //     setSearchData(e.target.value);
// //     const filterdItem = allCards.filter(
// //       (user: any) =>
// //         // state takes time tu update, so search result will be delayed and to improve search functionality i used e.target.value in filter method to improve search performance.
// //         user?.firstName
// //           ?.toLowerCase()
// //           .includes(e.target.value?.toLowerCase()) ||
// //         user?.emails?.toLowerCase().includes(e.target.value?.toLowerCase())
// //     );
// //     sewtShowSearchData(filterdItem);
// //   };
// //   const handleLocalImage = (e: any) => {
// //     setCardData({ ...cardData, virtualImage: "" });
// //     // setLoading(!loading);
// //     // if(e.target.files[0]){
// //     //   console.log('item---->', e.target.files[0])
// //     //   let data = URL.createObjectURL(e.target.files[0]);
// //     //   // setSelectedImage(data)
// //     //   setCardData({...cardData, virtualImage: data})
// //     // }
// //   };
// //   const handleImage = (item: any) => {
// //     setLoading(!loading);
// //     // setSelectedImage(item);
// //     // let data = URL.createObjectURL(item);
// //     console.log("item---->", item);
// //     setCardData({ ...cardData, virtualImage: item });
// //   };
// //   const clearSearchInput = () => {
// //     setSearchData("");
// //   };
// //   const handleExpandSocialDetails = (keyName: any) => () => {
// //     setSocialDetails({ ...socialDetails, [keyName]: !socialDetails[keyName] });
// //   };
// //   const socialValidation = (expandKey: string, cardDataKey: string) => {
// //     if (socialDetails[expandKey]) {
// //       return cardData[cardDataKey].length && displayTexts[cardDataKey].length;
// //     } else {
// //       return true;
// //     }
// //   };
// //   // Life Cycle Metod

// //   useEffect(() => {
// //     getCardData();
// //     setLoading(!loading);
// //     if (showForm !== 0) {
// //       const filterData = showForm?.filter(
// //         (item: any, i: any) =>
// //           i === showForm?.findIndex((items: any) => items.label === item.label)
// //       );
// //       setFilterForm(filterData);
// //     }
// //   }, [isFirstLoad]);

// //   const toggleContactPage = () => setShowContactPage((prev) => !prev);

// //   // UI Implamentation
//   return (
//     <Box className="mainLoginBox" position={"relative"}>
//       {!maxWidth890px && (
//         <SideBar commonTexts={texts.commonText} openDrawer={openDrawer} />
//       )}
//       <Box className="layout" position={"relative"}>
//         <HeaderBar
//           openAddCards={handleOpen}
//           openDrawer={openDrawer}
//           handleDrawer={handleDrawer}
//           searchInput={searchData}
//           handleSearch={handleSearch}
//           clearSearchInput={clearSearchInput}
//           commonText={texts.commonText}
//         />
//         {maxWidth890px && !addNewCard && (
//           <div className="mobileSearchBox">
//             <div className="searchAndFilter">
//               {/* Search Field */}
//               <SearchField
//                 searchData={searchData}
//                 handleSearch={handleSearch}
//                 commonText={texts.commonText}
//                 clearSearchInput={clearSearchInput}
//               />

//               {/* Filter Button */}
//               <div className="filterButton">
//                 <Button>
//                   <img
//                     src={require("../../Assets/Images/Icons/filter.png")}
//                     alt="filter"
//                   />
//                 </Button>
//               </div>
//             </div>

//             {/* Cards and Contacts Buttons (Now Below the Search Bar and Filter) */}
//             <div className="toggleButtons">
//               <Button
//                 onClick={() => setShowContactPage(false)}
//                 variant="contained"
//                 className="cardsButton"
//                 disabled={!showContactPage}
//               >
//                 Cards
//               </Button>

//               <Button
//                 onClick={() => setShowContactPage(true)}
//                 variant="contained"
//                 className="contactsButton"
//                 disabled={showContactPage}
//               >
//                 Contacts
//               </Button>
//             </div>
//           </div>
//         )}

//         <div className="yourcards">
//           <h1>{showContactPage ? "Contacts" : "Cards"}</h1>
//         </div>

//         {showContactPage ? (
//           <ContactPage />
//         ) : (
//           <>
//             {!addNewCard && (
//               <CustomCards
//                 handleOpen={handleOpen}
//                 searchData={searchData}
//                 showSearchData={[]}
//                 cards={allCards}
//                 handleEdit={(e: any) => console.log("Edit logic here", e)}
//                 commonTexts={{}}
//                 handleDeleteCard={(e: any) =>
//                   console.log("Delete logic here", e)
//                 }
//               />
//             )}
//             {addNewCard && (
//               <AddCardLayout
//                 cardData={cardData}
//                 handleCardLayout={handleCardLayout}
//                 commonTexts={texts.commonText}
//                 handleProfileImage={handleProfileImage}
//                 handleLogo={handleLogo}
//                 handleLocalImage={handleLocalImage}
//                 showAlert={showAlert}
//                 validateEmail={validateEmail}
//                 tabs={tabs}
//                 handleNextTab={handleNextTab}
//                 handleBackTab={handleBackTab}
//                 displayTexts={displayTexts}
//                 handleCardDisplayLayout={handleCardDisplayLayout}
//                 countryCode={countryCode}
//                 handleCountrycode={handleCountrycode}
//                 handleImage={handleImage}
//                 socialDetails={socialDetails}
//                 handleExpandSocialDetails={handleExpandSocialDetails}
//               />
//             )}
//             {allCards.length > 0 && !addNewCard && (
//               <Box className="dashboardCardFooterbox">
//                 <Button className="dashboardCardEditBtn" onClick={handleOpen}>
//                   <AddCircleOutlineRoundedIcon />
//                 </Button>
//               </Box>
//             )}
//           </>
//         )}
//       </Box>
//       <ConfirmModal
//         open={confirm}
//         onClose={(e: any) => handelConfirmModal(e, false, "")}
//         confirmClick={(e: any) => handleDeleteCard(e)}
//         commonTexts={texts.commonText}
//       />
//     </Box>
//   );
// }
// const [showContactPage, setShowContactPage] = useState(false);

// const [addNewCard, setAddNewCard] = useState(false);
// const [customCard, setCustomCard] = useState<any>([]);
// const [countryCode, setCountrycode] = useState({
//   code: "IN",
//   label: "India",
//   phone: "+91",
// });
// // const [selectedImage, setSelectedImage] = useState(""
// //   // require("../../Assets/Images/image/demo.jpg")
// // );
// const maxWidth890px = useMediaQuery("(max-width:890px)");

// const [cardData, setCardData] = useState({
//   layout: 1,
//   color: 1,
//   imgLayout: 1,
//   imageUrl: "",
//   logoLayout: 1,
//   logoUrl: "",
//   firstName: "",
//   lastName: "",
//   pronouns: "",
//   title: "",
//   department: "",
//   company: "",
//   headline: "",
//   phoneNumber: "",
//   emails: "",
//   address: "",
//   linkedin: "",
//   instagram: "",
//   twitter: "",
//   website: "",
//   // new Ui keys
//   fullName: "",
//   role: "",
//   youtube: "",
//   facebook: "",
//   virtualImage: "",
//   cardName: "",
//   personalImage: "",
//   logo: "",
// });
// const [displayTexts, setDisplayTexts] = useState({
//   instagram: "",
//   youtube: "",
//   facebook: "",
//   twitter: "",
//   linkedin: "",
// });
// const [showFields, setShowFields] = useState<any>(false);
// const [tabs, setTabs] = React.useState(0);
// const [edit, setEdit] = useState(false);
// const [selelctCard, setSelectCard] = useState<any>();
// // const [urls, setUrls] = useState({
// //   url: "",
// //   displayText: "",
// // })
// const [allCards, setAllcards] = useState<any>([]);
// const [isFirstLoad, setIsFirstLoad] = useState("");
// const [showForm, setShowForm] = useState<any>([]);
// const [loading, setLoading] = useState<any>(false);
// const [filterForm, setFilterForm] = useState<any>([]);
// const [showAlert, setShowAlert] = useState(false);
// const [searchData, setSearchData] = useState("");
// const [showSearchData, sewtShowSearchData] = useState([]);
// const [subAlert, setSubAlert] = useState(false);
// const [error, setError] = useState(false);
// const [value, setValue] = React.useState("1");
// const [confirm, setConfirm] = useState(false);
// const [openDrawer, setOpenDrawer] = useState(false);
// const [showAddCardLayout, setShowAddCardLayout] = useState(false);
// const [socialDetails, setSocialDetails] = useState({
//   instagramExpand: false,
//   youtubeExpand: false,
//   facebookExpand: false,
//   twitterExpand: false,
//   linkedinExpand: false,
// });
// const toggleAddCardLayout = () => {
//   setShowAddCardLayout((prev) => !prev);
// };
// const [cardId, setCardIs] = useState("");
// const check = [] as any;

// // Custom Methods
// const handleTabs = (event: React.SyntheticEvent, newValue: string) => {
//   setValue(newValue);
// };
// const handleDrawer = (value: any) => {
//   setOpenDrawer(value);
// };

// const handleNextTab = () => {
//   // console.log('cardDate--->', cardData)
//   // setTabs((tab) =>  tab + 1)
//   handleSubmitData();
// };

// const handleBackTab = () => {
//   setTabs((tab) => tab - 1);
// };

// const handleCountrycode = (value) => {
//   setCountrycode(value);
// };
// const handleOpen = () => {
//   return setAddNewCard(true);
// };

// const handleClose = () => {
//   setShowAlert(false);
//   setAddNewCard(false);
//   setShowForm([]);
//   setFilterForm([]);
//   setCardData({
//     ...cardData,
//     layout: 1,
//     color: 1,
//     imgLayout: 1,
//     imageUrl: "",
//     // prifix: "",
//     firstName: "",
//     logoUrl: "",
//     // middleName: "",
//     lastName: "",
//     // suffix: "",
//     // accreditation: "",
//     // preFerredName: "",
//     // maidenName: "",
//     pronouns: "",
//     title: "",
//     department: "",
//     company: "",
//     headline: "",
//     // url: [],
//     phoneNumber: "",
//     emails: "",
//     address: "",
//     linkedin: "",
//     instagram: "",
//     twitter: "",
//     website: "",
//     personalImage: "",
//     logo: "",
//   });
//   setTabs("1");
// };

// const handleCardLayout = (keyName: any, e: any) => {
//   const value = e.target ? e.target.value : e;

//   setCardData((prev) => {
//     let updatedData = { ...prev, [keyName]: value };

//     // Ensure firstName, lastName, and cardName exist in the state
//     if (!updatedData.firstName) updatedData.firstName = "";
//     if (!updatedData.lastName) updatedData.lastName = "";
//     if (!updatedData.cardName) updatedData.cardName = "";

//     // Clear imageUrl if virtualImage is being set
//     if (keyName === "virtualImage") {
//       updatedData.imageUrl = ""; // Clear imageUrl
//     }

//     // Split fullName into firstName and lastName
//     if (keyName === "fullName") {
//       const nameParts = value.trim().split(" ");
//       updatedData.firstName = nameParts[0] || ""; // First part as firstName
//       updatedData.lastName = nameParts.slice(1).join(" ") || ""; // Remaining parts as lastName
//     }

//     // Handle cardName separately
//     if (keyName === "cardName") {
//       updatedData.cardName = value || ""; // Set or clear the cardName
//     }
//     return updatedData;
//   });
// };

// const handleCardDisplayLayout = (keyName: any, e: any) => {
//   setDisplayTexts({
//     ...displayTexts,
//     [keyName]: e.target ? e.target.value : e,
//   });
// };

// const handleProfileImage = async (e: any) => {
//   if (e.target.files[0]) {
//     const file = e.target.files[0];

//     try {
//       // Convert file to Base64
//       const base64Image = await fileToBase64(file);
//       const imageUrl = URL.createObjectURL(file);

//       setCardData((prev) => {
//         let updatedData = {
//           ...prev,
//           base64Image, // Store Base64 string
//           personalImage: base64Image, // Add Base64 key for API
//           imageUrl, // Store image URL
//         };

//         // Clear virtualImage if base64Image is being set
//         updatedData.virtualImage = "";
//         return updatedData;
//       });
//     } catch (error) {
//       console.error("Error converting file to Base64:", error);
//     }
//   }
// };

// const handleLogo = async (e: any) => {
//   if (e.target.files[0]) {
//     const file = e.target.files[0];

//     try {
//       // Convert file to Base64
//       const base64Logo = await fileToBase64(file);
//       const logoUrl = URL.createObjectURL(file);

//       setCardData((prev) => ({
//         ...prev,
//         base64Logo, // Store Base64 string
//         logo: base64Logo, // Add Base64 key for API
//         logoUrl, // Store logo URL
//       }));
//     } catch (error) {
//       console.error("Error converting file to Base64:", error);
//     }
//   }
// };

// // Helper function to convert file to Base64
// function fileToBase64(file: File): Promise<string> {
//   return new Promise((resolve, reject) => {
//     const reader = new FileReader();
//     reader.onload = () => resolve((reader.result as string).split(",")[1]); // Extract Base64 data
//     reader.onerror = (error) => reject(error);
//     reader.readAsDataURL(file);
//   });
// }

// // const handleProfileImage = (e: any) => {
// //   if (e.target.files[0]) {
// //     const imageUrl = URL.createObjectURL(e.target.files[0]);

// //     setCardData((prev) => {
// //       let updatedData = { ...prev, imageUrl };

// //       // Clear virtualImage if imageUrl is being set
// //       updatedData.virtualImage = ""; // Clear virtualImage
// //       return updatedData;
// //     });
// //   }
// // };

// // const handleLogo = (e: any) => {
// //   if (e.target.files[0]) {
// //     setCardData({
// //       ...cardData,
// //       logoUrl: URL.createObjectURL(e.target.files[0]),
// //     });
// //   }
// // };

// // const handleFieldInput = (keyName: any, e: any, i: any) => {
// //   setUrls({ ...urls, [keyName]: e.target.value })
// //   if(keyName === 'url'){
// //     let url =  e.target.value
// //     filterForm[i].url = url
// //   } else if( keyName === 'displayText'){
// //     let displayText = e.target.value
// //     filterForm[i].displayText = displayText
// //   }
// // };

// // const filterData2 = cardData.url.filter(
// //   (item: any, i: any) =>
// //     i === cardData.url.findIndex((items: any) => items.label === item.label)
// // );

// const handleUrlData = (e: any) => {
//   setIsFirstLoad(e);
//   const data = {
//     id: e.id,
//     label: e.label,
//     logoUrl: e.logo,
//     url: "",
//     displayText: "",
//     verfiyUrl: false,
//   };
//   showForm.push(data);
//   setShowFields(false);
// };

// // const handleUnSelect = (e: any) => {
// //   let test = filterData2.filter((itm: any) => e.label !== itm.label);
// //   setCardData({ ...cardData, url: test });
// // };

// const getCardData = async () => {
//   try {
//     const cookies = new Cookies();
//     const token = cookies.get("idToken"); // Retrieve token from cookies
//     console.log(`token`, token);
//     // Fetch API URL from environment variables
//     const apiUrl = process.env.REACT_APP_API_URL; // Replace with your variable name
//     if (!apiUrl) {
//       throw new Error("API base URL is not defined in environment variables.");
//     }

//     // Make the API call using fetch
//     const response = await fetch(`${apiUrl}/cards`, {
//       method: "GET",
//       headers: {
//         "Content-Type": "application/json", // Ensure proper header
//         Authorization: `${token}`, // Add token to the Authorization header
//       },
//     });
//     console.log(response);

//     if (!response.ok) {
//       throw new Error(`API call failed with status: ${response.status}`);
//     }

//     const data = await response.json();
//     if (!Array.isArray(data)) {
//       throw new Error("Unexpected API response format. Expected an array.");
//     }

//     // Set the cards data
//     setAllcards(data);
//     console.log("All cards:", data);
//   } catch (error) {
//     console.error("Error fetching card data:", error.message);
//   }
// };

// const handleDeleteCard = async (e: any) => {
//   console.log("cardIdasdfasfd>", cardId);
//   await service1
//     .deleteCard(cardId)
//     .then(() => {
//       getCardData();
//       handelConfirmModal(e, false, "");
//     })
//     .catch((err) => {
//       console.log("err--->", err);
//     });
// };

// const handelConfirmModal = async (e: any, value: boolean, id: string) => {
//   // This ( e.stopPropagation()) will stop on child click it will trigger parent.
//   e.stopPropagation();
//   setConfirm(value);
//   setCardIs(id);
// };

// // useEffect(() => {
// //   setLoading(!loading)
// //   for (let index = 0; index < filterForm?.length; index++) {
// //     const element = filterForm[index].label === 'Phone' ? OnlyNumbers(filterForm[index].url) : filterForm[index].label === 'Email' ? validateEmail(filterForm[index].url) :validateUrl(filterForm[index].url)
// //     filterForm[index].verfiyUrl = element
// //     // if (validateUrl(filterForm[index].url) && validateUrl(urls.url)){
// //     //   setError(false);
// //     // } else {setError(true)};
// //   }
// // },[urls]);

// // const handleAddBtn = () => {
// //   setLoading(!loading);
// //   // console.log("asdf====",);
// //   for (let index = 0; index < filterForm.length; index++) {
// //     if(filterForm[index].url.length > 0 && filterForm[index].displayText.length > 0){
// //       check.push(true)
// //     } else {setError(true)}
// //   }
// //   console.log("asdf====",check.length , filterForm.length);
// //   if(check.length === filterForm.length){
// //     cardData?.url.push(...filterForm);
// //     setFilterForm([]);
// //     setShowForm([]);
// //     setUrls({...urls,url:'', displayText:''})
// //     setSubAlert(false)
// //   } else {
// //     setSubAlert(true)
// //   }
// // }

// const handleSubmitData = async () => {
//   if (edit === true) {
//     if (tabs === 0) {
//       // if (cardData.imageUrl && cardData.logoUrl) {
//       setTabs(1);
//       setShowAlert(false);
//       // } else {
//       //   setShowAlert(true);
//       // }
//     } else if (tabs === 1) {
//       console.log("tab1111--->", tab);
//       if (
//         cardData.company.length &&
//         // cardData.department.length &&
//         cardData.firstName.length &&
//         // cardData.headline.length &&
//         cardData.lastName.length &&
//         // cardData.maidenName.length &&
//         // cardData.middleName.length &&
//         // cardData.preFerredName.length &&
//         // cardData.prifix.length &&
//         // cardData.pronouns.length &&
//         cardData.phoneNumber.length &&
//         validateEmail(cardData.emails) &&
//         // cardData.suffix.length &&
//         cardData.title.length
//         // cardData.accreditation.length
//       ) {
//         console.log("tab3333--->", tab);
//         setTabs(2);
//         setShowAlert(false);
//       } else {
//         setShowAlert(true);
//       }
//     } else {
//       if (tabs === 2) {
//         const modify = {
//           firstName: `${cardData.pronouns} ${cardData.firstName}`,
//           lastName: cardData.lastName,
//           emails: cardData.emails,
//           phoneNumber: cardData.phoneNumber,
//           address: cardData.address,
//           title: cardData.title,
//           company: cardData.company,
//           imageUrl: cardData.imageUrl,
//           logoUrl: cardData.logoUrl,
//           logo: cardData.logo,
//           personalImage: cardData.personalImage,
//           youtube: `${cardData.youtube}`,
//           linkedin: `${cardData.linkedin}`,
//           instagram: `${cardData.instagram}`,
//           twitter: `${cardData.twitter}`,
//           role: `${cardData.role}`,
//           facebook: `${cardData.facebook}`,
//           cardName: `${cardData.cardName}`,
//         };
//         setEdit(false);
//         setAddNewCard(false);
//         if (modify) {
//           await service1
//             .updateCard(modify, cardData?.cardId)
//             .then(() => {
//               getCardData();
//               // setCustomCard(customCard);
//               setShowAlert(false);
//               setAddNewCard(false);
//               setShowForm([]);
//               setFilterForm([]);
//               setCardData({
//                 ...cardData,
//                 layout: 1,
//                 color: 1,
//                 imgLayout: 1,
//                 imageUrl: "",
//                 // prifix: "",
//                 firstName: "",
//                 logoUrl: "",
//                 // middleName: "",
//                 lastName: "",
//                 // suffix: "",
//                 // accreditation: "",
//                 // preFerredName: "",
//                 // maidenName: "",
//                 pronouns: "",
//                 title: "",
//                 department: "",
//                 company: "",
//                 headline: "",
//                 // url: [],
//                 phoneNumber: "",
//                 emails: "",
//                 address: "",
//                 linkedin: "",
//                 instagram: "",
//                 twitter: "",
//                 website: "",
//                 personalImage: "",
//                 logo: "",
//               });
//               setTabs("1");
//             })
//             .catch((err) => {
//               console.log("err---->", err);
//             });
//         }
//       }
//     }
//   } else {
//     if (tabs === 0) {
//       // if (cardData.imageUrl && cardData.logoUrl) {
//       setTabs(1);
//       setShowAlert(false);
//       // } else {
//       //   setShowAlert(true);
//       // }
//     } else if (tabs === 1) {
//       if (
//         // cardData.company.length &&
//         // cardData.department.length &&
//         // cardData.firstName.length &&
//         // cardData.headline.length &&
//         // cardData.lastName.length &&
//         // cardData.phoneNumber.length &&
//         // validateEmail(cardData.emails) &&
//         // cardData.maidenName.length &&
//         // cardData.middleName.length &&
//         // cardData.preFerredName.length &&
//         // cardData.prifix.length &&
//         // cardData.pronouns.length &&
//         // cardData.suffix.length &&
//         // cardData.title.length
//         // cardData.accreditation.length
//         // New Ui Validation
//         cardData.fullName.length &&
//         validateEmail(cardData.emails) &&
//         cardData.phoneNumber.length &&
//         cardData.company.length &&
//         cardData.role.length
//       ) {
//         console.log("cardData", cardData);
//         setTabs(2);
//         setShowAlert(false);
//       } else {
//         setShowAlert(true);
//       }
//     } else {
//       if (tabs === 2) {
//         const modify = {
//           firstName: `${cardData.pronouns} ${cardData.firstName}`,
//           lastName: cardData.lastName,
//           emails: cardData.emails,
//           phoneNumber: cardData.phoneNumber,
//           address: cardData.address,
//           title: cardData.title,
//           company: cardData.company,
//           imageUrl: cardData.imageUrl,
//           logoUrl: cardData.logoUrl,
//           youtube: `${cardData.youtube}`,
//           linkedin: `${cardData.linkedin}`,
//           instagram: `${cardData.instagram}`,
//           twitter: `${cardData.twitter}`,
//           role: `${cardData.role}`,
//           facebook: `${cardData.facebook}`,
//           cardName: `${cardData.cardName}`,
//           personalImage: `${cardData.personalImage}`,
//           logo: `${cardData.logo}`,
//           // links: `${cardData.linkedin}, ${cardData.instagram}, ${cardData.twitter}, ${cardData.website}`,
//           // layout: cardData.layout,
//           // color: cardData.color,
//           // pronouns: cardData.pronouns,
//           // department: cardData.department,
//           // headline: cardData.headline,
//           // imgLayout: cardData.imgLayout,
//           // id:customCard.length ? customCard?.length : 0,
//           // prifix: cardData.prifix,
//           // middleName: cardData.middleName,
//           // suffix: cardData.suffix,
//           // accreditation: cardData.accreditation,
//           // preFerredName: cardData.preFerredName,
//           // maidenName: cardData.maidenName,
//           // url: cardData.url,
//           // linkedin: cardData.linkedin,
//           // instagram: cardData.instagram,
//           // twitter: cardData.twitter,
//           // website: cardData.website
//         };
//         if (
//           // cardData.imageUrl &&
//           // cardData.logoUrl &&
//           cardData.company.length &&
//           // cardData.firstName.length &&
//           // cardData.lastName.length &&
//           cardData.fullName &&
//           cardData.role &&
//           cardData.phoneNumber.length &&
//           validateEmail(cardData.emails) &&
//           socialValidation("instagramExpand", "instagram") &&
//           socialValidation("twitterExpand", "twitter") &&
//           socialValidation("linkedinExpand", "linkedin") &&
//           socialValidation("youtubeExpand", "youtube") &&
//           socialValidation("facebookExpand", "facebook")
//           // cardData.title.length
//         ) {
//           customCard.push(modify);
//           const response = await service1.addCard(modify);
//           if (response) {
//             getCardData();
//             setShowAlert(false);
//             setAddNewCard(false);
//             setShowForm([]);
//             setFilterForm([]);
//             setCardData({
//               ...cardData,
//               layout: 1,
//               color: 1,
//               imgLayout: 1,
//               imageUrl: "",
//               logoUrl: "",
//               firstName: "",
//               lastName: "",
//               pronouns: "",
//               title: "",
//               department: "",
//               company: "",
//               headline: "",
//               phoneNumber: "",
//               emails: "",
//               address: "",
//               linkedin: "",
//               instagram: "",
//               twitter: "",
//               website: "",
//               personalImage: "",
//               logo: "",
//             });
//             setTabs("1");
//           }
//         } else {
//           console.log("showAlert--->asdf", showAlert);
//           setShowAlert(true);
//         }
//       } else {
//         setShowAlert(true);
//       }
//     }
//   }
// };

// const handleEdit = async (e: any, item: any, index: any) => {
//   console.log("item?.cardId--->", item?.cardId);
//   e.stopPropagation();
//   const cardResponse = await service1.getCardById(item?.cardId);
//   //   console.log('cardResponse-->', cardResponse)
//   //   const divideLinks = cardResponse?.links?.split(",")
//   //   let instaLink = ''
//   //   let twitterLink = ''
//   //   let linkedInLink = ''
//   //   let websiteLink = ''
//   //   divideLinks.forEach((link: any) => {
//   //   console.log('link-->', link, link.includes('instagram'))
//   //     if (link.includes('instagram')) {
//   //       instaLink = link
//   //     } else if (link.includes('linkedin')) {
//   //       twitterLink = link
//   //     } else  if (link.includes('twitter')){
//   //       linkedInLink = link
//   //     } else {
//   //       websiteLink = link
//   //     }
//   // })
//   setCardData({
//     ...cardData,
//     // this key added forfully to pass cardid in payload.
//     cardId: cardResponse.cardId,
//     layout: cardResponse.layout,
//     color: cardResponse.color,
//     imgLayout: cardResponse.imageUrl,
//     imageUrl: cardResponse.imageUrl,
//     logoUrl: cardResponse.logoUrl,
//     // prifix: item.prifix,
//     firstName: cardResponse.firstName,
//     // middleName: item.middleName,
//     lastName: cardResponse.lastName,
//     // suffix: item.suffix,
//     // accreditation: item.accreditation,
//     // preFerredName: item.preFerredName,
//     // maidenName: item.maidenName,
//     pronouns: cardResponse.pronouns,
//     title: cardResponse.title,
//     department: cardResponse.department,
//     company: cardResponse.company,
//     headline: cardResponse.headline,
//     // url: item.url,
//     phoneNumber: cardResponse.phoneNumber,
//     emails: cardResponse.emails,
//     address: cardResponse.address,
//     linkedin: cardResponse.links,
//     // instagram: instaLink,
//     // twitter: twitterLink,
//     // website: websiteLink
//   });
//   setSelectCard(index);
//   setFilterForm(item.url);
//   setAddNewCard(true);
//   setEdit(true);
// };

// const handleRemoveUrl = (it: any) => {
//   setLoading(!loading);
//   const remove = filterForm.filter((e: any) => e.label !== it.label);
//   // setError(false)
//   setSubAlert(false);
//   setFilterForm(remove);
//   // setUrls({...urls,url:'', displayText:''})
//   setShowForm(remove);
//   setIsFirstLoad(remove);
// };

// const handleSearch = (e: any) => {
//   setSearchData(e.target.value);
//   const filterdItem = allCards.filter(
//     (user: any) =>
//       // state takes time tu update, so search result will be delayed and to improve search functionality i used e.target.value in filter method to improve search performance.
//       user?.firstName?.toLowerCase().includes(e.target.value?.toLowerCase()) ||
//       user?.emails?.toLowerCase().includes(e.target.value?.toLowerCase())
//   );
//   sewtShowSearchData(filterdItem);
// };
// const handleLocalImage = (e: any) => {
//   setCardData({ ...cardData, virtualImage: "" });
//   // setLoading(!loading);
//   // if(e.target.files[0]){
//   //   console.log('item---->', e.target.files[0])
//   //   let data = URL.createObjectURL(e.target.files[0]);
//   //   // setSelectedImage(data)
//   //   setCardData({...cardData, virtualImage: data})
//   // }
// };
// const handleImage = (item: any) => {
//   setLoading(!loading);
//   // setSelectedImage(item);
//   // let data = URL.createObjectURL(item);
//   console.log("item---->", item);
//   setCardData({ ...cardData, virtualImage: item });
// };
// const clearSearchInput = () => {
//   setSearchData("");
// };
// const handleExpandSocialDetails = (keyName: any) => () => {
//   setSocialDetails({ ...socialDetails, [keyName]: !socialDetails[keyName] });
// };
// const socialValidation = (expandKey: string, cardDataKey: string) => {
//   if (socialDetails[expandKey]) {
//     return cardData[cardDataKey].length && displayTexts[cardDataKey].length;
//   } else {
//     return true;
//   }
// };
// // Life Cycle Metod

// useEffect(() => {
//   getCardData();
//   setLoading(!loading);
//   if (showForm !== 0) {
//     const filterData = showForm?.filter(
//       (item: any, i: any) =>
//         i === showForm?.findIndex((items: any) => items.label === item.label)
//     );
//     setFilterForm(filterData);
//   }
// }, [isFirstLoad]);

// const toggleContactPage = () => setShowContactPage((prev) => !prev);

// import React, { useEffect, useState } from "react";
// import {
//   Avatar,
//   Box,
//   Button,
//   Divider,
//   Drawer,
//   List,
//   ListItem,
//   TextField,
//   Typography,
//   useMediaQuery,
//   InputAdornment,
// } from "@mui/material";

// import "./dashBoardPage.css";
// import texts from "../../Assets/CommonTexts/texts.json";
// // import DetailsBox from "../../Components/dashboardComponent/detailsBox.tsx";
// import SideBar from "../../Components/commonComponents/sideBar/sideBar.tsx";
// // import AddNewCard from "../../Components/dashboardComponent/addNewCardComponents/addNewCard.tsx";
// import {
//   OnlyNumbers,
//   validateEmail,
//   validateUrl,
// } from "../../utils/commonRegex.ts";
// import AddNewCard from "../../Components/dashboardComponent/addNewCard/addNewCard.tsx";
// import AddSearchBar from "../../Components/commonComponents/addSearchBar/addSearchBar.tsx";
// import CustomCards from "../../Components/dashboardComponent/customCard/customCards.tsx";
// import VirtualBackGrounds from "../../Components/dashboardComponent/virtualBackGrounds/virtualBackGrounds.tsx";
// import Tab from "@mui/material/Tab";
// import TabContext from "@mui/lab/TabContext";
// import TabList from "@mui/lab/TabList";
// import TabPanel from "@mui/lab/TabPanel";
// import { tab } from "@testing-library/user-event/dist/tab";
// import service1 from "../../services/service1.ts";
// import ConfirmModal from "../../Components/commonComponents/modals/confirmModal/confirmModal.tsx";
// import HeaderBar from "../../Components/commonComponents/headerBar/headerBar.tsx";
// import AddCardLayout from "../../Components/dashboardComponent/addCardLayout/addCardLayout.tsx";
// import CloseIcon from "@mui/icons-material/Close";
// import { commonColors } from "../../Assets/colors.ts";
// import SearchField from "../../Components/commonComponents/searchField/searchField.tsx";
// import Cookies from "universal-cookie";

// export default function DashBoardPage() {
//   // Custom States

//   const [addNewCard, setAddNewCard] = useState(false);
//   const [customCard, setCustomCard] = useState<any>([]);
//   const [countryCode, setCountrycode] = useState({
//     code: "IN",
//     label: "India",
//     phone: "+91",
//   });
//   // const [selectedImage, setSelectedImage] = useState(""
//   //   // require("../../Assets/Images/image/demo.jpg")
//   // );
//   const maxWidth890px = useMediaQuery("(max-width:890px)");

//   const [cardData, setCardData] = useState({
//     layout: 1,
//     color: 1,
//     imgLayout: 1,
//     imageUrl: "",
//     logoLayout: 1,
//     logoUrl: "",
//     firstName: "",
//     lastName: "",
//     pronouns: "",
//     title: "",
//     department: "",
//     company: "",
//     headline: "",
//     phoneNumber: "",
//     emails: "",
//     address: "",
//     linkedin: "",
//     instagram: "",
//     twitter: "",
//     website: "",
//     // new Ui keys
//     fullName: "",
//     role: "",
//     youtube: "",
//     facebook: "",
//     virtualImage: "",
//     cardName: "",
//   });
//   const [displayTexts, setDisplayTexts] = useState({
//     instagram: "",
//     youtube: "",
//     facebook: "",
//     twitter: "",
//     linkedin: "",
//   });
//   const [showFields, setShowFields] = useState<any>(false);
//   const [tabs, setTabs] = React.useState(0);
//   const [edit, setEdit] = useState(false);
//   const [selelctCard, setSelectCard] = useState<any>();
//   // const [urls, setUrls] = useState({
//   //   url: "",
//   //   displayText: "",
//   // })
//   const [allCards, setAllcards] = useState<any>([]);
//   const [isFirstLoad, setIsFirstLoad] = useState("");
//   const [showForm, setShowForm] = useState<any>([]);
//   const [loading, setLoading] = useState<any>(false);
//   const [filterForm, setFilterForm] = useState<any>([]);
//   const [showAlert, setShowAlert] = useState(false);
//   const [searchData, setSearchData] = useState("");
//   const [showSearchData, sewtShowSearchData] = useState([]);
//   const [subAlert, setSubAlert] = useState(false);
//   const [error, setError] = useState(false);
//   const [value, setValue] = React.useState("1");
//   const [confirm, setConfirm] = useState(false);
//   const [openDrawer, setOpenDrawer] = useState(false);
//   const [showAddCardLayout, setShowAddCardLayout] = useState(false);
//   const [socialDetails, setSocialDetails] = useState({
//     instagramExpand: false,
//     youtubeExpand: false,
//     facebookExpand: false,
//     twitterExpand: false,
//     linkedinExpand: false,
//   });
//   const toggleAddCardLayout = () => {
//     setShowAddCardLayout((prev) => !prev);
//   };
//   const [cardId, setCardIs] = useState("");
//   const check = [] as any;

//   // Custom Methods
//   const handleTabs = (event: React.SyntheticEvent, newValue: string) => {
//     setValue(newValue);
//   };
//   const handleDrawer = (value: any) => {
//     setOpenDrawer(value);
//   };

//   const handleNextTab = () => {
//     // console.log('cardDate--->', cardData)
//     // setTabs((tab) =>  tab + 1)
//     handleSubmitData();
//   };

//   const handleBackTab = () => {
//     setTabs((tab) => tab - 1);
//   };

//   const handleCountrycode = (value) => {
//     setCountrycode(value);
//   };
//   const handleOpen = () => {
//     return setAddNewCard(true);
//   };

//   const handleClose = () => {
//     setShowAlert(false);
//     setAddNewCard(false);
//     setShowForm([]);
//     setFilterForm([]);
//     setCardData({
//       ...cardData,
//       layout: 1,
//       color: 1,
//       imgLayout: 1,
//       imageUrl: "",
//       // prifix: "",
//       firstName: "",
//       logoUrl: "",
//       // middleName: "",
//       lastName: "",
//       // suffix: "",
//       // accreditation: "",
//       // preFerredName: "",
//       // maidenName: "",
//       pronouns: "",
//       title: "",
//       department: "",
//       company: "",
//       headline: "",
//       // url: [],
//       phoneNumber: "",
//       emails: "",
//       address: "",
//       linkedin: "",
//       instagram: "",
//       twitter: "",
//       website: "",
//     });
//     setTabs("1");
//   };

//   const handleCardLayout = (keyName: any, e: any) => {
//     const value = e.target ? e.target.value : e;

//     setCardData((prev) => {
//       let updatedData = { ...prev, [keyName]: value };

//       // Ensure firstName, lastName, and cardName exist in the state
//       if (!updatedData.firstName) updatedData.firstName = "";
//       if (!updatedData.lastName) updatedData.lastName = "";
//       if (!updatedData.cardName) updatedData.cardName = "";

//       // Clear imageUrl if virtualImage is being set
//       if (keyName === "virtualImage") {
//         updatedData.imageUrl = ""; // Clear imageUrl
//       }

//       // Split fullName into firstName and lastName
//       if (keyName === "fullName") {
//         const nameParts = value.trim().split(" ");
//         updatedData.firstName = nameParts[0] || ""; // First part as firstName
//         updatedData.lastName = nameParts.slice(1).join(" ") || ""; // Remaining parts as lastName
//       }

//       // Handle cardName separately
//       if (keyName === "cardName") {
//         updatedData.cardName = value || ""; // Set or clear the cardName
//       }
//       return updatedData;
//     });
//   };

//   const handleCardDisplayLayout = (keyName: any, e: any) => {
//     setDisplayTexts({
//       ...displayTexts,
//       [keyName]: e.target ? e.target.value : e,
//     });
//   };
//   const handleProfileImage = (e: any) => {
//     if (e.target.files[0]) {
//       const imageUrl = URL.createObjectURL(e.target.files[0]);

//       setCardData((prev) => {
//         let updatedData = { ...prev, imageUrl };

//         // Clear virtualImage if imageUrl is being set
//         updatedData.virtualImage = ""; // Clear virtualImage
//         return updatedData;
//       });
//     }
//   };

//   const handleLogo = (e: any) => {
//     if (e.target.files[0]) {
//       setCardData({
//         ...cardData,
//         logoUrl: URL.createObjectURL(e.target.files[0]),
//       });
//     }
//   };

//   // const handleFieldInput = (keyName: any, e: any, i: any) => {
//   //   setUrls({ ...urls, [keyName]: e.target.value })
//   //   if(keyName === 'url'){
//   //     let url =  e.target.value
//   //     filterForm[i].url = url
//   //   } else if( keyName === 'displayText'){
//   //     let displayText = e.target.value
//   //     filterForm[i].displayText = displayText
//   //   }
//   // };

//   // const filterData2 = cardData.url.filter(
//   //   (item: any, i: any) =>
//   //     i === cardData.url.findIndex((items: any) => items.label === item.label)
//   // );

//   const handleUrlData = (e: any) => {
//     setIsFirstLoad(e);
//     const data = {
//       id: e.id,
//       label: e.label,
//       logoUrl: e.logo,
//       url: "",
//       displayText: "",
//       verfiyUrl: false,
//     };
//     showForm.push(data);
//     setShowFields(false);
//   };

//   // const handleUnSelect = (e: any) => {
//   //   let test = filterData2.filter((itm: any) => e.label !== itm.label);
//   //   setCardData({ ...cardData, url: test });
//   // };

//   const getCardData = async () => {
//     try {
//       const cookies = new Cookies();
//       const token = cookies.get("idToken"); // Retrieve token from cookies
//       console.log(`token`, token);
//       // Fetch API URL from environment variables
//       const apiUrl = process.env.REACT_APP_API_URL; // Replace with your variable name
//       if (!apiUrl) {
//         throw new Error(
//           "API base URL is not defined in environment variables."
//         );
//       }

//       // Make the API call using fetch
//       const response = await fetch(`${apiUrl}/cards`, {
//         method: "GET",
//         headers: {
//           "Content-Type": "application/json", // Ensure proper header
//           Authorization: `${token}`, // Add token to the Authorization header
//         },
//       });
//       console.log(response);

//       if (!response.ok) {
//         throw new Error(`API call failed with status: ${response.status}`);
//       }

//       const data = await response.json();
//       if (!Array.isArray(data)) {
//         throw new Error("Unexpected API response format. Expected an array.");
//       }

//       // Set the cards data
//       setAllcards(data);
//       console.log("All cards:", data);
//     } catch (error) {
//       console.error("Error fetching card data:", error.message);
//     }
//   };

//   const handleDeleteCard = async (e: any) => {
//     console.log("cardIdasdfasfd>", cardId);
//     await service1
//       .deleteCard(cardId)
//       .then(() => {
//         getCardData();
//         handelConfirmModal(e, false, "");
//       })
//       .catch((err) => {
//         console.log("err--->", err);
//       });
//   };

//   const handelConfirmModal = async (e: any, value: boolean, id: string) => {
//     // This ( e.stopPropagation()) will stop on child click it will trigger parent.
//     e.stopPropagation();
//     setConfirm(value);
//     setCardIs(id);
//   };

//   // useEffect(() => {
//   //   setLoading(!loading)
//   //   for (let index = 0; index < filterForm?.length; index++) {
//   //     const element = filterForm[index].label === 'Phone' ? OnlyNumbers(filterForm[index].url) : filterForm[index].label === 'Email' ? validateEmail(filterForm[index].url) :validateUrl(filterForm[index].url)
//   //     filterForm[index].verfiyUrl = element
//   //     // if (validateUrl(filterForm[index].url) && validateUrl(urls.url)){
//   //     //   setError(false);
//   //     // } else {setError(true)};
//   //   }
//   // },[urls]);

//   // const handleAddBtn = () => {
//   //   setLoading(!loading);
//   //   // console.log("asdf====",);
//   //   for (let index = 0; index < filterForm.length; index++) {
//   //     if(filterForm[index].url.length > 0 && filterForm[index].displayText.length > 0){
//   //       check.push(true)
//   //     } else {setError(true)}
//   //   }
//   //   console.log("asdf====",check.length , filterForm.length);
//   //   if(check.length === filterForm.length){
//   //     cardData?.url.push(...filterForm);
//   //     setFilterForm([]);
//   //     setShowForm([]);
//   //     setUrls({...urls,url:'', displayText:''})
//   //     setSubAlert(false)
//   //   } else {
//   //     setSubAlert(true)
//   //   }
//   // }

//   const handleSubmitData = async () => {
//     if (edit === true) {
//       if (tabs === 0) {
//         // if (cardData.imageUrl && cardData.logoUrl) {
//         setTabs(1);
//         setShowAlert(false);
//         // } else {
//         //   setShowAlert(true);
//         // }
//       } else if (tabs === 1) {
//         console.log("tab1111--->", tab);
//         if (
//           cardData.company.length &&
//           // cardData.department.length &&
//           cardData.firstName.length &&
//           // cardData.headline.length &&
//           cardData.lastName.length &&
//           // cardData.maidenName.length &&
//           // cardData.middleName.length &&
//           // cardData.preFerredName.length &&
//           // cardData.prifix.length &&
//           // cardData.pronouns.length &&
//           cardData.phoneNumber.length &&
//           validateEmail(cardData.emails) &&
//           // cardData.suffix.length &&
//           cardData.title.length
//           // cardData.accreditation.length
//         ) {
//           console.log("tab3333--->", tab);
//           setTabs(2);
//           setShowAlert(false);
//         } else {
//           setShowAlert(true);
//         }
//       } else {
//         if (tabs === 2) {
//           const modify = {
//             firstName: `${cardData.pronouns} ${cardData.firstName}`,
//             lastName: cardData.lastName,
//             emails: cardData.emails,
//             phoneNumber: cardData.phoneNumber,
//             address: cardData.address,
//             title: cardData.title,
//             company: cardData.company,
//             imageUrl: cardData.imageUrl,
//             logoUrl: cardData.logoUrl,
//             youtube: `${cardData.youtube}`,
//             linkedin: `${cardData.linkedin}`,
//             instagram: `${cardData.instagram}`,
//             twitter: `${cardData.twitter}`,
//             role: `${cardData.role}`,
//             facebook: `${cardData.facebook}`,
//             cardName: `${cardData.cardName}`,
//           };
//           setEdit(false);
//           setAddNewCard(false);
//           if (modify) {
//             await service1
//               .updateCard(modify, cardData?.cardId)
//               .then(() => {
//                 getCardData();
//                 // setCustomCard(customCard);
//                 setShowAlert(false);
//                 setAddNewCard(false);
//                 setShowForm([]);
//                 setFilterForm([]);
//                 setCardData({
//                   ...cardData,
//                   layout: 1,
//                   color: 1,
//                   imgLayout: 1,
//                   imageUrl: "",
//                   // prifix: "",
//                   firstName: "",
//                   logoUrl: "",
//                   // middleName: "",
//                   lastName: "",
//                   // suffix: "",
//                   // accreditation: "",
//                   // preFerredName: "",
//                   // maidenName: "",
//                   pronouns: "",
//                   title: "",
//                   department: "",
//                   company: "",
//                   headline: "",
//                   // url: [],
//                   phoneNumber: "",
//                   emails: "",
//                   address: "",
//                   linkedin: "",
//                   instagram: "",
//                   twitter: "",
//                   website: "",
//                 });
//                 setTabs("1");
//               })
//               .catch((err) => {
//                 console.log("err---->", err);
//               });
//           }
//         }
//       }
//     } else {
//       if (tabs === 0) {
//         // if (cardData.imageUrl && cardData.logoUrl) {
//         setTabs(1);
//         setShowAlert(false);
//         // } else {
//         //   setShowAlert(true);
//         // }
//       } else if (tabs === 1) {
//         if (
//           // cardData.company.length &&
//           // cardData.department.length &&
//           // cardData.firstName.length &&
//           // cardData.headline.length &&
//           // cardData.lastName.length &&
//           // cardData.phoneNumber.length &&
//           // validateEmail(cardData.emails) &&
//           // cardData.maidenName.length &&
//           // cardData.middleName.length &&
//           // cardData.preFerredName.length &&
//           // cardData.prifix.length &&
//           // cardData.pronouns.length &&
//           // cardData.suffix.length &&
//           // cardData.title.length
//           // cardData.accreditation.length
//           // New Ui Validation
//           cardData.fullName.length &&
//           validateEmail(cardData.emails) &&
//           cardData.phoneNumber.length &&
//           cardData.company.length &&
//           cardData.role.length
//         ) {
//           console.log("cardData", cardData);
//           setTabs(2);
//           setShowAlert(false);
//         } else {
//           setShowAlert(true);
//         }
//       } else {
//         if (tabs === 2) {
//           const modify = {
//             firstName: `${cardData.pronouns} ${cardData.firstName}`,
//             lastName: cardData.lastName,
//             emails: cardData.emails,
//             phoneNumber: cardData.phoneNumber,
//             address: cardData.address,
//             title: cardData.title,
//             company: cardData.company,
//             imageUrl: cardData.imageUrl,
//             logoUrl: cardData.logoUrl,
//             youtube: `${cardData.youtube}`,
//             linkedin: `${cardData.linkedin}`,
//             instagram: `${cardData.instagram}`,
//             twitter: `${cardData.twitter}`,
//             role: `${cardData.role}`,
//             facebook: `${cardData.facebook}`,
//             cardName: `${cardData.cardName}`,
//             // links: `${cardData.linkedin}, ${cardData.instagram}, ${cardData.twitter}, ${cardData.website}`,
//             // layout: cardData.layout,
//             // color: cardData.color,
//             // pronouns: cardData.pronouns,
//             // department: cardData.department,
//             // headline: cardData.headline,
//             // imgLayout: cardData.imgLayout,
//             // id:customCard.length ? customCard?.length : 0,
//             // prifix: cardData.prifix,
//             // middleName: cardData.middleName,
//             // suffix: cardData.suffix,
//             // accreditation: cardData.accreditation,
//             // preFerredName: cardData.preFerredName,
//             // maidenName: cardData.maidenName,
//             // url: cardData.url,
//             // linkedin: cardData.linkedin,
//             // instagram: cardData.instagram,
//             // twitter: cardData.twitter,
//             // website: cardData.website
//           };
//           if (
//             // cardData.imageUrl &&
//             // cardData.logoUrl &&
//             cardData.company.length &&
//             // cardData.firstName.length &&
//             // cardData.lastName.length &&
//             cardData.fullName &&
//             cardData.role &&
//             cardData.phoneNumber.length &&
//             validateEmail(cardData.emails) &&
//             socialValidation("instagramExpand", "instagram") &&
//             socialValidation("twitterExpand", "twitter") &&
//             socialValidation("linkedinExpand", "linkedin") &&
//             socialValidation("youtubeExpand", "youtube") &&
//             socialValidation("facebookExpand", "facebook")
//             // cardData.title.length
//           ) {
//             customCard.push(modify);
//             const response = await service1.addCard(modify);
//             if (response) {
//               getCardData();
//               setShowAlert(false);
//               setAddNewCard(false);
//               setShowForm([]);
//               setFilterForm([]);
//               setCardData({
//                 ...cardData,
//                 layout: 1,
//                 color: 1,
//                 imgLayout: 1,
//                 imageUrl: "",
//                 logoUrl: "",
//                 firstName: "",
//                 lastName: "",
//                 pronouns: "",
//                 title: "",
//                 department: "",
//                 company: "",
//                 headline: "",
//                 phoneNumber: "",
//                 emails: "",
//                 address: "",
//                 linkedin: "",
//                 instagram: "",
//                 twitter: "",
//                 website: "",
//               });
//               setTabs("1");
//             }
//           } else {
//             console.log("showAlert--->asdf", showAlert);
//             setShowAlert(true);
//           }
//         } else {
//           setShowAlert(true);
//         }
//       }
//     }
//   };

//   const handleEdit = async (e: any, item: any, index: any) => {
//     console.log("item?.cardId--->", item?.cardId);
//     e.stopPropagation();
//     const cardResponse = await service1.getCardById(item?.cardId);
//     //   console.log('cardResponse-->', cardResponse)
//     //   const divideLinks = cardResponse?.links?.split(",")
//     //   let instaLink = ''
//     //   let twitterLink = ''
//     //   let linkedInLink = ''
//     //   let websiteLink = ''
//     //   divideLinks.forEach((link: any) => {
//     //   console.log('link-->', link, link.includes('instagram'))
//     //     if (link.includes('instagram')) {
//     //       instaLink = link
//     //     } else if (link.includes('linkedin')) {
//     //       twitterLink = link
//     //     } else  if (link.includes('twitter')){
//     //       linkedInLink = link
//     //     } else {
//     //       websiteLink = link
//     //     }
//     // })
//     setCardData({
//       ...cardData,
//       // this key added forfully to pass cardid in payload.
//       cardId: cardResponse.cardId,
//       layout: cardResponse.layout,
//       color: cardResponse.color,
//       imgLayout: cardResponse.imageUrl,
//       imageUrl: cardResponse.imageUrl,
//       logoUrl: cardResponse.logoUrl,
//       // prifix: item.prifix,
//       firstName: cardResponse.firstName,
//       // middleName: item.middleName,
//       lastName: cardResponse.lastName,
//       // suffix: item.suffix,
//       // accreditation: item.accreditation,
//       // preFerredName: item.preFerredName,
//       // maidenName: item.maidenName,
//       pronouns: cardResponse.pronouns,
//       title: cardResponse.title,
//       department: cardResponse.department,
//       company: cardResponse.company,
//       headline: cardResponse.headline,
//       // url: item.url,
//       phoneNumber: cardResponse.phoneNumber,
//       emails: cardResponse.emails,
//       address: cardResponse.address,
//       linkedin: cardResponse.links,
//       // instagram: instaLink,
//       // twitter: twitterLink,
//       // website: websiteLink
//     });
//     setSelectCard(index);
//     setFilterForm(item.url);
//     setAddNewCard(true);
//     setEdit(true);
//   };

//   const handleRemoveUrl = (it: any) => {
//     setLoading(!loading);
//     const remove = filterForm.filter((e: any) => e.label !== it.label);
//     // setError(false)
//     setSubAlert(false);
//     setFilterForm(remove);
//     // setUrls({...urls,url:'', displayText:''})
//     setShowForm(remove);
//     setIsFirstLoad(remove);
//   };

//   const handleSearch = (e: any) => {
//     setSearchData(e.target.value);
//     const filterdItem = allCards.filter(
//       (user: any) =>
//         // state takes time tu update, so search result will be delayed and to improve search functionality i used e.target.value in filter method to improve search performance.
//         user?.firstName
//           ?.toLowerCase()
//           .includes(e.target.value?.toLowerCase()) ||
//         user?.emails?.toLowerCase().includes(e.target.value?.toLowerCase())
//     );
//     sewtShowSearchData(filterdItem);
//   };
//   const handleLocalImage = (e: any) => {
//     setCardData({ ...cardData, virtualImage: "" });
//     // setLoading(!loading);
//     // if(e.target.files[0]){
//     //   console.log('item---->', e.target.files[0])
//     //   let data = URL.createObjectURL(e.target.files[0]);
//     //   // setSelectedImage(data)
//     //   setCardData({...cardData, virtualImage: data})
//     // }
//   };
//   const handleImage = (item: any) => {
//     setLoading(!loading);
//     // setSelectedImage(item);
//     // let data = URL.createObjectURL(item);
//     console.log("item---->", item);
//     setCardData({ ...cardData, virtualImage: item });
//   };
//   const clearSearchInput = () => {
//     setSearchData("");
//   };
//   const handleExpandSocialDetails = (keyName: any) => () => {
//     setSocialDetails({ ...socialDetails, [keyName]: !socialDetails[keyName] });
//   };
//   const socialValidation = (expandKey: string, cardDataKey: string) => {
//     if (socialDetails[expandKey]) {
//       return cardData[cardDataKey].length && displayTexts[cardDataKey].length;
//     } else {
//       return true;
//     }
//   };
//   // Life Cycle Metod

//   useEffect(() => {
//     getCardData();
//     setLoading(!loading);
//     if (showForm !== 0) {
//       const filterData = showForm?.filter(
//         (item: any, i: any) =>
//           i === showForm?.findIndex((items: any) => items.label === item.label)
//       );
//       setFilterForm(filterData);
//     }
//   }, [isFirstLoad]);

//   // UI Implamentation

//   return (
//     <Box className="mainLoginBox" position={"relative"}>
//       {!maxWidth890px && (
//         <SideBar commonTexts={texts.commonText} openDrawer={openDrawer} />
//       )}
//       <Box className="layout" position={"relative"}>
//         <HeaderBar
//           openAddCards={handleOpen}
//           openDrawer={openDrawer}
//           handleDrawer={handleDrawer}
//           searchInput={searchData}
//           handleSearch={handleSearch}
//           clearSearchInput={clearSearchInput}
//           commonText={texts.commonText}
//         />
//         {maxWidth890px && !addNewCard && (
//           <div className="mobileSearchBox">
//             <SearchField
//               searchData={searchData}
//               handleSearch={handleSearch}
//               commonText={texts.commonText}
//               clearSearchInput={clearSearchInput}
//             />
//             <Button>
//               <img
//                 src={require("../../Assets/Images/Icons/filter.png")}
//                 alt="filter"
//               />
//             </Button>
//           </div>
//         )}
//         {/* <AddSearchBar placeHolder={texts?.commonText.search} buttonName={texts?.commonText.addCard} handleSearch={handleSearch}  handleOpen={handleOpen}/> */}
//         {/* <TabContext value={value}>
//         <Box sx={{ borderBottom: 1, borderColor: 'divider' }}>
//           <TabList onChange={handleTabs} aria-label="lab API tabs example">
//             <Tab label="Cards" value="1" />
//             <Tab label="VirtualBackGrounds" value="2"  />
//           </TabList>
//         </Box>
//         <TabPanel value="1">
//         <CustomCards handleOpen={handleOpen} searchData={searchData} showSearchData={showSearchData} cards={allCards} handleEdit={handleEdit} commonTexts={texts} handleDeleteCard={handelConfirmModal}/>
//         </TabPanel>
//         <TabPanel value="2">
//           <VirtualBackGrounds cards={allCards} commonTexts={texts} handleOpen={handleOpen}/>
//        </TabPanel>
//       </TabContext> */}
//         {!addNewCard && (
//           <CustomCards
//             handleOpen={handleOpen}
//             searchData={searchData}
//             showSearchData={showSearchData}
//             cards={allCards}
//             handleEdit={handleEdit}
//             commonTexts={texts.commonText}
//             handleDeleteCard={handelConfirmModal}
//           />
//         )}
//         {addNewCard && (
//           <AddCardLayout
//             cardData={cardData}
//             handleCardLayout={handleCardLayout}
//             commonTexts={texts.commonText}
//             handleProfileImage={handleProfileImage}
//             handleLogo={handleLogo}
//             handleLocalImage={handleLocalImage}
//             showAlert={showAlert}
//             validateEmail={validateEmail}
//             tabs={tabs}
//             handleNextTab={handleNextTab}
//             handleBackTab={handleBackTab}
//             displayTexts={displayTexts}
//             handleCardDisplayLayout={handleCardDisplayLayout}
//             countryCode={countryCode}
//             handleCountrycode={handleCountrycode}
//             handleImage={handleImage}
//             socialDetails={socialDetails}
//             handleExpandSocialDetails={handleExpandSocialDetails}
//             //  selectedImage={selectedImage}
//           />
//         )}
//         {/* <AddNewCard
//               addNewCard={addNewCard}
//               commonTexts={texts}
//               handleClose={handleClose}
//               tabs={tabs}
//               handleChange={handleChange}
//               filterForm={filterForm}
//               cardData={cardData}
//               handleCardLayout={handleCardLayout}
//               showAlert={showAlert}
//               handleProfileImage={handleProfileImage}
//               handleLogo={handleLogo}
//               // handleUnSelect={handleUnSelect}
//               // handleFieldInput={handleFieldInput}
//               handleRemoveUrl={handleRemoveUrl}
//               error={error}
//               // urls={urls}
//               subAlert={subAlert}
//               handleUrlData={handleUrlData}
//               handleSubmitData={handleSubmitData}`
//               // handleAddBtn={handleAddBtn}
//               // filterData2={filterData2}
//             /> */}
//         {allCards.length > 0 && !addNewCard && (
//           <Box className="dashboardCardFooterbox">
//             <Button className="dashboardCardEditBtn" onClick={handleOpen}>
//               Add Card
//             </Button>
//           </Box>
//         )}
//       </Box>
//       {/* <DetailsBox commonTexts={texts} /> */}
//       <ConfirmModal
//         open={confirm}
//         onClose={(e: any) => handelConfirmModal(e, false, "")}
//         confirmClick={(e: any) => handleDeleteCard(e)}
//         commonTexts={texts.commonText}
//       />
//     </Box>
//   );
// }

import React, { useEffect, useState } from "react";
import {
  Avatar,
  Box,
  Button,
  Divider,
  Drawer,
  List,
  ListItem,
  TextField,
  Typography,
  useMediaQuery,
  InputAdornment,
} from "@mui/material";

import "./dashBoardPage.css";
import texts from "../../Assets/CommonTexts/texts.json";
// import DetailsBox from "../../Components/dashboardComponent/detailsBox.tsx";
import SideBar from "../../Components/commonComponents/sideBar/sideBar.tsx";
// import AddNewCard from "../../Components/dashboardComponent/addNewCardComponents/addNewCard.tsx";
import {
  OnlyNumbers,
  validateEmail,
  validateUrl,
} from "../../utils/commonRegex.ts";
import AddNewCard from "../../Components/dashboardComponent/addNewCard/addNewCard.tsx";
import AddSearchBar from "../../Components/commonComponents/addSearchBar/addSearchBar.tsx";
import CustomCards from "../../Components/dashboardComponent/customCard/customCards.tsx";
import VirtualBackGrounds from "../../Components/dashboardComponent/virtualBackGrounds/virtualBackGrounds.tsx";
import Tab from "@mui/material/Tab";
import TabContext from "@mui/lab/TabContext";
import TabList from "@mui/lab/TabList";
import TabPanel from "@mui/lab/TabPanel";
import { tab } from "@testing-library/user-event/dist/tab";
import service1 from "../../services/service1.ts";
import ConfirmModal from "../../Components/commonComponents/modals/confirmModal/confirmModal.tsx";
import HeaderBar from "../../Components/commonComponents/headerBar/headerBar.tsx";
import AddCardLayout from "../../Components/dashboardComponent/addCardLayout/addCardLayout.tsx";
import CloseIcon from "@mui/icons-material/Close";
import { commonColors } from "../../Assets/colors.ts";
import SearchField from "../../Components/commonComponents/searchField/searchField.tsx";
import Cookies from "universal-cookie";
import ContactPage from "../contactPage/contactPage.tsx";
export default function DashBoardPage() {
  // Custom States
  const [showContactPage, setShowContactPage] = useState(false);

  const [addNewCard, setAddNewCard] = useState(false);
  const [customCard, setCustomCard] = useState<any>([]);
  const [countryCode, setCountrycode] = useState({
    code: "IN",
    label: "India",
    phone: "+91",
  });
  // const [selectedImage, setSelectedImage] = useState(""
  //   // require("../../Assets/Images/image/demo.jpg")
  // );
  const maxWidth890px = useMediaQuery("(max-width:890px)");

  const [cardData, setCardData] = useState({
    layout: 1,
    color: 1,
    imgLayout: 1,
    imageUrl: "",
    logoLayout: 1,
    logoUrl: "",
    firstName: "",
    lastName: "",
    pronouns: "",
    title: "",
    department: "",
    company: "",
    headline: "",
    phoneNumber: "",
    emails: "",
    address: "",
    linkedin: "",
    instagram: "",
    twitter: "",
    website: "",
    // new Ui keys
    fullName: "",
    role: "",
    youtube: "",
    facebook: "",
    virtualImage: "",
    cardName: "",
    personalImage: "",
    logo: "",
  });
  const [displayTexts, setDisplayTexts] = useState({
    instagram: "",
    youtube: "",
    facebook: "",
    twitter: "",
    linkedin: "",
  });
  const [showFields, setShowFields] = useState<any>(false);
  const [tabs, setTabs] = React.useState(0);
  const [edit, setEdit] = useState(false);
  const [selelctCard, setSelectCard] = useState<any>();
  // const [urls, setUrls] = useState({
  //   url: "",
  //   displayText: "",
  // })
  const [allCards, setAllcards] = useState<any>([]);
  const [isFirstLoad, setIsFirstLoad] = useState("");
  const [showForm, setShowForm] = useState<any>([]);
  const [loading, setLoading] = useState<any>(false);
  const [filterForm, setFilterForm] = useState<any>([]);
  const [showAlert, setShowAlert] = useState(false);
  const [searchData, setSearchData] = useState("");
  const [showSearchData, sewtShowSearchData] = useState([]);
  const [subAlert, setSubAlert] = useState(false);
  const [error, setError] = useState(false);
  const [value, setValue] = React.useState("1");
  const [confirm, setConfirm] = useState(false);
  const [openDrawer, setOpenDrawer] = useState(false);
  const [showAddCardLayout, setShowAddCardLayout] = useState(false);
  const [socialDetails, setSocialDetails] = useState({
    instagramExpand: false,
    youtubeExpand: false,
    facebookExpand: false,
    twitterExpand: false,
    linkedinExpand: false,
  });
  const toggleAddCardLayout = () => {
    setShowAddCardLayout((prev) => !prev);
  };
  const [cardId, setCardIs] = useState("");
  const check = [] as any;

  // Custom Methods
  const handleTabs = (event: React.SyntheticEvent, newValue: string) => {
    setValue(newValue);
  };
  const handleDrawer = (value: any) => {
    setOpenDrawer(value);
  };

  const handleNextTab = () => {
    // console.log('cardDate--->', cardData)
    // setTabs((tab) =>  tab + 1)
    handleSubmitData();
  };

  const handleBackTab = () => {
    setTabs((tab) => tab - 1);
  };

  const handleCountrycode = (value) => {
    setCountrycode(value);
  };
  const handleOpen = () => {
    return setAddNewCard(true);
  };

  const handleClose = () => {
    setShowAlert(false);
    setAddNewCard(false);
    setShowForm([]);
    setFilterForm([]);
    setCardData({
      ...cardData,
      layout: 1,
      color: 1,
      imgLayout: 1,
      imageUrl: "",
      // prifix: "",
      firstName: "",
      logoUrl: "",
      // middleName: "",
      lastName: "",
      // suffix: "",
      // accreditation: "",
      // preFerredName: "",
      // maidenName: "",
      pronouns: "",
      title: "",
      department: "",
      company: "",
      headline: "",
      // url: [],
      phoneNumber: "",
      emails: "",
      address: "",
      linkedin: "",
      instagram: "",
      twitter: "",
      website: "",
      personalImage: "",
      logo: "",
    });
    setTabs("1");
  };

  const handleCardLayout = (keyName: any, e: any) => {
    const value = e.target ? e.target.value : e;

    setCardData((prev) => {
      let updatedData = { ...prev, [keyName]: value };

      // Ensure firstName, lastName, and cardName exist in the state
      if (!updatedData.firstName) updatedData.firstName = "";
      if (!updatedData.lastName) updatedData.lastName = "";
      if (!updatedData.cardName) updatedData.cardName = "";

      // Clear imageUrl if virtualImage is being set
      if (keyName === "virtualImage") {
        updatedData.imageUrl = ""; // Clear imageUrl
      }

      // Split fullName into firstName and lastName
      if (keyName === "fullName") {
        const nameParts = value.trim().split(" ");
        updatedData.firstName = nameParts[0] || ""; // First part as firstName
        updatedData.lastName = nameParts.slice(1).join(" ") || ""; // Remaining parts as lastName
      }

      // Handle cardName separately
      if (keyName === "cardName") {
        updatedData.cardName = value || ""; // Set or clear the cardName
      }
      return updatedData;
    });
  };

  const handleCardDisplayLayout = (keyName: any, e: any) => {
    setDisplayTexts({
      ...displayTexts,
      [keyName]: e.target ? e.target.value : e,
    });
  };

  const handleProfileImage = async (e: any) => {
    if (e.target.files[0]) {
      const file = e.target.files[0];

      try {
        // Convert file to Base64
        const base64Image = await fileToBase64(file);
        const imageUrl = URL.createObjectURL(file);

        setCardData((prev) => {
          let updatedData = {
            ...prev,
            base64Image, // Store Base64 string
            personalImage: base64Image, // Add Base64 key for API
            imageUrl, // Store image URL
          };

          // Clear virtualImage if base64Image is being set
          updatedData.virtualImage = "";
          return updatedData;
        });
      } catch (error) {
        console.error("Error converting file to Base64:", error);
      }
    }
  };

  const handleLogo = async (e: any) => {
    if (e.target.files[0]) {
      const file = e.target.files[0];

      try {
        // Convert file to Base64
        const base64Logo = await fileToBase64(file);
        const logoUrl = URL.createObjectURL(file);

        setCardData((prev) => ({
          ...prev,
          base64Logo, // Store Base64 string
          logo: base64Logo, // Add Base64 key for API
          logoUrl, // Store logo URL
        }));
      } catch (error) {
        console.error("Error converting file to Base64:", error);
      }
    }
  };

  // Helper function to convert file to Base64
  function fileToBase64(file: File): Promise<string> {
    return new Promise((resolve, reject) => {
      const reader = new FileReader();
      reader.onload = () => resolve((reader.result as string).split(",")[1]); // Extract Base64 data
      reader.onerror = (error) => reject(error);
      reader.readAsDataURL(file);
    });
  }

  // const handleProfileImage = (e: any) => {
  //   if (e.target.files[0]) {
  //     const imageUrl = URL.createObjectURL(e.target.files[0]);

  //     setCardData((prev) => {
  //       let updatedData = { ...prev, imageUrl };

  //       // Clear virtualImage if imageUrl is being set
  //       updatedData.virtualImage = ""; // Clear virtualImage
  //       return updatedData;
  //     });
  //   }
  // };

  // const handleLogo = (e: any) => {
  //   if (e.target.files[0]) {
  //     setCardData({
  //       ...cardData,
  //       logoUrl: URL.createObjectURL(e.target.files[0]),
  //     });
  //   }
  // };

  // const handleFieldInput = (keyName: any, e: any, i: any) => {
  //   setUrls({ ...urls, [keyName]: e.target.value })
  //   if(keyName === 'url'){
  //     let url =  e.target.value
  //     filterForm[i].url = url
  //   } else if( keyName === 'displayText'){
  //     let displayText = e.target.value
  //     filterForm[i].displayText = displayText
  //   }
  // };

  // const filterData2 = cardData.url.filter(
  //   (item: any, i: any) =>
  //     i === cardData.url.findIndex((items: any) => items.label === item.label)
  // );

  const handleUrlData = (e: any) => {
    setIsFirstLoad(e);
    const data = {
      id: e.id,
      label: e.label,
      logoUrl: e.logo,
      url: "",
      displayText: "",
      verfiyUrl: false,
    };
    showForm.push(data);
    setShowFields(false);
  };

  // const handleUnSelect = (e: any) => {
  //   let test = filterData2.filter((itm: any) => e.label !== itm.label);
  //   setCardData({ ...cardData, url: test });
  // };

  const getCardData = async () => {
    try {
      const cookies = new Cookies();
      const token = cookies.get("idToken"); // Retrieve token from cookies
      console.log(`token`, token);
      // Fetch API URL from environment variables
      const apiUrl = process.env.REACT_APP_API_URL; // Replace with your variable name
      if (!apiUrl) {
        throw new Error(
          "API base URL is not defined in environment variables."
        );
      }

      // Make the API call using fetch
      const response = await fetch(`${apiUrl}/cards`, {
        method: "GET",
        headers: {
          "Content-Type": "application/json", // Ensure proper header
          Authorization: `${token}`, // Add token to the Authorization header
        },
      });
      console.log(response);

      if (!response.ok) {
        throw new Error(`API call failed with status: ${response.status}`);
      }

      const data = await response.json();
      if (!Array.isArray(data)) {
        throw new Error("Unexpected API response format. Expected an array.");
      }

      // Set the cards data
      setAllcards(data);
      console.log("All cards:", data);
    } catch (error) {
      console.error("Error fetching card data:", error.message);
    }
  };

  const handleDeleteCard = async (e: any) => {
    console.log("cardIdasdfasfd>", cardId);
    await service1
      .deleteCard(cardId)
      .then(() => {
        getCardData();
        handelConfirmModal(e, false, "");
      })
      .catch((err) => {
        console.log("err--->", err);
      });
  };

  const handelConfirmModal = async (e: any, value: boolean, id: string) => {
    // This ( e.stopPropagation()) will stop on child click it will trigger parent.
    e.stopPropagation();
    setConfirm(value);
    setCardIs(id);
  };

  // useEffect(() => {
  //   setLoading(!loading)
  //   for (let index = 0; index < filterForm?.length; index++) {
  //     const element = filterForm[index].label === 'Phone' ? OnlyNumbers(filterForm[index].url) : filterForm[index].label === 'Email' ? validateEmail(filterForm[index].url) :validateUrl(filterForm[index].url)
  //     filterForm[index].verfiyUrl = element
  //     // if (validateUrl(filterForm[index].url) && validateUrl(urls.url)){
  //     //   setError(false);
  //     // } else {setError(true)};
  //   }
  // },[urls]);

  // const handleAddBtn = () => {
  //   setLoading(!loading);
  //   // console.log("asdf====",);
  //   for (let index = 0; index < filterForm.length; index++) {
  //     if(filterForm[index].url.length > 0 && filterForm[index].displayText.length > 0){
  //       check.push(true)
  //     } else {setError(true)}
  //   }
  //   console.log("asdf====",check.length , filterForm.length);
  //   if(check.length === filterForm.length){
  //     cardData?.url.push(...filterForm);
  //     setFilterForm([]);
  //     setShowForm([]);
  //     setUrls({...urls,url:'', displayText:''})
  //     setSubAlert(false)
  //   } else {
  //     setSubAlert(true)
  //   }
  // }

  const handleSubmitData = async () => {
    if (edit === true) {
      if (tabs === 0) {
        // if (cardData.imageUrl && cardData.logoUrl) {
        setTabs(1);
        setShowAlert(false);
        // } else {
        //   setShowAlert(true);
        // }
      } else if (tabs === 1) {
        console.log("tab1111--->", tab);
        if (
          cardData.company.length &&
          // cardData.department.length &&
          cardData.firstName.length &&
          // cardData.headline.length &&
          cardData.lastName.length &&
          // cardData.maidenName.length &&
          // cardData.middleName.length &&
          // cardData.preFerredName.length &&
          // cardData.prifix.length &&
          // cardData.pronouns.length &&
          cardData.phoneNumber.length &&
          validateEmail(cardData.emails) &&
          // cardData.suffix.length &&
          cardData.title.length
          // cardData.accreditation.length
        ) {
          console.log("tab3333--->", tab);
          setTabs(2);
          setShowAlert(false);
        } else {
          setShowAlert(true);
        }
      } else {
        if (tabs === 2) {
          const modify = {
            firstName: `${cardData.pronouns} ${cardData.firstName}`,
            lastName: cardData.lastName,
            emails: cardData.emails,
            phoneNumber: cardData.phoneNumber,
            address: cardData.address,
            title: cardData.title,
            company: cardData.company,
            imageUrl: cardData.imageUrl,
            logoUrl: cardData.logoUrl,
            logo: cardData.logo,
            personalImage: cardData.personalImage,
            youtube: `${cardData.youtube}`,
            linkedin: `${cardData.linkedin}`,
            instagram: `${cardData.instagram}`,
            twitter: `${cardData.twitter}`,
            role: `${cardData.role}`,
            facebook: `${cardData.facebook}`,
            cardName: `${cardData.cardName}`,
          };
          setEdit(false);
          setAddNewCard(false);
          if (modify) {
            await service1
              .updateCard(modify, cardData?.cardId)
              .then(() => {
                getCardData();
                // setCustomCard(customCard);
                setShowAlert(false);
                setAddNewCard(false);
                setShowForm([]);
                setFilterForm([]);
                setCardData({
                  ...cardData,
                  layout: 1,
                  color: 1,
                  imgLayout: 1,
                  imageUrl: "",
                  // prifix: "",
                  firstName: "",
                  logoUrl: "",
                  // middleName: "",
                  lastName: "",
                  // suffix: "",
                  // accreditation: "",
                  // preFerredName: "",
                  // maidenName: "",
                  pronouns: "",
                  title: "",
                  department: "",
                  company: "",
                  headline: "",
                  // url: [],
                  phoneNumber: "",
                  emails: "",
                  address: "",
                  linkedin: "",
                  instagram: "",
                  twitter: "",
                  website: "",
                  personalImage: "",
                  logo: "",
                });
                setTabs("1");
              })
              .catch((err) => {
                console.log("err---->", err);
              });
          }
        }
      }
    } else {
      if (tabs === 0) {
        // if (cardData.imageUrl && cardData.logoUrl) {
        setTabs(1);
        setShowAlert(false);
        // } else {
        //   setShowAlert(true);
        // }
      } else if (tabs === 1) {
        if (
          // cardData.company.length &&
          // cardData.department.length &&
          // cardData.firstName.length &&
          // cardData.headline.length &&
          // cardData.lastName.length &&
          // cardData.phoneNumber.length &&
          // validateEmail(cardData.emails) &&
          // cardData.maidenName.length &&
          // cardData.middleName.length &&
          // cardData.preFerredName.length &&
          // cardData.prifix.length &&
          // cardData.pronouns.length &&
          // cardData.suffix.length &&
          // cardData.title.length
          // cardData.accreditation.length
          // New Ui Validation
          cardData.fullName.length &&
          validateEmail(cardData.emails) &&
          cardData.phoneNumber.length &&
          cardData.company.length &&
          cardData.role.length
        ) {
          console.log("cardData", cardData);
          setTabs(2);
          setShowAlert(false);
        } else {
          setShowAlert(true);
        }
      } else {
        if (tabs === 2) {
          const modify = {
            firstName: `${cardData.pronouns} ${cardData.firstName}`,
            lastName: cardData.lastName,
            emails: cardData.emails,
            phoneNumber: cardData.phoneNumber,
            address: cardData.address,
            title: cardData.title,
            company: cardData.company,
            imageUrl: cardData.imageUrl,
            logoUrl: cardData.logoUrl,
            youtube: `${cardData.youtube}`,
            linkedin: `${cardData.linkedin}`,
            instagram: `${cardData.instagram}`,
            twitter: `${cardData.twitter}`,
            role: `${cardData.role}`,
            facebook: `${cardData.facebook}`,
            cardName: `${cardData.cardName}`,
            personalImage: `${cardData.personalImage}`,
            logo: `${cardData.logo}`,
            // links: `${cardData.linkedin}, ${cardData.instagram}, ${cardData.twitter}, ${cardData.website}`,
            // layout: cardData.layout,
            // color: cardData.color,
            // pronouns: cardData.pronouns,
            // department: cardData.department,
            // headline: cardData.headline,
            // imgLayout: cardData.imgLayout,
            // id:customCard.length ? customCard?.length : 0,
            // prifix: cardData.prifix,
            // middleName: cardData.middleName,
            // suffix: cardData.suffix,
            // accreditation: cardData.accreditation,
            // preFerredName: cardData.preFerredName,
            // maidenName: cardData.maidenName,
            // url: cardData.url,
            // linkedin: cardData.linkedin,
            // instagram: cardData.instagram,
            // twitter: cardData.twitter,
            // website: cardData.website
          };
          if (
            // cardData.imageUrl &&
            // cardData.logoUrl &&
            cardData.company.length &&
            // cardData.firstName.length &&
            // cardData.lastName.length &&
            cardData.fullName &&
            cardData.role &&
            cardData.phoneNumber.length &&
            validateEmail(cardData.emails) &&
            socialValidation("instagramExpand", "instagram") &&
            socialValidation("twitterExpand", "twitter") &&
            socialValidation("linkedinExpand", "linkedin") &&
            socialValidation("youtubeExpand", "youtube") &&
            socialValidation("facebookExpand", "facebook")
            // cardData.title.length
          ) {
            customCard.push(modify);
            const response = await service1.addCard(modify);
            if (response) {
              getCardData();
              setShowAlert(false);
              setAddNewCard(false);
              setShowForm([]);
              setFilterForm([]);
              setCardData({
                ...cardData,
                layout: 1,
                color: 1,
                imgLayout: 1,
                imageUrl: "",
                logoUrl: "",
                firstName: "",
                lastName: "",
                pronouns: "",
                title: "",
                department: "",
                company: "",
                headline: "",
                phoneNumber: "",
                emails: "",
                address: "",
                linkedin: "",
                instagram: "",
                twitter: "",
                website: "",
                personalImage: "",
                logo: "",
              });
              setTabs("1");
            }
          } else {
            console.log("showAlert--->asdf", showAlert);
            setShowAlert(true);
          }
        } else {
          setShowAlert(true);
        }
      }
    }
  };

  const handleEdit = async (e: any, item: any, index: any) => {
    console.log("item?.cardId--->", item?.cardId);
    e.stopPropagation();
    const cardResponse = await service1.getCardById(item?.cardId);
    //   console.log('cardResponse-->', cardResponse)
    //   const divideLinks = cardResponse?.links?.split(",")
    //   let instaLink = ''
    //   let twitterLink = ''
    //   let linkedInLink = ''
    //   let websiteLink = ''
    //   divideLinks.forEach((link: any) => {
    //   console.log('link-->', link, link.includes('instagram'))
    //     if (link.includes('instagram')) {
    //       instaLink = link
    //     } else if (link.includes('linkedin')) {
    //       twitterLink = link
    //     } else  if (link.includes('twitter')){
    //       linkedInLink = link
    //     } else {
    //       websiteLink = link
    //     }
    // })
    setCardData({
      ...cardData,
      // this key added forfully to pass cardid in payload.
      cardId: cardResponse.cardId,
      layout: cardResponse.layout,
      color: cardResponse.color,
      imgLayout: cardResponse.imageUrl,
      imageUrl: cardResponse.imageUrl,
      logoUrl: cardResponse.logoUrl,
      // prifix: item.prifix,
      firstName: cardResponse.firstName,
      // middleName: item.middleName,
      lastName: cardResponse.lastName,
      // suffix: item.suffix,
      // accreditation: item.accreditation,
      // preFerredName: item.preFerredName,
      // maidenName: item.maidenName,
      pronouns: cardResponse.pronouns,
      title: cardResponse.title,
      department: cardResponse.department,
      company: cardResponse.company,
      headline: cardResponse.headline,
      // url: item.url,
      phoneNumber: cardResponse.phoneNumber,
      emails: cardResponse.emails,
      address: cardResponse.address,
      linkedin: cardResponse.links,
      // instagram: instaLink,
      // twitter: twitterLink,
      // website: websiteLink
    });
    setSelectCard(index);
    setFilterForm(item.url);
    setAddNewCard(true);
    setEdit(true);
  };

  const handleRemoveUrl = (it: any) => {
    setLoading(!loading);
    const remove = filterForm.filter((e: any) => e.label !== it.label);
    // setError(false)
    setSubAlert(false);
    setFilterForm(remove);
    // setUrls({...urls,url:'', displayText:''})
    setShowForm(remove);
    setIsFirstLoad(remove);
  };

  const handleSearch = (e: any) => {
    setSearchData(e.target.value);
    const filterdItem = allCards.filter(
      (user: any) =>
        // state takes time tu update, so search result will be delayed and to improve search functionality i used e.target.value in filter method to improve search performance.
        user?.firstName
          ?.toLowerCase()
          .includes(e.target.value?.toLowerCase()) ||
        user?.emails?.toLowerCase().includes(e.target.value?.toLowerCase())
    );
    sewtShowSearchData(filterdItem);
  };
  const handleLocalImage = (e: any) => {
    setCardData({ ...cardData, virtualImage: "" });
    // setLoading(!loading);
    // if(e.target.files[0]){
    //   console.log('item---->', e.target.files[0])
    //   let data = URL.createObjectURL(e.target.files[0]);
    //   // setSelectedImage(data)
    //   setCardData({...cardData, virtualImage: data})
    // }
  };
  const handleImage = (item: any) => {
    setLoading(!loading);
    // setSelectedImage(item);
    // let data = URL.createObjectURL(item);
    console.log("item---->", item);
    setCardData({ ...cardData, virtualImage: item });
  };
  const clearSearchInput = () => {
    setSearchData("");
  };
  const handleExpandSocialDetails = (keyName: any) => () => {
    setSocialDetails({ ...socialDetails, [keyName]: !socialDetails[keyName] });
  };
  const socialValidation = (expandKey: string, cardDataKey: string) => {
    if (socialDetails[expandKey]) {
      return cardData[cardDataKey].length && displayTexts[cardDataKey].length;
    } else {
      return true;
    }
  };
  // Life Cycle Metod

  useEffect(() => {
    getCardData();
    setLoading(!loading);
    if (showForm !== 0) {
      const filterData = showForm?.filter(
        (item: any, i: any) =>
          i === showForm?.findIndex((items: any) => items.label === item.label)
      );
      setFilterForm(filterData);
    }
  }, [isFirstLoad]);

  const toggleContactPage = () => setShowContactPage((prev) => !prev);

  // UI Implamentation

  return (
    <Box className="mainLoginBox" position={"relative"}>
      {!maxWidth890px && (
        <SideBar commonTexts={texts.commonText} openDrawer={openDrawer} />
      )}
      <Box className="layout" position={"relative"}>
        <HeaderBar
          // openAddCards={handleOpen}
          openDrawer={openDrawer}
          handleDrawer={handleDrawer}
          // searchInput={searchData}
          // handleSearch={handleSearch}
          // clearSearchInput={clearSearchInput}
          // commonText={texts.commonText}
        />
        {!addNewCard && (
          <div className="mobileSearchBox">
            <div className="searchAndFilter">
              {/* Search Field */}
              <SearchField
                searchData={searchData}
                handleSearch={handleSearch}
                commonText={texts.commonText}
                clearSearchInput={clearSearchInput}
              />

              {/* Filter Button */}
              <div className="filterButton">
                <Button>
                  <img
                    src={require("../../Assets/Images/Icons/filter.png")}
                    alt="filter"
                  />
                </Button>
              </div>
            </div>

            {/* Cards and Contacts Buttons (Now Below the Search Bar and Filter) */}
            <div className="toggleButtons">
              <Button
                onClick={() => setShowContactPage(false)}
                variant="contained"
                className="cardsButton"
                disabled={!showContactPage}
              >
                Cards
              </Button>

              <Button
                onClick={() => setShowContactPage(true)}
                variant="contained"
                className="contactsButton"
                disabled={showContactPage}
              >
                Contacts
              </Button>
            </div>
          </div>
        )}

        <div className="yourcards">
          <h1>{showContactPage ? "Contacts" : "Cards"}</h1>
        </div>

        {showContactPage ? (
          <ContactPage />
        ) : (
          <>
            {!addNewCard && (
              <CustomCards
                handleOpen={handleOpen}
                searchData={searchData}
                showSearchData={[]}
                cards={allCards}
                handleEdit={(e: any) => console.log("Edit logic here", e)}
                commonTexts={{}}
                handleDeleteCard={(e: any) =>
                  console.log("Delete logic here", e)
                }
              />
            )}
            {addNewCard && (
              <AddCardLayout
                cardData={cardData}
                handleCardLayout={handleCardLayout}
                commonTexts={texts.commonText}
                handleProfileImage={handleProfileImage}
                handleLogo={handleLogo}
                handleLocalImage={handleLocalImage}
                showAlert={showAlert}
                validateEmail={validateEmail}
                tabs={tabs}
                handleNextTab={handleNextTab}
                handleBackTab={handleBackTab}
                displayTexts={displayTexts}
                handleCardDisplayLayout={handleCardDisplayLayout}
                countryCode={countryCode}
                handleCountrycode={handleCountrycode}
                handleImage={handleImage}
                socialDetails={socialDetails}
                handleExpandSocialDetails={handleExpandSocialDetails}
              />
            )}
            {allCards.length > 0 && !addNewCard && (
              <Box className="dashboardCardFooterbox">
                <Button className="dashboardCardEditBtn" onClick={handleOpen}>
                  Add Card
                </Button>
              </Box>
            )}
          </>
        )}
      </Box>
      <ConfirmModal
        open={confirm}
        onClose={(e: any) => handelConfirmModal(e, false, "")}
        confirmClick={(e: any) => handleDeleteCard(e)}
        commonTexts={texts.commonText}
      />
    </Box>
  );
}
