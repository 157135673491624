import { Box, Button, Drawer, TextField, Typography } from "@mui/material";
import React, { useState } from "react";
import { commonColors } from "../../../Assets/colors.ts";
import "./addContact.css";
import WebRoundedIcon from "@mui/icons-material/WebRounded";
import CloseRoundedIcon from "@mui/icons-material/CloseRounded";
interface props {
  open?: any;
  handleClose?: any;
  handleSubmitData?: any;
  handleContactData?: any;
  handleAddMoreInfo?: any;
  contactData?: any;
  showAlert?: any;
  handleRemove?: any;
  filterData?: any;
  handleAddMoreInput?: any;
  showSubAlert?: any;
  addMoreInfo?: any;
  error?: any;
  commonText?: any;
}

function AddContact({
  open,
  handleClose,
  handleSubmitData,
  handleContactData,
  handleAddMoreInfo,
  contactData,
  showAlert,
  handleRemove,
  filterData,
  handleAddMoreInput,
  showSubAlert,
  addMoreInfo,
  error,
  commonText,
}: props) {
  const color = [
    { color: 1 },
    { color: 2 },
    { color: 3 },
    { color: 4 },
    { color: 5 },
    { color: 6 },
  ];
  const addMore = [
    {
      id: 1,
      label: "Phone",
      logo: WebRoundedIcon,
    },
    {
      id: 2,
      label: "Email",
      logo: WebRoundedIcon,
    },
    {
      id: 4,
      label: "Address",
      logo: WebRoundedIcon,
    },
    {
      id: 5,
      label: "Website",
      logo: WebRoundedIcon,
    },
    {
      id: 6,
      label: "Link",
      logo: WebRoundedIcon,
    },
    {
      id: 7,
      label: "PDF",
      logo: WebRoundedIcon,
    },
    {
      id: 8,
      label: "X.com",
      logo: WebRoundedIcon,
    },
    {
      id: 9,
      label: "Instagram",
      logo: WebRoundedIcon,
    },
    {
      id: 10,
      label: "Linkedin",
      logo: WebRoundedIcon,
    },
    {
      id: 11,
      label: "Facebook",
      logo: WebRoundedIcon,
    },
    {
      id: 12,
      label: "Youtube",
      logo: WebRoundedIcon,
    },
    {
      id: 13,
      label: "WhatsApp",
      logo: WebRoundedIcon,
    },
    {
      id: 14,
      label: "Snapchat",
      logo: WebRoundedIcon,
    },
    {
      id: 15,
      label: "TikTok",
      logo: WebRoundedIcon,
    },
    {
      id: 16,
      label: "Github",
      logo: WebRoundedIcon,
    },
    {
      id: 17,
      label: "Yeip",
      logo: WebRoundedIcon,
    },
    {
      id: 18,
      label: "Venmo",
      logo: WebRoundedIcon,
    },
    {
      id: 19,
      label: "PayPal",
      logo: WebRoundedIcon,
    },
    {
      id: 20,
      label: "CashApp",
      logo: WebRoundedIcon,
    },

    {
      id: 21,
      label: "Calendly",
      logo: WebRoundedIcon,
    },

    {
      id: 22,
      label: "Discord",
      logo: WebRoundedIcon,
    },

    {
      id: 23,
      label: "Twitch",
      logo: WebRoundedIcon,
    },

    {
      id: 24,
      label: "Telegram",
      logo: WebRoundedIcon,
    },
    {
      id: 25,
      label: "Zelte",
      logo: WebRoundedIcon,
    },
    {
      id: 26,
      label: "Skype",
      logo: WebRoundedIcon,
    },
    {
      id: 27,
      label: "Wechat",
      logo: WebRoundedIcon,
    },
    {
      id: 28,
      label: "Signal",
      logo: WebRoundedIcon,
    },
    {
      id: 29,
      label: "Nintendo Switch",
      logo: WebRoundedIcon,
    },
    {
      id: 30,
      label: "PSN",
      logo: WebRoundedIcon,
    },
    {
      id: 31,
      label: "Xbox Live",
      logo: WebRoundedIcon,
    },
    {
      id: 32,
      label: "Xing",
      logo: WebRoundedIcon,
    },
    {
      id: 33,
      label: "Dribbble",
      logo: WebRoundedIcon,
    },
    {
      id: 34,
      label: "Behance",
      logo: WebRoundedIcon,
    },
    {
      id: 35,
      label: "Pinterest",
      logo: WebRoundedIcon,
    },
    {
      id: 36,
      label: "Patreon",
      logo: WebRoundedIcon,
    },
    {
      id: 37,
      label: "Vimeo",
      logo: WebRoundedIcon,
    },
    {
      id: 38,
      label: "Line",
      logo: WebRoundedIcon,
    },
    {
      id: 39,
      label: "Date",
      logo: WebRoundedIcon,
    },
    {
      id: 40,
      label: "Note",
      logo: WebRoundedIcon,
    },
    {
      id: 41,
      label: "Apple Music",
      logo: WebRoundedIcon,
    },
  ];
  return (
    <Drawer
      anchor="right"
      open={open}
      onClose={handleClose}
      ModalProps={{
        slotProps: { backdrop: { invisible: true } },
      }}
      PaperProps={{ sx: { width: "100%" } }}
    >
      <Box>
        <div className="contact-drawerImgBox">
          <input
            id="addImg"
            hidden
            type="file"
            onChange={(e: any) => handleContactData("profileImg", e)}
          />
          <label htmlFor="addImg">
            <img
              className="contact-drawerImg"
              src={
                contactData.profileImg
                  ? contactData.profileImg
                  : "https://static.vecteezy.com/system/resources/previews/020/213/738/non_2x/add-profile-picture-icon-upload-photo-of-social-media-user-vector.jpg"
              }
            />
          </label>
        </div>

        {showAlert && contactData.profileImg.length == 0 && (
          <Typography className="alertText">
            {commonText.commonText.enterProfileImage}
          </Typography>
        )}
        <span className="contact-colorBox">
          {color.map((it: any, index: any) => (
            <span className="colorSpan" key={index}>
              <div
                onClick={() => handleContactData("theme", it.color)}
                className="customColors"
                style={{
                  backgroundColor:
                    it.color === 6
                      ? commonColors.AccentColor
                      : it.color === 5
                      ? commonColors.dynamicPurple
                      : it.color === 4
                      ? commonColors.dynamicBlue
                      : it.color === 3
                      ? commonColors.dynamicGreen
                      : it.color === 2
                      ? commonColors.dynamicOrange
                      : commonColors.MainColor,
                }}
              ></div>
            </span>
          ))}
        </span>

        <div className="contact-subBox">
          <>
            <Typography>{commonText.commonText.fullName}</Typography>
            <TextField
              size="small"
              className="fieldsInput"
              label="Prefix"
              variant="standard"
              onChange={(e: any) => handleContactData("prefix", e)}
              value={contactData.prefix}
            />
            {showAlert && contactData.prefix.length == 0 && (
              <Typography className="alertText">
                {commonText.commonText.enterprifix}
              </Typography>
            )}
            <TextField
              size="small"
              className="fieldsInput"
              label="First Name"
              variant="standard"
              onChange={(e: any) => handleContactData("firstName", e)}
              value={contactData.firstName}
            />

            {showAlert && contactData.firstName.length == 0 && (
              <Typography className="alertText">
                {commonText.commonText.enterFirsName}
              </Typography>
            )}
            <TextField
              size="small"
              className="fieldsInput"
              label="Middle Name"
              variant="standard"
              onChange={(e: any) => handleContactData("middleName", e)}
              value={contactData.middleName}
            />
            {showAlert && contactData.middleName.length == 0 && (
              <Typography className="alertText">
                {commonText.commonText.enterMiddleName}
              </Typography>
            )}
            <TextField
              size="small"
              className="fieldsInput"
              label="Last Name"
              variant="standard"
              onChange={(e: any) => handleContactData("lastName", e)}
              value={contactData.lastName}
            />
            {showAlert && contactData.lastName.length == 0 && (
              <Typography className="alertText">
                {commonText.commonText.enterlastName}
              </Typography>
            )}
            <TextField
              size="small"
              className="fieldsInput"
              label="Suffix"
              variant="standard"
              onChange={(e: any) => handleContactData("suffix", e)}
              value={contactData.suffix}
            />
            {showAlert && contactData.suffix.length == 0 && (
              <Typography className="alertText">
                {commonText.commonText.enterSuffix}
              </Typography>
            )}
            <TextField
              size="small"
              className="fieldsInput"
              label="Accreditations"
              variant="standard"
              onChange={(e: any) => handleContactData("accreditations", e)}
              value={contactData.accreditations}
            />
            {showAlert && contactData.accreditations.length == 0 && (
              <Typography className="alertText">
                {commonText.commonText.enterAccreditation}
              </Typography>
            )}
            <TextField
              size="small"
              className="fieldsInput"
              label="Preferred Name"
              variant="standard"
              onChange={(e: any) => handleContactData("prefferredName", e)}
              value={contactData.prefferredName}
            />
            {showAlert && contactData.prefferredName.length == 0 && (
              <Typography className="alertText">
                {commonText.commonText.enterPreFerred}
              </Typography>
            )}
            <TextField
              size="small"
              className="fieldsInput"
              label="Maiden Name"
              variant="standard"
              onChange={(e: any) => handleContactData("maidenName", e)}
              value={contactData.maidenName}
            />
            {showAlert && contactData.maidenName.length == 0 && (
              <Typography className="alertText">
                {commonText.commonText.enterMaiden}
              </Typography>
            )}
            <TextField
              size="small"
              className="fieldsInput"
              label="Pronouns"
              variant="standard"
              onChange={(e: any) => handleContactData("pronouse", e)}
              value={contactData.pronouse}
            />
            {showAlert && contactData.pronouse.length == 0 && (
              <Typography className="alertText">
                {commonText.commonText.enterPronouns}
              </Typography>
            )}
            <TextField
              size="small"
              className="fieldsInput"
              label="Title"
              variant="standard"
              onChange={(e: any) => handleContactData("title", e)}
              value={contactData.title}
            />
            {showAlert && contactData.title.length == 0 && (
              <Typography className="alertText">
                {commonText.commonText.enterTitle}
              </Typography>
            )}
            <TextField
              size="small"
              className="fieldsInput"
              label="Department"
              variant="standard"
              onChange={(e: any) => handleContactData("department", e)}
              value={contactData.department}
            />
            {showAlert && contactData.department.length == 0 && (
              <Typography className="alertText">
                {commonText.commonText.enterDepartment}
              </Typography>
            )}
            <TextField
              size="small"
              className="fieldsInput"
              label="Company"
              variant="standard"
              onChange={(e: any) => handleContactData("company", e)}
              value={contactData.company}
            />
            {showAlert && contactData.company.length == 0 && (
              <Typography className="alertText">
                {commonText.commonText.enterCompany}
              </Typography>
            )}
            <TextField
              size="small"
              className="fieldsInput"
              label="Headline"
              variant="standard"
              onChange={(e: any) => handleContactData("headline", e)}
              value={contactData.headline}
            />
            {showAlert && contactData.headline.length == 0 && (
              <Typography className="alertText">
                {commonText.commonText.enterHeadline}
              </Typography>
            )}
          </>
          <div className="contact-moreInfo">
            {filterData?.map((item: any, i: any) => (
              <div className="cantact-moreOpt" key={i}>
                <span
                  style={{ display: "flex", justifyContent: "space-between" }}
                >
                  <Typography>{item.label}</Typography>
                  <a onClick={() => handleRemove(item)}>
                    <CloseRoundedIcon />
                  </a>
                </span>
                <TextField
                  size="small"
                  className="fieldsInput"
                  placeholder={
                    item.label === "Phone"
                      ? "1122334455 or 2233445566"
                      : item.label === "Email"
                      ? "example123@gmail.com"
                      : "https://www.example.com/ or www.example.com"
                  }
                  variant="standard"
                  value={item.firstInput}
                  onChange={(e: any) => handleAddMoreInput("mainInput", e, i)}
                />
                {showSubAlert && item.firstInput.length == 0 && (
                  <Typography className="alertText">
                    {commonText.commonText.fillinformation}
                  </Typography>
                )}
                {item?.verfiyUrl === false &&
                  addMoreInfo.mainInput.length > 0 &&
                  !error && (
                    <Typography className="alertText">
                      {item.label === "Phone" ? (
                        <>{commonText.commonText.invalidPhoneFormat}</>
                      ) : item.label === "Email" ? (
                        <>{commonText.commonText.invalidEmailFormat}</>
                      ) : (
                        <>{commonText.commonText.invalidURLFormat}</>
                      )}
                    </Typography>
                  )}
                <TextField
                  size="small"
                  className="fieldsInput"
                  label={item.label === "Phone" ? "+91 or +1" : "add extention"}
                  variant="standard"
                  value={item.subInput}
                  onChange={(e: any) => handleAddMoreInput("subInput", e, i)}
                />
                {showSubAlert && item.subInput.length == 0 && (
                  <Typography className="alertText">
                    {commonText.commonText.fillinformation}
                  </Typography>
                )}
              </div>
            ))}
          </div>
          <div className="contact-btnBox">
            {/* <Button className="submitBtn">
              {commonText.commonText.cancel}
            </Button> */}
            <Button className="submitBtn" onClick={() => handleSubmitData()}>
              {commonText.commonText.save}
            </Button>
          </div>

          <div className="contact-addInfoOptBox">
            <Typography>{commonText.commonText.addinfomation}</Typography>
            <div className="contact-addInfoSubBox">
              {addMore.map((item) => (
                <div
                  onClick={() => {
                    filterData.some(
                      (items: any) => items.label === item.label
                    ) === false
                      ? handleAddMoreInfo(item)
                      : handleRemove(item);
                  }}
                  key={item.id}
                  className={
                    filterData.some(
                      (items: any) => items.label === item.label
                    ) === false
                      ? "contant-addOpt"
                      : "contant-addOpt2"
                  }
                >
                  <item.logo />
                  <Typography style={{ textAlign: "center" }}>
                    {item.label}
                  </Typography>
                </div>
              ))}
            </div>
          </div>
        </div>
      </Box>
    </Drawer>
  );
}

export default AddContact;

// -----------------------------------------------------------------------------------------------------------------------------------------

// import React, { useState } from "react";
// import {
//   Box,
//   Button,
//   TextField,
//   Typography,
//   IconButton,
//   Select,
//   MenuItem,
//   InputLabel,
//   FormControl,
//   Alert,
//   Grid,
// } from "@mui/material";
// import AddAPhotoIcon from "@mui/icons-material/AddAPhoto";
// import DeleteIcon from "@mui/icons-material/Delete";

// const AddContactForm = () => {
//   const [formData, setFormData] = useState({
//     prefix: "",
//     firstName: "",
//     lastName: "",
//     profileImage: null,
//     color: "",
//     additionalInfo: [],
//   });
//   const [error, setError] = useState("");

//   const handleInputChange = (e) => {
//     const { name, value } = e.target;
//     setFormData({ ...formData, [name]: value });
//   };

//   const handleProfileImageUpload = (e) => {
//     const file = e.target.files[0];
//     if (file) {
//       setFormData({ ...formData, profileImage: URL.createObjectURL(file) });
//     }
//   };

//   const handleAddInfo = () => {
//     setFormData({
//       ...formData,
//       additionalInfo: [...formData.additionalInfo, { type: "", value: "" }],
//     });
//   };

//   const handleAdditionalInfoChange = (index, field, value) => {
//     const updatedInfo = formData.additionalInfo.map((info, i) =>
//       i === index ? { ...info, [field]: value } : info
//     );
//     setFormData({ ...formData, additionalInfo: updatedInfo });
//   };

//   const handleDeleteInfo = (index) => {
//     const updatedInfo = formData.additionalInfo.filter((_, i) => i !== index);
//     setFormData({ ...formData, additionalInfo: updatedInfo });
//   };

//   const handleSubmit = (e) => {
//     e.preventDefault();
//     if (!formData.firstName || !formData.lastName) {
//       setError("First Name and Last Name are required.");
//       return;
//     }
//     setError("");
//     console.log("Form Submitted:", formData);
//   };

//   return (
//     <Box
//       component="form"
//       onSubmit={handleSubmit}
//       sx={{
//         maxWidth: "600px",
//         margin: "auto",
//         padding: 4,
//         border: "1px solid #ccc",
//         borderRadius: "8px",
//         boxShadow: "0 4px 8px rgba(0,0,0,0.1)",
//       }}
//     >
//       <Typography variant="h5" gutterBottom>
//         Add Contact
//       </Typography>

//       {error && <Alert severity="error">{error}</Alert>}

//       <Grid container spacing={2}>
//         <Grid item xs={12}>
//           <Box
//             sx={{
//               display: "flex",
//               alignItems: "center",
//               gap: 2,
//               marginBottom: 2,
//             }}
//           >
//             <IconButton component="label" size="large">
//               <AddAPhotoIcon fontSize="large" />
//               <input
//                 hidden
//                 accept="image/*"
//                 type="file"
//                 onChange={handleProfileImageUpload}
//               />
//             </IconButton>
//             {formData.profileImage && (
//               <Box
//                 component="img"
//                 src={formData.profileImage}
//                 alt="Profile Preview"
//                 sx={{ width: 50, height: 50, borderRadius: "50%" }}
//               />
//             )}
//           </Box>
//         </Grid>

//         <Grid item xs={12} sm={4}>
//           <FormControl fullWidth>
//             <InputLabel id="prefix-label">Prefix</InputLabel>
//             <Select
//               labelId="prefix-label"
//               name="prefix"
//               value={formData.prefix}
//               onChange={handleInputChange}
//             >
//               <MenuItem value="Mr">Mr</MenuItem>
//               <MenuItem value="Ms">Ms</MenuItem>
//               <MenuItem value="Dr">Dr</MenuItem>
//             </Select>
//           </FormControl>
//         </Grid>

//         <Grid item xs={12} sm={4}>
//           <TextField
//             fullWidth
//             label="First Name"
//             name="firstName"
//             value={formData.firstName}
//             onChange={handleInputChange}
//           />
//         </Grid>

//         <Grid item xs={12} sm={4}>
//           <TextField
//             fullWidth
//             label="Last Name"
//             name="lastName"
//             value={formData.lastName}
//             onChange={handleInputChange}
//           />
//         </Grid>

//         <Grid item xs={12}>
//           <TextField
//             fullWidth
//             label="Color"
//             name="color"
//             type="color"
//             value={formData.color}
//             onChange={handleInputChange}
//             InputLabelProps={{ shrink: true }}
//           />
//         </Grid>

//         <Grid item xs={12}>
//           <Typography variant="subtitle1">Additional Information</Typography>
//           {formData.additionalInfo.map((info, index) => (
//             <Box key={index} display="flex" alignItems="center" gap={2} my={1}>
//               <TextField
//                 label="Type"
//                 value={info.type}
//                 onChange={(e) =>
//                   handleAdditionalInfoChange(index, "type", e.target.value)
//                 }
//               />
//               <TextField
//                 label="Value"
//                 value={info.value}
//                 onChange={(e) =>
//                   handleAdditionalInfoChange(index, "value", e.target.value)
//                 }
//               />
//               <IconButton onClick={() => handleDeleteInfo(index)}>
//                 <DeleteIcon />
//               </IconButton>
//             </Box>
//           ))}
//           <Button variant="outlined" onClick={handleAddInfo}>
//             Add More Info
//           </Button>
//         </Grid>
//       </Grid>

//       <Box mt={3} textAlign="right">
//         <Button variant="contained" color="primary" type="submit">
//           Save Contact
//         </Button>
//       </Box>
//     </Box>
//   );
// };

// export default AddContactForm;

// -----------------------------------------------------------------------------------

// import React from "react";
// import { TextField, Typography, Button, Grid } from "@mui/material";

// interface ContactFormProps {
//   contactData: any;
//   handleContactData: (
//     field: string,
//     event: React.ChangeEvent<HTMLInputElement>
//   ) => void;
//   showAlert: boolean;
//   commonText: any;
// }

// const ContactForm: React.FC<ContactFormProps> = ({
//   contactData,
//   handleContactData,
//   showAlert,
//   commonText,
// }) => {
//   const renderField = (
//     label: string,
//     field: string,
//     validationText: string
//   ) => (
//     <>
//       <TextField
//         size="small"
//         className="fieldsInput"
//         label={label}
//         variant="standard"
//         onChange={(e) => handleContactData(field, e)}
//         value={contactData[field]}
//         fullWidth
//       />
//       {showAlert && contactData[field]?.length === 0 && (
//         <Typography className="alertText">{validationText}</Typography>
//       )}
//     </>
//   );

//   return (
//     <form>
//       <Grid container spacing={2}>
//         <Grid item xs={12}>
//           <Typography variant="h6">{commonText.commonText.fullName}</Typography>
//         </Grid>
//         <Grid item xs={12} sm={6}>
//           {renderField("Prefix", "prefix", commonText.commonText.enterprifix)}
//         </Grid>
//         <Grid item xs={12} sm={6}>
//           {renderField(
//             "First Name",
//             "firstName",
//             commonText.commonText.enterFirsName
//           )}
//         </Grid>
//         <Grid item xs={12} sm={6}>
//           {renderField(
//             "Middle Name",
//             "middleName",
//             commonText.commonText.enterMiddleName
//           )}
//         </Grid>
//         <Grid item xs={12} sm={6}>
//           {renderField(
//             "Last Name",
//             "lastName",
//             commonText.commonText.enterlastName
//           )}
//         </Grid>
//         <Grid item xs={12} sm={6}>
//           {renderField("Suffix", "suffix", commonText.commonText.enterSuffix)}
//         </Grid>
//         <Grid item xs={12} sm={6}>
//           {renderField(
//             "Accreditations",
//             "accreditations",
//             commonText.commonText.enterAccreditation
//           )}
//         </Grid>
//         <Grid item xs={12} sm={6}>
//           {renderField(
//             "Preferred Name",
//             "prefferredName",
//             commonText.commonText.enterPreFerred
//           )}
//         </Grid>
//         <Grid item xs={12} sm={6}>
//           {renderField(
//             "Maiden Name",
//             "maidenName",
//             commonText.commonText.enterMaiden
//           )}
//         </Grid>
//         <Grid item xs={12} sm={6}>
//           {renderField(
//             "Pronouns",
//             "pronouse",
//             commonText.commonText.enterPronouns
//           )}
//         </Grid>
//         <Grid item xs={12} sm={6}>
//           {renderField("Title", "title", commonText.commonText.enterTitle)}
//         </Grid>
//         <Grid item xs={12} sm={6}>
//           {renderField(
//             "Department",
//             "department",
//             commonText.commonText.enterDepartment
//           )}
//         </Grid>
//         <Grid item xs={12} sm={6}>
//           {renderField(
//             "Company",
//             "company",
//             commonText.commonText.enterCompany
//           )}
//         </Grid>
//         <Grid item xs={12} sm={6}>
//           {renderField(
//             "Headline",
//             "headline",
//             commonText.commonText.enterHeadline
//           )}
//         </Grid>
//         <Grid item xs={12}>
//           <Button variant="contained" type="submit" fullWidth>
//             Submit
//           </Button>
//         </Grid>
//       </Grid>
//     </form>
//   );
// };

// export default ContactForm;

// import React, { useState } from "react";
// import { TextField, Typography, Button, Grid, IconButton } from "@mui/material";
// import CloseRoundedIcon from "@mui/icons-material/CloseRounded";

// // Initial data for the Add More section
// const addMoreInitialData = [
//   { id: 1, label: "Phone", placeholder: "e.g., +1234567890" },
//   { id: 2, label: "Email", placeholder: "e.g., example@example.com" },
//   { id: 3, label: "Address", placeholder: "e.g., 123 Main St" },
//   { id: 4, label: "Website", placeholder: "e.g., https://example.com" },
// ];

// interface ContactFormProps {
//   contactData: any;
//   handleContactData: (
//     field: string,
//     event: React.ChangeEvent<HTMLInputElement>
//   ) => void;
//   showAlert: boolean;
//   commonText: any;
// }

// const ContactForm: React.FC<ContactFormProps> = ({
//   contactData,
//   handleContactData,
//   showAlert,
//   commonText,
// }) => {
//   const [addMore, setAddMore] = useState(addMoreInitialData);
//   const [addMoreData, setAddMoreData] = useState(
//     addMoreInitialData.map(() => ({ value: "" }))
//   );

//   const renderField = (
//     label: string,
//     field: string,
//     validationText: string
//   ) => (
//     <>
//       <TextField
//         size="small"
//         className="fieldsInput"
//         label={label}
//         variant="standard"
//         onChange={(e) => handleContactData(field, e)}
//         value={contactData[field]}
//         fullWidth
//       />
//       {showAlert && contactData[field]?.length === 0 && (
//         <Typography className="alertText">{validationText}</Typography>
//       )}
//     </>
//   );

//   const handleAddMoreChange = (
//     index: number,
//     event: React.ChangeEvent<HTMLInputElement>
//   ) => {
//     const updatedData = [...addMoreData];
//     updatedData[index].value = event.target.value;
//     setAddMoreData(updatedData);
//   };

//   const handleRemove = (id: number) => {
//     setAddMore(addMore.filter((item) => item.id !== id));
//     setAddMoreData(addMoreData.filter((_, index) => index !== id - 1));
//   };

//   const handleAddMoreSubmit = () => {
//     console.log("Add More Data Submitted:", addMoreData);
//     alert("Additional information submitted!");
//   };

//   return (
//     <form>
//       <Grid container spacing={2}>
//         {/* Full Name Section */}
//         <Grid item xs={12}>
//           <Typography variant="h6">{commonText.commonText.fullName}</Typography>
//         </Grid>
//         <Grid item xs={12} sm={6}>
//           {renderField("Prefix", "prefix", commonText.commonText.enterprifix)}
//         </Grid>
//         <Grid item xs={12} sm={6}>
//           {renderField(
//             "First Name",
//             "firstName",
//             commonText.commonText.enterFirsName
//           )}
//         </Grid>
//         <Grid item xs={12} sm={6}>
//           {renderField(
//             "Middle Name",
//             "middleName",
//             commonText.commonText.enterMiddleName
//           )}
//         </Grid>
//         <Grid item xs={12} sm={6}>
//           {renderField(
//             "Last Name",
//             "lastName",
//             commonText.commonText.enterlastName
//           )}
//         </Grid>
//         <Grid item xs={12} sm={6}>
//           {renderField("Suffix", "suffix", commonText.commonText.enterSuffix)}
//         </Grid>
//         <Grid item xs={12} sm={6}>
//           {renderField(
//             "Accreditations",
//             "accreditations",
//             commonText.commonText.enterAccreditation
//           )}
//         </Grid>
//         <Grid item xs={12} sm={6}>
//           {renderField(
//             "Preferred Name",
//             "prefferredName",
//             commonText.commonText.enterPreFerred
//           )}
//         </Grid>
//         <Grid item xs={12} sm={6}>
//           {renderField(
//             "Maiden Name",
//             "maidenName",
//             commonText.commonText.enterMaiden
//           )}
//         </Grid>
//         <Grid item xs={12} sm={6}>
//           {renderField(
//             "Pronouns",
//             "pronouse",
//             commonText.commonText.enterPronouns
//           )}
//         </Grid>
//         <Grid item xs={12} sm={6}>
//           {renderField("Title", "title", commonText.commonText.enterTitle)}
//         </Grid>
//         <Grid item xs={12} sm={6}>
//           {renderField(
//             "Department",
//             "department",
//             commonText.commonText.enterDepartment
//           )}
//         </Grid>
//         <Grid item xs={12} sm={6}>
//           {renderField(
//             "Company",
//             "company",
//             commonText.commonText.enterCompany
//           )}
//         </Grid>
//         <Grid item xs={12} sm={6}>
//           {renderField(
//             "Headline",
//             "headline",
//             commonText.commonText.enterHeadline
//           )}
//         </Grid>

//         {/* Add More Section */}
//         <Grid item xs={12}>
//           <Typography variant="h6" style={{ marginTop: "16px" }}>
//             Additional Information
//           </Typography>
//         </Grid>
//         {addMore.map((item, index) => (
//           <Grid item xs={12} sm={6} key={item.id}>
//             <div style={{ display: "flex", alignItems: "center" }}>
//               <TextField
//                 fullWidth
//                 size="small"
//                 placeholder={item.placeholder}
//                 value={addMoreData[index]?.value}
//                 onChange={(e) => handleAddMoreChange(index, e)}
//               />
//               <IconButton
//                 color="secondary"
//                 onClick={() => handleRemove(item.id)}
//               >
//                 <CloseRoundedIcon />
//               </IconButton>
//             </div>
//             {showAlert && addMoreData[index]?.value.length === 0 && (
//               <Typography className="alertText" color="error">
//                 Please fill in the {item.label.toLowerCase()}.
//               </Typography>
//             )}
//           </Grid>
//         ))}
//         <Grid item xs={12}>
//           <Button
//             variant="contained"
//             color="primary"
//             onClick={handleAddMoreSubmit}
//           >
//             Save Additional Information
//           </Button>
//         </Grid>
//       </Grid>
//     </form>
//   );
// };

// export default ContactForm;
